<app-glueckwunschtemplate attachment-point-for="content" fxFlex="grow" widget-id="248016 wappnet-glueckwunsch-template_Imported_Imported_Imported">
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-between stretch" layout-id="246957" widget-id="248056 navigation div_Imported_Imported_Imported_Imported">
  <button class="btn-round fab-pointer-cursor" [class.btn-blue]="false" (click)="callActions('beratungsidealformular', undefined, true, undefined, [], undefined, undefined)" widget-id="248144 back wappnet_fab_button_Imported_Imported_Imported"> 
   <div class="btn-round-img" [style.background-image]="config.icons.arrow_left"></div> </button><button class="btn-round temperature-icon fab-pointer-cursor" (click)="callActions('themenfinderrisikotemperatur', undefined, true, undefined, [], undefined, undefined)" widget-id="248145 wappnet_temperature_indicator_Imported_Imported_Imported"> 
   <div class="temperature-icon-peak"> 
    <div class="temperature-icon-peak-background"></div> 
   </div> 
   <div class="btn-round-img" [style.background-image]="model.themenfinder.risikotemperatur == 0 ? config.icons.temperature_low : (model.themenfinder.risikotemperatur == 1 ? config.icons.temperature : config.icons.temperature_high)"></div> </button>
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" widget-id="248146 placeholder div_Imported_Imported_Imported_Imported"></div>
 </div>
</app-glueckwunschtemplate>