<div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="grow" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="246930" widget-id="248020 root div_Imported_Imported_Imported_Imported">
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="246961" widget-id="248061 content div_Imported_Imported_Imported_Imported">
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="30" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px" layout-id="246992" widget-id="248155 text panel with header div_Imported_Imported_Imported_Imported">
   <div class="step-header brand-radius brand-shadow brand-gradient" [class.brand-gradient]="true" (click)="callActions(undefined, undefined, true, undefined, [], undefined, undefined)" widget-id="248246 header wappnet_step_header_Imported_Imported_Imported"> 
    <div class="step-title">
     {{config.geldgefuhle.steps.step1_title}}
    </div> 
    <div class="step-number">
     {{config.geldgefuhle.steps.step1_counter}}
    </div> 
   </div>
   <div attachment-point-for="content" [ngClass]="'brand-offset-small-inner brand-radius brand-shadow-inset'" *ngIf="true" widget-id="248245 text panel div_Imported_Imported_Imported_Imported">
    <article widget-id="248375 wappnet_text-with-header_Imported_Imported_Imported"> 
     <header>
      {{config.geldgefuhle.denkfehler.header}}
     </header> 
     <p>{{config.geldgefuhle.denkfehler.paragraph }}</p> 
    </article>
   </div>
   <div attachment-point-for="content" [ngClass]="'brand-offset-small-inner brand-radius brand-shadow-inset'" *ngIf="true" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px" layout-id="247016" widget-id="248247 results div_Imported_Imported_Imported_Imported">
    <div class="extendable-panel brand-radius-small" [class.is-highlighted]="false" *ngIf="model.geldgefuhle.denkfehler.fehler1.chosen" widget-id="248385 res1 wappnet_chosen_panel_title_Imported_Imported"> 
     <div class="extendable-panel-header" [class.brand-gradient]="true"> 
      <div class="extendable-panel-title">
       {{config.geldgefuhle.denkfehler.fehler1.title}}
      </div> 
     </div> 
    </div>
    <div class="extendable-panel brand-radius-small" [class.is-highlighted]="false" *ngIf="model.geldgefuhle.denkfehler.fehler2.chosen" widget-id="248386 res2 wappnet_chosen_panel_title_Imported_Imported"> 
     <div class="extendable-panel-header" [class.brand-gradient]="true"> 
      <div class="extendable-panel-title">
       {{config.geldgefuhle.denkfehler.fehler2.title}}
      </div> 
     </div> 
    </div>
    <div class="extendable-panel brand-radius-small" [class.is-highlighted]="false" *ngIf="model.geldgefuhle.denkfehler.fehler3.chosen" widget-id="248376 res3 wappnet_chosen_panel_title_Imported_Imported"> 
     <div class="extendable-panel-header" [class.brand-gradient]="true"> 
      <div class="extendable-panel-title">
       {{config.geldgefuhle.denkfehler.fehler3.title}}
      </div> 
     </div> 
    </div>
    <div class="extendable-panel brand-radius-small" [class.is-highlighted]="false" *ngIf="model.geldgefuhle.denkfehler.fehler4.chosen" widget-id="248377 res4 wappnet_chosen_panel_title_Imported_Imported"> 
     <div class="extendable-panel-header" [class.brand-gradient]="true"> 
      <div class="extendable-panel-title">
       config.geldgefuhle.denkfehler.fehler4.title
      </div> 
     </div> 
    </div>
    <div class="extendable-panel brand-radius-small" [class.is-highlighted]="false" *ngIf="model.geldgefuhle.denkfehler.fehler5.chosen" widget-id="248378 res5 wappnet_chosen_panel_title_Imported_Imported"> 
     <div class="extendable-panel-header" [class.brand-gradient]="true"> 
      <div class="extendable-panel-title">
       {{config.geldgefuhle.denkfehler.fehler5.title}}
      </div> 
     </div> 
    </div>
    <div class="extendable-panel brand-radius-small" [class.is-highlighted]="false" *ngIf="model.geldgefuhle.denkfehler.fehler6.chosen" widget-id="248379 res6 wappnet_chosen_panel_title_Imported_Imported"> 
     <div class="extendable-panel-header" [class.brand-gradient]="true"> 
      <div class="extendable-panel-title">
       {{config.geldgefuhle.denkfehler.fehler6.title}}
      </div> 
     </div> 
    </div>
    <div class="extendable-panel brand-radius-small" [class.is-highlighted]="false" *ngIf="model.geldgefuhle.denkfehler.fehler7.chosen" widget-id="248380 res7 wappnet_chosen_panel_title_Imported_Imported"> 
     <div class="extendable-panel-header" [class.brand-gradient]="true"> 
      <div class="extendable-panel-title">
       {{config.geldgefuhle.denkfehler.fehler7.title}}
      </div> 
     </div> 
    </div>
    <div class="extendable-panel brand-radius-small" [class.is-highlighted]="false" *ngIf="model.geldgefuhle.denkfehler.fehler8.chosen" widget-id="248381 res8 wappnet_chosen_panel_title_Imported_Imported"> 
     <div class="extendable-panel-header" [class.brand-gradient]="true"> 
      <div class="extendable-panel-title">
       {{config.geldgefuhle.denkfehler.fehler8.title}}
      </div> 
     </div> 
    </div>
    <div class="extendable-panel brand-radius-small" [class.is-highlighted]="false" *ngIf="model.geldgefuhle.denkfehler.fehler9.chosen" widget-id="248382 res9 wappnet_chosen_panel_title_Imported_Imported"> 
     <div class="extendable-panel-header" [class.brand-gradient]="true"> 
      <div class="extendable-panel-title">
       {{config.geldgefuhle.denkfehler.fehler9.title}}
      </div> 
     </div> 
    </div>
    <div class="extendable-panel brand-radius-small" [class.is-highlighted]="false" *ngIf="model.geldgefuhle.denkfehler.fehler10.chosen" widget-id="248383 res10 wappnet_chosen_panel_title_Imported_Imported"> 
     <div class="extendable-panel-header" [class.brand-gradient]="true"> 
      <div class="extendable-panel-title">
       {{config.geldgefuhle.denkfehler.fehler10.title}}
      </div> 
     </div> 
    </div>
    <div class="extendable-panel brand-radius-small" [class.is-highlighted]="false" *ngIf="model.geldgefuhle.denkfehler.fehler11.chosen" widget-id="248384 res11 wappnet_chosen_panel_title_Imported_Imported"> 
     <div class="extendable-panel-header" [class.brand-gradient]="true"> 
      <div class="extendable-panel-title">
       {{config.geldgefuhle.denkfehler.fehler11.title}}
      </div> 
     </div> 
    </div>
   </div>
  </div>
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="70" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="" layout-id="246991" widget-id="248154 dropdowns div_Imported_Imported_Imported_Imported">
   <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="48%" widget-id="248244 leftColumn div_Imported_Imported_Imported_Imported">
    <div class="extendable-panel brand-shadow" *ngIf="true" [class.is-highlighted]="!model.geldgefuhle.denkfehler.fehler1.chosen" widget-id="248369 fehler1 wappnet_extendable_panel_Imported_Imported_Imported"> 
     <div class="extendable-panel-header" [class.brand-gradient]="model.geldgefuhle.denkfehler.fehler1.visited" (click)="callActions(undefined, 'model.geldgefuhle.denkfehler.fehler1.expanded', false, undefined, [], undefined, undefined)"> 
      <div class="extendable-panel-title">
       {{config.geldgefuhle.denkfehler.fehler1.title}}
      </div> 
      <div class="extendable-panel-trigger"> 
       <div class="extendable-panel-icon" [style.background-image]="config.icons.burger" *ngIf="!model.geldgefuhle.denkfehler.fehler1.expanded"></div> 
       <div class="extendable-panel-icon" *ngIf="model.geldgefuhle.denkfehler.fehler1.expanded" [style.background-image]="config.icons.close"></div> 
      </div> 
     </div> 
     <div class="extendable-panel-body" attachment-point-for="panelContent" *ngIf="model.geldgefuhle.denkfehler.fehler1.expanded">
      <div widget-id="248441 q1 wappnet_question_panel_Imported_Imported_Imported"> 
       <p>{{config.geldgefuhle.denkfehler.fehler1.content}}</p> 
       <div class="tooltip-radio-wrapper"> <label class="tooltip-radio" (click)="wappnet_question_panel_Imported_Imported_Importedq1click249064(undefined, undefined, false, undefined, [], undefined, undefined)"> <input type="radio" name="error1" [value]="true" [(ngModel)]="model.geldgefuhle.denkfehler.fehler1.chosen"> <span class="tooltip-background"> <span class="tooltip brand-shadow brand-radius-small"> {{config.geldgefuhle.denkfehler.acceptButtonTitle}} <span class="tooltip-arrow"> <span class="tooltip-arrow-shape"></span> </span> </span> </span> </label> <label class="tooltip-radio" (click)="wappnet_question_panel_Imported_Imported_Importedq1click249064(undefined, undefined, false, undefined, [], undefined, undefined)"> <input type="radio" name="error1" [value]="false" [(ngModel)]="model.geldgefuhle.denkfehler.fehler1.chosen"> <span class="tooltip-background"> <span class="tooltip brand-shadow brand-radius-small"> {{config.geldgefuhle.denkfehler.denyButtonTitle}} <span class="tooltip-arrow"> <span class="tooltip-arrow-shape"></span> </span> </span> </span> </label> 
       </div> 
      </div>
     </div> 
    </div>
    <div class="extendable-panel brand-shadow" *ngIf="true" [class.is-highlighted]="!model.geldgefuhle.denkfehler.fehler2.chosen" widget-id="248370 fehler2 wappnet_extendable_panel_Imported_Imported_Imported"> 
     <div class="extendable-panel-header" [class.brand-gradient]="model.geldgefuhle.denkfehler.fehler2.visited" (click)="callActions(undefined, 'model.geldgefuhle.denkfehler.fehler2.expanded', false, undefined, [], undefined, undefined)"> 
      <div class="extendable-panel-title">
       {{config.geldgefuhle.denkfehler.fehler2.title}}
      </div> 
      <div class="extendable-panel-trigger"> 
       <div class="extendable-panel-icon" [style.background-image]="config.icons.burger" *ngIf="!model.geldgefuhle.denkfehler.fehler2.expanded"></div> 
       <div class="extendable-panel-icon" *ngIf="model.geldgefuhle.denkfehler.fehler2.expanded" [style.background-image]="config.icons.close"></div> 
      </div> 
     </div> 
     <div class="extendable-panel-body" attachment-point-for="panelContent" *ngIf="model.geldgefuhle.denkfehler.fehler2.expanded">
      <div widget-id="248442 q2 wappnet_question_panel_Imported_Imported_Imported"> 
       <p>{{config.geldgefuhle.denkfehler.fehler2.content}}</p> 
       <div class="tooltip-radio-wrapper"> <label class="tooltip-radio" (click)="wappnet_question_panel_Imported_Imported_Importedq2click249065(undefined, undefined, false, undefined, [], undefined, undefined)"> <input type="radio" name="error3" [value]="true" [(ngModel)]="model.geldgefuhle.denkfehler.fehler2.chosen"> <span class="tooltip-background"> <span class="tooltip brand-shadow brand-radius-small"> {{config.geldgefuhle.denkfehler.acceptButtonTitle}} <span class="tooltip-arrow"> <span class="tooltip-arrow-shape"></span> </span> </span> </span> </label> <label class="tooltip-radio" (click)="wappnet_question_panel_Imported_Imported_Importedq2click249065(undefined, undefined, false, undefined, [], undefined, undefined)"> <input type="radio" name="error3" [value]="false" [(ngModel)]="model.geldgefuhle.denkfehler.fehler2.chosen"> <span class="tooltip-background"> <span class="tooltip brand-shadow brand-radius-small"> {{config.geldgefuhle.denkfehler.denyButtonTitle}} <span class="tooltip-arrow"> <span class="tooltip-arrow-shape"></span> </span> </span> </span> </label> 
       </div> 
      </div>
     </div> 
    </div>
    <div class="extendable-panel brand-shadow" *ngIf="true" [class.is-highlighted]="!model.geldgefuhle.denkfehler.fehler3.chosen" widget-id="248371 fehler3 wappnet_extendable_panel_Imported_Imported_Imported"> 
     <div class="extendable-panel-header" [class.brand-gradient]="model.geldgefuhle.denkfehler.fehler3.visited" (click)="callActions(undefined, 'model.geldgefuhle.denkfehler.fehler3.expanded', false, undefined, [], undefined, undefined)"> 
      <div class="extendable-panel-title">
       {{config.geldgefuhle.denkfehler.fehler3.title}}
      </div> 
      <div class="extendable-panel-trigger"> 
       <div class="extendable-panel-icon" [style.background-image]="config.icons.burger" *ngIf="!model.geldgefuhle.denkfehler.fehler3.expanded"></div> 
       <div class="extendable-panel-icon" *ngIf="model.geldgefuhle.denkfehler.fehler3.expanded" [style.background-image]="config.icons.close"></div> 
      </div> 
     </div> 
     <div class="extendable-panel-body" attachment-point-for="panelContent" *ngIf="model.geldgefuhle.denkfehler.fehler3.expanded">
      <div widget-id="248443 q3 wappnet_question_panel_Imported_Imported_Imported"> 
       <p>{{config.geldgefuhle.denkfehler.fehler3.content}}</p> 
       <div class="tooltip-radio-wrapper"> <label class="tooltip-radio" (click)="wappnet_question_panel_Imported_Imported_Importedq3click249066(undefined, undefined, false, undefined, [], undefined, undefined)"> <input type="radio" name="error3" [value]="true" [(ngModel)]="model.geldgefuhle.denkfehler.fehler3.chosen"> <span class="tooltip-background"> <span class="tooltip brand-shadow brand-radius-small"> {{config.geldgefuhle.denkfehler.acceptButtonTitle}} <span class="tooltip-arrow"> <span class="tooltip-arrow-shape"></span> </span> </span> </span> </label> <label class="tooltip-radio" (click)="wappnet_question_panel_Imported_Imported_Importedq3click249066(undefined, undefined, false, undefined, [], undefined, undefined)"> <input type="radio" name="error3" [value]="false" [(ngModel)]="model.geldgefuhle.denkfehler.fehler3.chosen"> <span class="tooltip-background"> <span class="tooltip brand-shadow brand-radius-small"> {{config.geldgefuhle.denkfehler.denyButtonTitle}} <span class="tooltip-arrow"> <span class="tooltip-arrow-shape"></span> </span> </span> </span> </label> 
       </div> 
      </div>
     </div> 
    </div>
    <div class="extendable-panel brand-shadow" *ngIf="true" [class.is-highlighted]="!model.geldgefuhle.denkfehler.fehler4.chosen" widget-id="248372 fehler4 wappnet_extendable_panel_Imported_Imported_Imported"> 
     <div class="extendable-panel-header" [class.brand-gradient]="model.geldgefuhle.denkfehler.fehler4.visited" (click)="callActions(undefined, 'model.geldgefuhle.denkfehler.fehler4.expanded', false, undefined, [], undefined, undefined)"> 
      <div class="extendable-panel-title">
       {{config.geldgefuhle.denkfehler.fehler4.title}}
      </div> 
      <div class="extendable-panel-trigger"> 
       <div class="extendable-panel-icon" [style.background-image]="config.icons.burger" *ngIf="!model.geldgefuhle.denkfehler.fehler4.expanded"></div> 
       <div class="extendable-panel-icon" *ngIf="model.geldgefuhle.denkfehler.fehler4.expanded" [style.background-image]="config.icons.close"></div> 
      </div> 
     </div> 
     <div class="extendable-panel-body" attachment-point-for="panelContent" *ngIf="model.geldgefuhle.denkfehler.fehler4.expanded">
      <div widget-id="248444 q4 wappnet_question_panel_Imported_Imported_Imported"> 
       <p>{{config.geldgefuhle.denkfehler.fehler4.content}}</p> 
       <div class="tooltip-radio-wrapper"> <label class="tooltip-radio" (click)="wappnet_question_panel_Imported_Imported_Importedq4click249067(undefined, undefined, false, undefined, [], undefined, undefined)"> <input type="radio" name="error4" [value]="true" [(ngModel)]="model.geldgefuhle.denkfehler.fehler4.chosen"> <span class="tooltip-background"> <span class="tooltip brand-shadow brand-radius-small"> {{config.geldgefuhle.denkfehler.acceptButtonTitle}} <span class="tooltip-arrow"> <span class="tooltip-arrow-shape"></span> </span> </span> </span> </label> <label class="tooltip-radio" (click)="wappnet_question_panel_Imported_Imported_Importedq4click249067(undefined, undefined, false, undefined, [], undefined, undefined)"> <input type="radio" name="error4" [value]="false" [(ngModel)]="model.geldgefuhle.denkfehler.fehler4.chosen"> <span class="tooltip-background"> <span class="tooltip brand-shadow brand-radius-small"> {{config.geldgefuhle.denkfehler.denyButtonTitle}} <span class="tooltip-arrow"> <span class="tooltip-arrow-shape"></span> </span> </span> </span> </label> 
       </div> 
      </div>
     </div> 
    </div>
    <div class="extendable-panel brand-shadow" *ngIf="true" [class.is-highlighted]="!model.geldgefuhle.denkfehler.fehler5.chosen" widget-id="248373 fehler5 wappnet_extendable_panel_Imported_Imported_Imported"> 
     <div class="extendable-panel-header" [class.brand-gradient]="model.geldgefuhle.denkfehler.fehler5.visited" (click)="callActions(undefined, 'model.geldgefuhle.denkfehler.fehler5.expanded', false, undefined, [], undefined, undefined)"> 
      <div class="extendable-panel-title">
       {{config.geldgefuhle.denkfehler.fehler5.title}}
      </div> 
      <div class="extendable-panel-trigger"> 
       <div class="extendable-panel-icon" [style.background-image]="config.icons.burger" *ngIf="!model.geldgefuhle.denkfehler.fehler5.expanded"></div> 
       <div class="extendable-panel-icon" *ngIf="model.geldgefuhle.denkfehler.fehler5.expanded" [style.background-image]="config.icons.close"></div> 
      </div> 
     </div> 
     <div class="extendable-panel-body" attachment-point-for="panelContent" *ngIf="model.geldgefuhle.denkfehler.fehler5.expanded">
      <div widget-id="248445 q5 wappnet_question_panel_Imported_Imported_Imported"> 
       <p>{{config.geldgefuhle.denkfehler.fehler5.content}}</p> 
       <div class="tooltip-radio-wrapper"> <label class="tooltip-radio" (click)="wappnet_question_panel_Imported_Imported_Importedq5click249068(undefined, undefined, false, undefined, [], undefined, undefined)"> <input type="radio" name="error5" [value]="true" [(ngModel)]="model.geldgefuhle.denkfehler.fehler5.chosen"> <span class="tooltip-background"> <span class="tooltip brand-shadow brand-radius-small"> {{config.geldgefuhle.denkfehler.acceptButtonTitle}} <span class="tooltip-arrow"> <span class="tooltip-arrow-shape"></span> </span> </span> </span> </label> <label class="tooltip-radio" (click)="wappnet_question_panel_Imported_Imported_Importedq5click249068(undefined, undefined, false, undefined, [], undefined, undefined)"> <input type="radio" name="error5" [value]="false" [(ngModel)]="model.geldgefuhle.denkfehler.fehler5.chosen"> <span class="tooltip-background"> <span class="tooltip brand-shadow brand-radius-small"> {{config.geldgefuhle.denkfehler.denyButtonTitle}} <span class="tooltip-arrow"> <span class="tooltip-arrow-shape"></span> </span> </span> </span> </label> 
       </div> 
      </div>
     </div> 
    </div>
    <div class="extendable-panel brand-shadow" *ngIf="true" [class.is-highlighted]="!model.geldgefuhle.denkfehler.fehler6.chosen" widget-id="248374 fehler6 wappnet_extendable_panel_Imported_Imported_Imported"> 
     <div class="extendable-panel-header" [class.brand-gradient]="model.geldgefuhle.denkfehler.fehler6.visited" (click)="callActions(undefined, 'model.geldgefuhle.denkfehler.fehler6.expanded', false, undefined, [], undefined, undefined)"> 
      <div class="extendable-panel-title">
       {{config.geldgefuhle.denkfehler.fehler6.title}}
      </div> 
      <div class="extendable-panel-trigger"> 
       <div class="extendable-panel-icon" [style.background-image]="config.icons.burger" *ngIf="!model.geldgefuhle.denkfehler.fehler6.expanded"></div> 
       <div class="extendable-panel-icon" *ngIf="model.geldgefuhle.denkfehler.fehler6.expanded" [style.background-image]="config.icons.close"></div> 
      </div> 
     </div> 
     <div class="extendable-panel-body" attachment-point-for="panelContent" *ngIf="model.geldgefuhle.denkfehler.fehler6.expanded">
      <div widget-id="248446 q6 wappnet_question_panel_Imported_Imported_Imported"> 
       <p>{{config.geldgefuhle.denkfehler.fehler6.content}}</p> 
       <div class="tooltip-radio-wrapper"> <label class="tooltip-radio" (click)="wappnet_question_panel_Imported_Imported_Importedq6click249069(undefined, undefined, false, undefined, [], undefined, undefined)"> <input type="radio" name="error6" [value]="true" [(ngModel)]="model.geldgefuhle.denkfehler.fehler6.chosen"> <span class="tooltip-background"> <span class="tooltip brand-shadow brand-radius-small"> {{config.geldgefuhle.denkfehler.acceptButtonTitle}} <span class="tooltip-arrow"> <span class="tooltip-arrow-shape"></span> </span> </span> </span> </label> <label class="tooltip-radio" (click)="wappnet_question_panel_Imported_Imported_Importedq6click249069(undefined, undefined, false, undefined, [], undefined, undefined)"> <input type="radio" name="error6" [value]="false" [(ngModel)]="model.geldgefuhle.denkfehler.fehler6.chosen"> <span class="tooltip-background"> <span class="tooltip brand-shadow brand-radius-small"> {{config.geldgefuhle.denkfehler.denyButtonTitle}} <span class="tooltip-arrow"> <span class="tooltip-arrow-shape"></span> </span> </span> </span> </label> 
       </div> 
      </div>
     </div> 
    </div>
   </div>
   <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="48%" widget-id="248243 rightColumn div_Imported_Imported_Imported_Imported">
    <div class="extendable-panel brand-shadow" *ngIf="true" [class.is-highlighted]="!model.geldgefuhle.denkfehler.fehler7.chosen" widget-id="248364 fehler7 wappnet_extendable_panel_Imported_Imported_Imported"> 
     <div class="extendable-panel-header" [class.brand-gradient]="model.geldgefuhle.denkfehler.fehler7.visited" (click)="callActions(undefined, 'model.geldgefuhle.denkfehler.fehler7.expanded', false, undefined, [], undefined, undefined)"> 
      <div class="extendable-panel-title">
       {{config.geldgefuhle.denkfehler.fehler7.title}}
      </div> 
      <div class="extendable-panel-trigger"> 
       <div class="extendable-panel-icon" [style.background-image]="config.icons.burger" *ngIf="!model.geldgefuhle.denkfehler.fehler7.expanded"></div> 
       <div class="extendable-panel-icon" *ngIf="model.geldgefuhle.denkfehler.fehler7.expanded" [style.background-image]="config.icons.close"></div> 
      </div> 
     </div> 
     <div class="extendable-panel-body" attachment-point-for="panelContent" *ngIf="model.geldgefuhle.denkfehler.fehler7.expanded">
      <div widget-id="248436 q7 wappnet_question_panel_Imported_Imported_Imported"> 
       <p>{{config.geldgefuhle.denkfehler.fehler7.content}}</p> 
       <div class="tooltip-radio-wrapper"> <label class="tooltip-radio" (click)="wappnet_question_panel_Imported_Imported_Importedq7click249056(undefined, undefined, false, undefined, [], undefined, undefined)"> <input type="radio" name="error2" [value]="true" [(ngModel)]="model.geldgefuhle.denkfehler.fehler7.chosen"> <span class="tooltip-background"> <span class="tooltip brand-shadow brand-radius-small"> {{config.geldgefuhle.denkfehler.acceptButtonTitle}} <span class="tooltip-arrow"> <span class="tooltip-arrow-shape"></span> </span> </span> </span> </label> <label class="tooltip-radio" (click)="wappnet_question_panel_Imported_Imported_Importedq7click249056(undefined, undefined, false, undefined, [], undefined, undefined)"> <input type="radio" name="error2" [value]="false" [(ngModel)]="model.geldgefuhle.denkfehler.fehler7.chosen"> <span class="tooltip-background"> <span class="tooltip brand-shadow brand-radius-small"> {{config.geldgefuhle.denkfehler.denyButtonTitle}} <span class="tooltip-arrow"> <span class="tooltip-arrow-shape"></span> </span> </span> </span> </label> 
       </div> 
      </div>
     </div> 
    </div>
    <div class="extendable-panel brand-shadow" *ngIf="true" [class.is-highlighted]="!model.geldgefuhle.denkfehler.fehler8.chosen" widget-id="248365 fehler8 wappnet_extendable_panel_Imported_Imported_Imported"> 
     <div class="extendable-panel-header" [class.brand-gradient]="model.geldgefuhle.denkfehler.fehler8.visited" (click)="callActions(undefined, 'model.geldgefuhle.denkfehler.fehler8.expanded', false, undefined, [], undefined, undefined)"> 
      <div class="extendable-panel-title">
       {{config.geldgefuhle.denkfehler.fehler8.title}}
      </div> 
      <div class="extendable-panel-trigger"> 
       <div class="extendable-panel-icon" [style.background-image]="config.icons.burger" *ngIf="!model.geldgefuhle.denkfehler.fehler8.expanded"></div> 
       <div class="extendable-panel-icon" *ngIf="model.geldgefuhle.denkfehler.fehler8.expanded" [style.background-image]="config.icons.close"></div> 
      </div> 
     </div> 
     <div class="extendable-panel-body" attachment-point-for="panelContent" *ngIf="model.geldgefuhle.denkfehler.fehler8.expanded">
      <div widget-id="248437 q8 wappnet_question_panel_Imported_Imported_Imported"> 
       <p>{{config.geldgefuhle.denkfehler.fehler8.content}}</p> 
       <div class="tooltip-radio-wrapper"> <label class="tooltip-radio" (click)="wappnet_question_panel_Imported_Imported_Importedq8click249058(undefined, undefined, false, undefined, [], undefined, undefined)"> <input type="radio" [value]="true" [(ngModel)]="model.geldgefuhle.denkfehler.fehler8.chosen"> <span class="tooltip-background"> <span class="tooltip brand-shadow brand-radius-small"> {{config.geldgefuhle.denkfehler.acceptButtonTitle}} <span class="tooltip-arrow"> <span class="tooltip-arrow-shape"></span> </span> </span> </span> </label> <label class="tooltip-radio" (click)="wappnet_question_panel_Imported_Imported_Importedq8click249058(undefined, undefined, false, undefined, [], undefined, undefined)"> <input type="radio" [value]="false" [(ngModel)]="model.geldgefuhle.denkfehler.fehler8.chosen"> <span class="tooltip-background"> <span class="tooltip brand-shadow brand-radius-small"> {{config.geldgefuhle.denkfehler.denyButtonTitle}} <span class="tooltip-arrow"> <span class="tooltip-arrow-shape"></span> </span> </span> </span> </label> 
       </div> 
      </div>
     </div> 
    </div>
    <div class="extendable-panel brand-shadow" *ngIf="true" [class.is-highlighted]="!model.geldgefuhle.denkfehler.fehler9.chosen" widget-id="248366 fehler9 wappnet_extendable_panel_Imported_Imported_Imported"> 
     <div class="extendable-panel-header" [class.brand-gradient]="model.geldgefuhle.denkfehler.fehler9.visited" (click)="callActions(undefined, 'model.geldgefuhle.denkfehler.fehler9.expanded', false, undefined, [], undefined, undefined)"> 
      <div class="extendable-panel-title">
       {{config.geldgefuhle.denkfehler.fehler9.title}}
      </div> 
      <div class="extendable-panel-trigger"> 
       <div class="extendable-panel-icon" [style.background-image]="config.icons.burger" *ngIf="!model.geldgefuhle.denkfehler.fehler9.expanded"></div> 
       <div class="extendable-panel-icon" *ngIf="model.geldgefuhle.denkfehler.fehler9.expanded" [style.background-image]="config.icons.close"></div> 
      </div> 
     </div> 
     <div class="extendable-panel-body" attachment-point-for="panelContent" *ngIf="model.geldgefuhle.denkfehler.fehler9.expanded">
      <div widget-id="248438 q9 wappnet_question_panel_Imported_Imported_Imported"> 
       <p>{{config.geldgefuhle.denkfehler.fehler9.content}}</p> 
       <div class="tooltip-radio-wrapper"> <label class="tooltip-radio" (click)="wappnet_question_panel_Imported_Imported_Importedq9click249059(undefined, undefined, false, undefined, [], undefined, undefined)"> <input type="radio" name="error9" [value]="true" [(ngModel)]="model.geldgefuhle.denkfehler.fehler9.chosen"> <span class="tooltip-background"> <span class="tooltip brand-shadow brand-radius-small"> {{config.geldgefuhle.denkfehler.acceptButtonTitle}} <span class="tooltip-arrow"> <span class="tooltip-arrow-shape"></span> </span> </span> </span> </label> <label class="tooltip-radio" (click)="wappnet_question_panel_Imported_Imported_Importedq9click249059(undefined, undefined, false, undefined, [], undefined, undefined)"> <input type="radio" name="error9" [value]="false" [(ngModel)]="model.geldgefuhle.denkfehler.fehler9.chosen"> <span class="tooltip-background"> <span class="tooltip brand-shadow brand-radius-small"> {{config.geldgefuhle.denkfehler.denyButtonTitle}} <span class="tooltip-arrow"> <span class="tooltip-arrow-shape"></span> </span> </span> </span> </label> 
       </div> 
      </div>
     </div> 
    </div>
    <div class="extendable-panel brand-shadow" *ngIf="true" [class.is-highlighted]="!model.geldgefuhle.denkfehler.fehler10.chosen" widget-id="248367 fehler10 wappnet_extendable_panel_Imported_Imported_Imported"> 
     <div class="extendable-panel-header" [class.brand-gradient]="model.geldgefuhle.denkfehler.fehler10.visited" (click)="callActions(undefined, 'model.geldgefuhle.denkfehler.fehler10.expanded', false, undefined, [], undefined, undefined)"> 
      <div class="extendable-panel-title">
       {{config.geldgefuhle.denkfehler.fehler10.title}}
      </div> 
      <div class="extendable-panel-trigger"> 
       <div class="extendable-panel-icon" [style.background-image]="config.icons.burger" *ngIf="!model.geldgefuhle.denkfehler.fehler10.expanded"></div> 
       <div class="extendable-panel-icon" *ngIf="model.geldgefuhle.denkfehler.fehler10.expanded" [style.background-image]="config.icons.close"></div> 
      </div> 
     </div> 
     <div class="extendable-panel-body" attachment-point-for="panelContent" *ngIf="model.geldgefuhle.denkfehler.fehler10.expanded">
      <div widget-id="248439 q10 wappnet_question_panel_Imported_Imported_Imported"> 
       <p>{{config.geldgefuhle.denkfehler.fehler10.content}}</p> 
       <div class="tooltip-radio-wrapper"> <label class="tooltip-radio" (click)="wappnet_question_panel_Imported_Imported_Importedq10click249060(undefined, undefined, false, undefined, [], undefined, undefined)"> <input type="radio" name="error10" [value]="true" [(ngModel)]="model.geldgefuhle.denkfehler.fehler10.chosen"> <span class="tooltip-background"> <span class="tooltip brand-shadow brand-radius-small"> {{config.geldgefuhle.denkfehler.acceptButtonTitle}} <span class="tooltip-arrow"> <span class="tooltip-arrow-shape"></span> </span> </span> </span> </label> <label class="tooltip-radio" (click)="wappnet_question_panel_Imported_Imported_Importedq10click249060(undefined, undefined, false, undefined, [], undefined, undefined)"> <input type="radio" name="error10" [value]="false" [(ngModel)]="model.geldgefuhle.denkfehler.fehler10.chosen"> <span class="tooltip-background"> <span class="tooltip brand-shadow brand-radius-small"> {{config.geldgefuhle.denkfehler.denyButtonTitle}} <span class="tooltip-arrow"> <span class="tooltip-arrow-shape"></span> </span> </span> </span> </label> 
       </div> 
      </div>
     </div> 
    </div>
    <div class="extendable-panel brand-shadow" *ngIf="true" [class.is-highlighted]="!model.geldgefuhle.denkfehler.fehler11.chosen" widget-id="248368 fehler11 wappnet_extendable_panel_Imported_Imported_Imported"> 
     <div class="extendable-panel-header" [class.brand-gradient]="model.geldgefuhle.denkfehler.fehler11.visited" (click)="callActions(undefined, 'model.geldgefuhle.denkfehler.fehler11.expanded', false, undefined, [], undefined, undefined)"> 
      <div class="extendable-panel-title">
       {{config.geldgefuhle.denkfehler.fehler11.title}}
      </div> 
      <div class="extendable-panel-trigger"> 
       <div class="extendable-panel-icon" [style.background-image]="config.icons.burger" *ngIf="!model.geldgefuhle.denkfehler.fehler11.expanded"></div> 
       <div class="extendable-panel-icon" *ngIf="model.geldgefuhle.denkfehler.fehler11.expanded" [style.background-image]="config.icons.close"></div> 
      </div> 
     </div> 
     <div class="extendable-panel-body" attachment-point-for="panelContent" *ngIf="model.geldgefuhle.denkfehler.fehler11.expanded">
      <div widget-id="248440 q11 wappnet_question_panel_Imported_Imported_Imported"> 
       <p>{{config.geldgefuhle.denkfehler.fehler11.content}}</p> 
       <div class="tooltip-radio-wrapper"> <label class="tooltip-radio" (click)="wappnet_question_panel_Imported_Imported_Importedq11click249062(undefined, undefined, false, undefined, [], undefined, undefined)"> <input type="radio" name="error11" [value]="true" [(ngModel)]="model.geldgefuhle.denkfehler.fehler11.chosen"> <span class="tooltip-background"> <span class="tooltip brand-shadow brand-radius-small"> {{config.geldgefuhle.denkfehler.acceptButtonTitle}} <span class="tooltip-arrow"> <span class="tooltip-arrow-shape"></span> </span> </span> </span> </label> <label class="tooltip-radio" (click)="wappnet_question_panel_Imported_Imported_Importedq11click249062(undefined, undefined, false, undefined, [], undefined, undefined)"> <input type="radio" name="error11" [value]="false" [(ngModel)]="model.geldgefuhle.denkfehler.fehler11.chosen"> <span class="tooltip-background"> <span class="tooltip brand-shadow brand-radius-small"> {{config.geldgefuhle.denkfehler.denyButtonTitle}} <span class="tooltip-arrow"> <span class="tooltip-arrow-shape"></span> </span> </span> </span> </label> 
       </div> 
      </div>
     </div> 
    </div>
   </div>
  </div>
 </div>
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="20px" layout-id="246962" widget-id="248062 navigation div_Imported_Imported_Imported_Imported">
  <button class="btn-round fab-pointer-cursor" [class.btn-blue]="false" (click)="wappnet_fab_button_Imported_Imported_Importedbacknavigate248929('geldgefuhlesteps', undefined, true, undefined, [], undefined, undefined)" widget-id="248156 back wappnet_fab_button_Imported_Imported_Imported"> 
   <div class="btn-round-img" [style.background-image]="config.icons.arrow_left"></div> </button><button class="btn-round temperature-icon fab-pointer-cursor" (click)="callActions('themenfinderrisikotemperatur', undefined, true, undefined, [], undefined, undefined)" widget-id="248158 wappnet_temperature_indicator_Imported_Imported_Imported"> 
   <div class="temperature-icon-peak"> 
    <div class="temperature-icon-peak-background"></div> 
   </div> 
   <div class="btn-round-img" [style.background-image]="model.themenfinder.risikotemperatur == 0 ? config.icons.temperature_low : (model.themenfinder.risikotemperatur == 1 ? config.icons.temperature : config.icons.temperature_high)"></div> </button><button class="btn-round fab-pointer-cursor" [class.btn-blue]="false" (click)="wappnet_fab_button_Imported_Imported_Importedforwardnavigate248930('geldgefuhleglaubenssatze', undefined, true, undefined, [], undefined, undefined)" widget-id="248157 forward wappnet_fab_button_Imported_Imported_Imported"> 
   <div class="btn-round-img" [style.background-image]="config.icons.arrow_right"></div> </button>
 </div>
</div>