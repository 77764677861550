<div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="grow" fxLayout="column" fxLayoutAlign="start stretch" layout-id="246925" widget-id="248012 div_Imported_Imported_Imported_Imported">
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="12%" widget-id="248049 upper placeholder div_Imported_Imported_Imported_Imported"></div>
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="start" layout-id="246950" widget-id="248050 content row div_Imported_Imported_Imported_Imported">
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="15%" widget-id="248128 left placeholder div_Imported_Imported_Imported_Imported"></div>
  <div attachment-point-for="content" [ngClass]="'brand-offset-small-inner brand-radius brand-shadow'" *ngIf="true" fxFlex="70%" fxLayout="row" fxLayoutAlign="start none" fxLayoutGap="" layout-id="246984" widget-id="248130 shadowed box div_Imported_Imported_Imported_Imported">
   <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="10%" widget-id="248225 left placeholder div_Imported_Imported_Imported_Imported"></div>
   <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="80%" fxLayout="column" fxLayoutAlign="start" layout-id="247014" widget-id="248224 content column div_Imported_Imported_Imported_Imported">
    <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="30%" widget-id="248355 header_row div_Imported_Imported_Imported_Imported">
     <h1 widget-id="248420 header wappnet_header_Imported_Imported_Imported">{{config.profile.header}}</h1>
    </div>
    <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="45%" fxLayout="row" fxLayoutAlign="space-around none" fxLayoutGap="60px" layout-id="247021" widget-id="248356 content_row div_Imported_Imported_Imported_Imported">
     <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="66" fxLayout="column" fxLayoutAlign="space-between none" fxLayoutGap="40px" layout-id="247025" widget-id="248421 column 1 div_Imported_Imported_Imported_Imported">
      <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="20px" layout-id="247026" widget-id="248465 form div_Imported_Imported_Imported_Imported">
       <div class="field-control" widget-id="248467 login wappnet_input_Imported_Imported_Imported"> 
        <input type="text" [placeholder]="config.profile.username_placeholder" [(ngModel)]="model.profile.usernameTemp"> <span class="field-control-shadow"></span> 
       </div>
       <div class="field-control" widget-id="248468 password wappnet_input_Imported_Imported_Imported"> 
        <input type="text" [placeholder]="config.profile.password_placeholder"> <span class="field-control-shadow"></span> 
       </div>
      </div>
      <div class="btn-main" (click)="wappnet_nav_button_Imported_Imported_Importednavigate249078('start-page', undefined, true, undefined, [], undefined, undefined)" widget-id="248464 wappnet_nav_button_Imported_Imported_Imported">
        {{config.profile.button_text}} 
      </div>
     </div>
     <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="33" widget-id="248422 column 2 div_Imported_Imported_Imported_Imported">
      <div class="profile-avatar" widget-id="248466 wappnet_profile_avatar_Imported_Imported_Imported"> 
       <div class="profile-avatar-img brand-shadow" [style.background-image]="config.icons.profile1" [style.background-size]="model.profile.profilePictureSize"></div> 
       <div class="profile-avatar-btn brand-shadow">
        +
       </div> 
      </div>
     </div>
    </div>
    <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="25%" widget-id="248354 lower placeholder div_Imported_Imported_Imported_Imported"></div>
   </div>
   <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="10%" widget-id="248226 right placeholder div_Imported_Imported_Imported_Imported"></div>
  </div>
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="15%" widget-id="248129 right placeholder div_Imported_Imported_Imported_Imported"></div>
 </div>
</div>