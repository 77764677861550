import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ListsMergeService {

  constructor() { }

  public merge(masterList: any[], slaveList: any[], masterListFieldName: string, slaveListFieldName: string,
    resultMasterFieldName: string, resultSlaveFieldName: string, slaveElementPrototype: string) {
    var that = this;
    if (masterList && slaveList) {
      slaveElementPrototype = slaveElementPrototype ?? "{}";
      masterListFieldName = masterListFieldName ?? "id";
      slaveListFieldName = slaveListFieldName ?? "configId";
      resultMasterFieldName = resultMasterFieldName ?? "config";
      resultSlaveFieldName = resultSlaveFieldName ?? "data";
      var resultList = masterList.map(function (masterElement) {
        return that.joinElement(masterElement, slaveList, masterListFieldName, slaveListFieldName,
          resultMasterFieldName, resultSlaveFieldName, slaveElementPrototype);
      });
      resultList = resultList.filter(function (elem) { return elem != null });
      return resultList;
    }
    return [];
  }


  private joinElement(masterElement: any, slaveList: any[], masterListFieldName: string, slaveListFieldName: string,
    resultMasterFieldName: string, resultSlaveFieldName: string, slaveElementPrototype: string) {
    var valueToSearch = masterElement[masterListFieldName];
    if (valueToSearch == undefined) {
      return null;
    }
    var slaveElement = slaveList.find(slaveElem => slaveElem[slaveListFieldName] == valueToSearch);
    if (slaveElement == undefined) {
      slaveElement = JSON.parse(slaveElementPrototype);
      slaveElement[slaveListFieldName] = valueToSearch;
      slaveList.push(slaveElement);
    }
    var result = {};
    result[resultMasterFieldName] = masterElement;
    result[resultSlaveFieldName] = slaveElement;
    return result;
  }


}
