import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StartPageComponent } from './components/start-page/start-page.component';
import { DataAccessService } from './utils/data-access-service';
import { TestComponent } from './components/test/test.component';
import { PasswordResetComponent } from './components/common/password-reset/password-reset.component';
import { ConfigAccessService } from './utils/config-access-service';
import { ThemenfinderelefantengleichnisComponent } from './components/themenfinderelefantengleichnis/themenfinderelefantengleichnis.component';
import { Themenfinderlebenssituationstep3Component } from './components/themenfinderlebenssituationstep3/themenfinderlebenssituationstep3.component';
import { GeldgefuhlestepsComponent } from './components/geldgefuhlesteps/geldgefuhlesteps.component';
import { Themenfinderlebenssituationstep2Component } from './components/themenfinderlebenssituationstep2/themenfinderlebenssituationstep2.component';
import { GeldgefuhledenkfehlerComponent } from './components/geldgefuhledenkfehler/geldgefuhledenkfehler.component';
import { LebenssituationdetailspanelComponent } from './components/lebenssituationdetailspanel/lebenssituationdetailspanel.component';
import { LoginpageComponent } from './components/loginpage/loginpage.component';
import { BeratungsidealglueckwunschComponent } from './components/beratungsidealglueckwunsch/beratungsidealglueckwunsch.component';
import { BeratungsidealformularComponent } from './components/beratungsidealformular/beratungsidealformular.component';
import { LebenssituationdetailsComponent } from './components/lebenssituationdetails/lebenssituationdetails.component';
import { ThemenfinderrisikotemperaturComponent } from './components/themenfinderrisikotemperatur/themenfinderrisikotemperatur.component';
import { BeratungsidealComponent } from './components/beratungsideal/beratungsideal.component';
import { ThemenfinderstepsComponent } from './components/themenfindersteps/themenfindersteps.component';
import { BeratungsidealberatungswunschzettelComponent } from './components/beratungsidealberatungswunschzettel/beratungsidealberatungswunschzettel.component';
import { ThemenfinderglueckwunschComponent } from './components/themenfinderglueckwunsch/themenfinderglueckwunsch.component';
import { GeldgefuehleglueckwunschComponent } from './components/geldgefuehleglueckwunsch/geldgefuehleglueckwunsch.component';
import { Themenfinderlebenssituationstep1Component } from './components/themenfinderlebenssituationstep1/themenfinderlebenssituationstep1.component';
import { GeldgefuhleComponent } from './components/geldgefuhle/geldgefuhle.component';
import { ThemenfinderComponent } from './components/themenfinder/themenfinder.component';
import { GeldgefuhleglaubenssatzeComponent } from './components/geldgefuhleglaubenssatze/geldgefuhleglaubenssatze.component';
import { GlueckwunschtemplateComponent } from './components/glueckwunschtemplate/glueckwunschtemplate.component';
import { BeratungsidealstepsComponent } from './components/beratungsidealsteps/beratungsidealsteps.component';
import { KontaktComponent } from './components/kontakt/kontakt.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'start-page',
    pathMatch: 'full'
  },
  {
    path: 'start-page',
    component: StartPageComponent,
    resolve: { modelAndConfig: DataAccessService }
  },
  {
    path: 'test',
    component: TestComponent,
    resolve: { modelAndConfig: DataAccessService }
  },
  {
    path: 'reset',
    component: PasswordResetComponent,
    resolve: { config: ConfigAccessService },
  },
  {
    path: 'themenfinderelefantengleichnis',
    component: ThemenfinderelefantengleichnisComponent,
    resolve: { modelAndConfig: DataAccessService },
    data: { pageName: 'ThemenfinderElefantengleichnis' }
  },{
    path: 'themenfinderlebenssituationstep3',
    component: Themenfinderlebenssituationstep3Component,
    resolve: { modelAndConfig: DataAccessService },
    data: { pageName: 'ThemenfinderLebenssituationStep3' }
  },{
    path: 'geldgefuhlesteps',
    component: GeldgefuhlestepsComponent,
    resolve: { modelAndConfig: DataAccessService },
    data: { pageName: 'GeldgefuhleSteps' }
  },{
    path: 'themenfinderlebenssituationstep2',
    component: Themenfinderlebenssituationstep2Component,
    resolve: { modelAndConfig: DataAccessService },
    data: { pageName: 'ThemenfinderLebenssituationStep2' }
  },{
    path: 'geldgefuhledenkfehler',
    component: GeldgefuhledenkfehlerComponent,
    resolve: { modelAndConfig: DataAccessService },
    data: { pageName: 'GeldgefuhleDenkfehler' }
  },{
    path: 'lebenssituationdetailspanel',
    component: LebenssituationdetailspanelComponent,
    resolve: { modelAndConfig: DataAccessService },
    data: { pageName: 'LebenssituationDetailsPanel' }
  },{
    path: 'loginpage',
    component: LoginpageComponent,
    resolve: { modelAndConfig: DataAccessService },
    data: { pageName: 'LoginPage' }
  },{
    path: 'beratungsidealglueckwunsch',
    component: BeratungsidealglueckwunschComponent,
    resolve: { modelAndConfig: DataAccessService },
    data: { pageName: 'BeratungsidealGlueckwunsch' }
  },{
    path: 'beratungsidealformular',
    component: BeratungsidealformularComponent,
    resolve: { modelAndConfig: DataAccessService },
    data: { pageName: 'BeratungsidealFormular' }
  },{
    path: 'lebenssituationdetails',
    component: LebenssituationdetailsComponent,
    resolve: { modelAndConfig: DataAccessService },
    data: { pageName: 'LebenssituationDetails' }
  },{
    path: 'themenfinderrisikotemperatur',
    component: ThemenfinderrisikotemperaturComponent,
    resolve: { modelAndConfig: DataAccessService },
    data: { pageName: 'ThemenfinderRisikotemperatur' }
  },{
    path: 'beratungsideal',
    component: BeratungsidealComponent,
    resolve: { modelAndConfig: DataAccessService },
    data: { pageName: 'Beratungsideal' }
  },{
    path: 'themenfindersteps',
    component: ThemenfinderstepsComponent,
    resolve: { modelAndConfig: DataAccessService },
    data: { pageName: 'ThemenfinderSteps' }
  },{
    path: 'beratungsidealberatungswunschzettel',
    component: BeratungsidealberatungswunschzettelComponent,
    resolve: { modelAndConfig: DataAccessService },
    data: { pageName: 'BeratungsidealBeratungswunschzettel' }
  },{
    path: 'themenfinderglueckwunsch',
    component: ThemenfinderglueckwunschComponent,
    resolve: { modelAndConfig: DataAccessService },
    data: { pageName: 'ThemenfinderGlueckwunsch' }
  },{
    path: 'geldgefuehleglueckwunsch',
    component: GeldgefuehleglueckwunschComponent,
    resolve: { modelAndConfig: DataAccessService },
    data: { pageName: 'GeldgefuehleGlueckwunsch' }
  },{
    path: 'themenfinderlebenssituationstep1',
    component: Themenfinderlebenssituationstep1Component,
    resolve: { modelAndConfig: DataAccessService },
    data: { pageName: 'ThemenfinderLebenssituationStep1' }
  },{
    path: 'geldgefuhle',
    component: GeldgefuhleComponent,
    resolve: { modelAndConfig: DataAccessService },
    data: { pageName: 'Geldgefuhle' }
  },{
    path: 'themenfinder',
    component: ThemenfinderComponent,
    resolve: { modelAndConfig: DataAccessService },
    data: { pageName: 'Themenfinder' }
  },{
    path: 'geldgefuhleglaubenssatze',
    component: GeldgefuhleglaubenssatzeComponent,
    resolve: { modelAndConfig: DataAccessService },
    data: { pageName: 'GeldgefuhleGlaubenssatze' }
  },{
    path: 'glueckwunschtemplate',
    component: GlueckwunschtemplateComponent,
    resolve: { modelAndConfig: DataAccessService },
    data: { pageName: 'GlueckwunschTemplate' }
  },{
    path: 'beratungsidealsteps',
    component: BeratungsidealstepsComponent,
    resolve: { modelAndConfig: DataAccessService },
    data: { pageName: 'BeratungsidealSteps' }
  },{
    path: 'kontakt',
    component: KontaktComponent,
    resolve: { modelAndConfig: DataAccessService },
    data: { pageName: 'Kontakt' }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
