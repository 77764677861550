<div
  class="blocker-container popup"
  fxLayout="column"
  fxLayoutAlign="center center"
>
  <div class="popup-bar" fxLayout="row" fxLayoutAlign="start center">
    {{ title }}
  </div>
  <div class="popup-content">
    {{ message }}
  </div>
</div>
