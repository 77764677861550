import { SignUpComponent } from './../components/common/sign-up/sign-up.component';
import { AuthResult, RegisterType, AuthRequest } from './auth.utils';
import { DialogService } from './dialog/dialog.service';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, race, Subject } from 'rxjs';
import { EndUserCredentialsResourceService } from './../usersdk/api/endUserCredentialsResource.service';
import { EndUserResourceService } from './../usersdk/api/endUserResource.service';
import { Injectable } from '@angular/core';
import { UserStatusDTO } from '../usersdk';
import { SignInComponent } from '../components/common/sign-in/sign-in.component';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userStatus$: BehaviorSubject<UserStatusDTO> = new BehaviorSubject(
    undefined
  );
  public userStatusObservable: Observable<UserStatusDTO> =
    this.userStatus$.asObservable();

  private readonly SESSION_TOKEN: string = 'session_token';
  private readonly USER_KEY: string = 'user_key';

  constructor(
    private endUserCredentialsService: EndUserCredentialsResourceService,
    private endUserService: EndUserResourceService,
    private dialogService: DialogService
  ) {}

  public login(
    userKey: string,
    config: any,
    status: UserStatusDTO
  ): Observable<boolean> {
    const resultObservable: Subject<AuthResult> = new Subject();
    const requestSubject: Subject<string> = new Subject();

    const dialogObservable: Observable<boolean> = this.dialogService
      .open(SignInComponent, {
        disableClose: true,
        backdropClass: 'sign-in-backdrop',
        data: {
          config: config,
          resultObservable: resultObservable.asObservable(),
          requestSubject: requestSubject,
          firstName: status.firstName,
          lastName: status.lastName,
          token: userKey,
        },
        height: '60%',
        width: '70%',
      })
      .afterClosed()
      .pipe(tap((result) => console.log('Dialog observable result' + result)));

    const requestObservable: Observable<boolean> = requestSubject
      .asObservable()
      .pipe(
        mergeMap((password) => {
          return this.endUserCredentialsService
            .loginUsingPOST(password, userKey)
            .pipe(
              catchError((err) => {
                resultObservable.next({ error: 'Login failed' });
                return of(false);
              }),
              mergeMap((result) => {
                if (typeof result === 'boolean') {
                  console.log('false from request observable');
                  return of(false);
                }
                console.log('Session token got');
                this.setSessionToken(result.value);
                resultObservable.next({ ok: {} });
                resultObservable.complete();
                console.log('Returning true');
                return of(true);
              })
            );
        })
      );

    return race(requestObservable, dialogObservable);
  }

  public register(
    config: any,
    document: any,
    status: UserStatusDTO
  ): Observable<boolean> {
    const resultObservable: Subject<AuthResult> = new Subject();
    const requestSubject: Subject<AuthRequest> = new Subject();
    const userKey: string = this.getUserKey();

    const dialogObservable: Observable<void> = this.dialogService
      .open(SignUpComponent, {
        backdropClass: 'sign-up-backdrop',
        disableClose: false,
        data: {
          config: config,
          resultObservable: resultObservable.asObservable(),
          requestSubject: requestSubject,
          firstName: status.firstName,
          lastName: status.lastName,
          mode: RegisterType.SAVE,
        },
        height: '85%',
        width: '95%',
      })
      .afterClosed();

    return requestSubject.asObservable().pipe(
      mergeMap((request) => {
        if (request.type === RegisterType.FINALIZE) {
          return this.endUserService
            .finalizeWithoutPasswordUsingPUT(document, userKey)
            .pipe(
              mergeMap((res) => {
                if (res.messageKey) {
                  resultObservable.next({ error: res.messageKey });
                  return of(false);
                } else {
                  resultObservable.next({ ok: {} });
                  resultObservable.complete();
                  return dialogObservable.pipe(map(() => true));
                }
              })
            );
        }
        const isFinalize = request.type === RegisterType.FINALIZE_WITH_PASSWORD;
        return this.endUserService
          .saveCurrentIntermediateStatusUsingPOST(
            request.consent,
            document,
            isFinalize,
            request.password,
            userKey
          )
          .pipe(
            catchError((err) => {
              resultObservable.next({ error: err.error.message });
              return of(false);
            }),
            mergeMap((result) => {
              if (typeof result === 'boolean') {
                return of(false);
              }
              this.setSessionToken(result.value);
              resultObservable.next({ ok: {} });
              resultObservable.complete();
              return dialogObservable.pipe(map(() => true));
            })
          );
      })
    );
  }

  private setSessionToken(sessionToken: string) {
    if (sessionToken) {
      sessionStorage.removeItem(this.USER_KEY);
      sessionStorage.setItem(this.SESSION_TOKEN, sessionToken);
    } else {
      sessionStorage.removeItem(this.SESSION_TOKEN);
    }
  }

  private getUserKey() {
    return sessionStorage.getItem(this.USER_KEY);
  }
}
