import { AuthService } from './utils/auth.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {
  FullscreenOverlayContainer,
  OverlayContainer,
  OverlayModule,
} from '@angular/cdk/overlay';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StartPageComponent } from './components/start-page/start-page.component';
import { DataAccessService } from './utils/data-access-service';
import { ConfigAccessService } from './utils/config-access-service';
import { TestComponent } from './components/test/test.component';
import { ApiModule, Configuration } from './usersdk';
import { environment } from '../environments/environment';
import { DataCreationService } from './utils/data-creation-service';
import { DialogComponent } from './utils/dialog/dialog/dialog.component';

import { DialogCloseDirective } from './utils/dialog/dialog-close.directive';
import { PortalModule } from '@angular/cdk/portal';
import { BlockerPopupComponent } from './components/common/blocker-popup/blocker-popup.component';
import { OnboardingComponent } from './components/common/onboarding/onboarding.component';
import { PasswordStrenghtLevelComponent } from './components/common/password-strenght-level/password-strenght-level.component';
import { PasswordResetComponent } from './components/common/password-reset/password-reset.component';
import { CookieConsentBannerComponent } from './components/common/cookie-consent-banner/cookie-consent-banner.component';
import { ToggleComponent } from './components/common/toggle/toggle.component';
import { SignUpComponent } from './components/common/sign-up/sign-up.component';
import { SignInComponent } from './components/common/sign-in/sign-in.component';
import { Themenfinderlebenssituationstep3Component } from './components/themenfinderlebenssituationstep3/themenfinderlebenssituationstep3.component';
import { GeldgefuhledenkfehlerComponent } from './components/geldgefuhledenkfehler/geldgefuhledenkfehler.component';
import { GeldgefuehleglueckwunschComponent } from './components/geldgefuehleglueckwunsch/geldgefuehleglueckwunsch.component';
import { Themenfinderlebenssituationstep1Component } from './components/themenfinderlebenssituationstep1/themenfinderlebenssituationstep1.component';
import { GeldgefuhleComponent } from './components/geldgefuhle/geldgefuhle.component';
import { ThemenfinderComponent } from './components/themenfinder/themenfinder.component';
import { ThemenfinderelefantengleichnisComponent } from './components/themenfinderelefantengleichnis/themenfinderelefantengleichnis.component';
import { GeldgefuhleglaubenssatzeComponent } from './components/geldgefuhleglaubenssatze/geldgefuhleglaubenssatze.component';
import { GlueckwunschtemplateComponent } from './components/glueckwunschtemplate/glueckwunschtemplate.component';
import { BeratungsidealstepsComponent } from './components/beratungsidealsteps/beratungsidealsteps.component';
import { KontaktComponent } from './components/kontakt/kontakt.component';
import { LebenssituationdetailspanelComponent } from './components/lebenssituationdetailspanel/lebenssituationdetailspanel.component';
import { LebenssituationdetailsComponent } from './components/lebenssituationdetails/lebenssituationdetails.component';
import { ThemenfinderglueckwunschComponent } from './components/themenfinderglueckwunsch/themenfinderglueckwunsch.component';
import { LoginpageComponent } from './components/loginpage/loginpage.component';
import { BeratungsidealformularComponent } from './components/beratungsidealformular/beratungsidealformular.component';
import { BeratungsidealglueckwunschComponent } from './components/beratungsidealglueckwunsch/beratungsidealglueckwunsch.component';
import { BeratungsidealComponent } from './components/beratungsideal/beratungsideal.component';
import { ThemenfinderstepsComponent } from './components/themenfindersteps/themenfindersteps.component';
import { BeratungsidealberatungswunschzettelComponent } from './components/beratungsidealberatungswunschzettel/beratungsidealberatungswunschzettel.component';
import { ThemenfinderrisikotemperaturComponent } from './components/themenfinderrisikotemperatur/themenfinderrisikotemperatur.component';
import { GeldgefuhlestepsComponent } from './components/geldgefuhlesteps/geldgefuhlesteps.component';
import { Themenfinderlebenssituationstep2Component } from './components/themenfinderlebenssituationstep2/themenfinderlebenssituationstep2.component';

export function getAPIConfiguration() {
  return new Configuration({ basePath: environment.userApiUrl });
}

@NgModule({
  declarations: [
    AppComponent,
    StartPageComponent,
    TestComponent,
    DialogComponent,
    DialogCloseDirective,
    BlockerPopupComponent,
    OnboardingComponent,
    PasswordStrenghtLevelComponent,
    PasswordResetComponent,
    CookieConsentBannerComponent,
    ToggleComponent,
    SignUpComponent,
    SignInComponent,
    Themenfinderlebenssituationstep3Component,
    GeldgefuhledenkfehlerComponent,
    GeldgefuehleglueckwunschComponent,
    Themenfinderlebenssituationstep1Component,
    GeldgefuhleComponent,
    ThemenfinderComponent,
    ThemenfinderelefantengleichnisComponent,
    GeldgefuhleglaubenssatzeComponent,
    GlueckwunschtemplateComponent,
    BeratungsidealstepsComponent,
    KontaktComponent,
    LebenssituationdetailspanelComponent,
    LebenssituationdetailsComponent,
    ThemenfinderglueckwunschComponent,
    LoginpageComponent,
    BeratungsidealformularComponent,
    BeratungsidealglueckwunschComponent,
    BeratungsidealComponent,
    ThemenfinderstepsComponent,
    BeratungsidealberatungswunschzettelComponent,
    ThemenfinderrisikotemperaturComponent,
    GeldgefuhlestepsComponent,
    Themenfinderlebenssituationstep2Component,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    HttpClientModule,
    FormsModule,
    ApiModule.forRoot(getAPIConfiguration),
    PortalModule,
    OverlayModule,
  ],
  providers: [
    AuthService,
    DataAccessService,
    ConfigAccessService,
    DataCreationService,
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
  ],
  entryComponents: [DialogComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
