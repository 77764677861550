/**
 * userAPI API
 * userAPI API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AppConfigurationDTO } from '../model/appConfigurationDTO';
import { DataDTO } from '../model/dataDTO';
import { StringWrapperDTO } from '../model/stringWrapperDTO';
import { ValueWrapperDTOOflong } from '../model/valueWrapperDTOOflong';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AppConfigurationResourceService {

    protected basePath = 'https://localhost:8082';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * createAppConfigurationCopy
     * 
     * @param appConfigurationDTO appConfigurationDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAppConfigurationCopyUsingPOST(appConfigurationDTO: AppConfigurationDTO, observe?: 'body', reportProgress?: boolean): Observable<AppConfigurationDTO>;
    public createAppConfigurationCopyUsingPOST(appConfigurationDTO: AppConfigurationDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AppConfigurationDTO>>;
    public createAppConfigurationCopyUsingPOST(appConfigurationDTO: AppConfigurationDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AppConfigurationDTO>>;
    public createAppConfigurationCopyUsingPOST(appConfigurationDTO: AppConfigurationDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appConfigurationDTO === null || appConfigurationDTO === undefined) {
            throw new Error('Required parameter appConfigurationDTO was null or undefined when calling createAppConfigurationCopyUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AppConfigurationDTO>(`${this.basePath}/api/app-configurations/copy`,
            appConfigurationDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createAppConfiguration
     * 
     * @param appConfigurationDTO appConfigurationDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAppConfigurationUsingPOST(appConfigurationDTO: AppConfigurationDTO, observe?: 'body', reportProgress?: boolean): Observable<AppConfigurationDTO>;
    public createAppConfigurationUsingPOST(appConfigurationDTO: AppConfigurationDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AppConfigurationDTO>>;
    public createAppConfigurationUsingPOST(appConfigurationDTO: AppConfigurationDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AppConfigurationDTO>>;
    public createAppConfigurationUsingPOST(appConfigurationDTO: AppConfigurationDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appConfigurationDTO === null || appConfigurationDTO === undefined) {
            throw new Error('Required parameter appConfigurationDTO was null or undefined when calling createAppConfigurationUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AppConfigurationDTO>(`${this.basePath}/api/app-configurations`,
            appConfigurationDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteAppConfiguration
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAppConfigurationUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteAppConfigurationUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteAppConfigurationUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteAppConfigurationUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteAppConfigurationUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/app-configurations/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAppConfiguration
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppConfigurationUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<AppConfigurationDTO>;
    public getAppConfigurationUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AppConfigurationDTO>>;
    public getAppConfigurationUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AppConfigurationDTO>>;
    public getAppConfigurationUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getAppConfigurationUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<AppConfigurationDTO>(`${this.basePath}/api/app-configurations/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAppUrlWithConfiguration
     * 
     * @param configId configId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppUrlWithConfigurationUsingGET(configId: number, observe?: 'body', reportProgress?: boolean): Observable<StringWrapperDTO>;
    public getAppUrlWithConfigurationUsingGET(configId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StringWrapperDTO>>;
    public getAppUrlWithConfigurationUsingGET(configId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StringWrapperDTO>>;
    public getAppUrlWithConfigurationUsingGET(configId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (configId === null || configId === undefined) {
            throw new Error('Required parameter configId was null or undefined when calling getAppUrlWithConfigurationUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<StringWrapperDTO>(`${this.basePath}/api/app-configurations/${encodeURIComponent(String(configId))}/app-url`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getConfigDataTypeId
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfigDataTypeIdUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<ValueWrapperDTOOflong>;
    public getConfigDataTypeIdUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ValueWrapperDTOOflong>>;
    public getConfigDataTypeIdUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ValueWrapperDTOOflong>>;
    public getConfigDataTypeIdUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getConfigDataTypeIdUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ValueWrapperDTOOflong>(`${this.basePath}/api/app-configurations/${encodeURIComponent(String(id))}/data-type-id`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getDocument
     * 
     * @param documentId documentId
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocumentUsingGET(documentId: string, id: number, observe?: 'body', reportProgress?: boolean): Observable<DataDTO>;
    public getDocumentUsingGET(documentId: string, id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DataDTO>>;
    public getDocumentUsingGET(documentId: string, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DataDTO>>;
    public getDocumentUsingGET(documentId: string, id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling getDocumentUsingGET.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getDocumentUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (documentId !== undefined && documentId !== null) {
            queryParameters = queryParameters.set('documentId', <any>documentId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DataDTO>(`${this.basePath}/api/app-configurations/${encodeURIComponent(String(id))}/es-doc`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * saveElasticsearchDocument
     * 
     * @param document document
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveElasticsearchDocumentUsingPATCH(document: any, id: number, observe?: 'body', reportProgress?: boolean): Observable<DataDTO>;
    public saveElasticsearchDocumentUsingPATCH(document: any, id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DataDTO>>;
    public saveElasticsearchDocumentUsingPATCH(document: any, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DataDTO>>;
    public saveElasticsearchDocumentUsingPATCH(document: any, id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (document === null || document === undefined) {
            throw new Error('Required parameter document was null or undefined when calling saveElasticsearchDocumentUsingPATCH.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling saveElasticsearchDocumentUsingPATCH.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<DataDTO>(`${this.basePath}/api/app-configurations/${encodeURIComponent(String(id))}/es-doc`,
            document,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateAppConfiguration
     * 
     * @param appConfigurationDTO appConfigurationDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAppConfigurationUsingPUT(appConfigurationDTO: AppConfigurationDTO, observe?: 'body', reportProgress?: boolean): Observable<AppConfigurationDTO>;
    public updateAppConfigurationUsingPUT(appConfigurationDTO: AppConfigurationDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AppConfigurationDTO>>;
    public updateAppConfigurationUsingPUT(appConfigurationDTO: AppConfigurationDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AppConfigurationDTO>>;
    public updateAppConfigurationUsingPUT(appConfigurationDTO: AppConfigurationDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appConfigurationDTO === null || appConfigurationDTO === undefined) {
            throw new Error('Required parameter appConfigurationDTO was null or undefined when calling updateAppConfigurationUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<AppConfigurationDTO>(`${this.basePath}/api/app-configurations`,
            appConfigurationDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
