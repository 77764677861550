import { Component, Inject, OnInit, Optional } from '@angular/core';
import { DialogRef } from 'src/app/utils/dialog/dialog-ref';
import { DIALOG_DATA } from 'src/app/utils/dialog/dialog.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements OnInit {
  public config: any;
  public showOnboarding: false;
  public showWelcomeBack: false;

  constructor(
    private dialogRef: DialogRef<string>,
    @Optional() @Inject(DIALOG_DATA) public data?: any
  ) {
    this.config = data.config;
    this.showOnboarding = data.showOnboarding;
    this.showWelcomeBack = data.showWelcomeBack;
  }

  ngOnInit(): void { }

  confirm(event) {
    event.stopPropagation();
    //todo

    this.dialogRef.close();
  }
}
