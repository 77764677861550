import { EndUserCredentialsResourceService } from 'src/app/usersdk';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { DialogRef } from 'src/app/utils/dialog/dialog-ref';
import { DIALOG_DATA } from 'src/app/utils/dialog/dialog.service';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthResult } from 'src/app/utils/auth.utils';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  public isForgotPassword: boolean = false;
  public isResetSent: boolean = false;
  public isResetError: boolean = false;
  public isResetSending: boolean = false;

  public appName: string = '';
  public buttonLabel: string = '';
  public logoUrl: string = '';
  public firstName: string = '';
  public lastName: string = '';

  password: string;
  error: string = undefined;
  private requestSubject: Subject<any>;
  private resultObservable: Observable<AuthResult>;

  constructor(
    private dialogRef: DialogRef<boolean>,
    private endUserCredentialsService: EndUserCredentialsResourceService,
    private router: Router,
    @Optional() @Inject(DIALOG_DATA) public data?: any
  ) {
    this.requestSubject = data.requestSubject;
    this.resultObservable = data.resultObservable;
    const config = data.config;

    this.appName = '';
    this.buttonLabel = '';
    this.logoUrl = '';
    this.firstName = data.firstName;
    this.lastName = data.lastName;
  }

  ngOnInit(): void {
    this.resultObservable.subscribe((res) => {
      if (res.ok) {
        this.dialogRef.close(true);
      } else {
        this.error = res.error;
      }
    });
  }

  confirm() {
    this.error = undefined;
    if (!this.password) {
      return;
    }
    this.requestSubject.next(this.password);
  }

  openImpressum(event) {
    event.stopPropagation();
    this.dialogRef.close();
    this.router.navigate(''.split('/'));
  }

  openPrivacyPolicy(event) {
    event.stopPropagation();
    this.dialogRef.close();
    this.router.navigate(''.split('/'));
  }

  forgotPassword(event) {
    event.stopPropagation();
    this.isForgotPassword = true;
  }

  sendReset(event) {
    event.stopPropagation();
    this.isResetSending = true;
    this.endUserCredentialsService
      .passwordResetStartUsingPOST(this.data.token)
      .subscribe(
        () => {
          this.isResetSending = false;
          this.isResetSent = true;
        },
        (error) => {
          this.isResetSending = false;
          this.isResetError = true;
          this.isResetSent = true;
        }
      );
  }

  cancelReset(event) {
    event.stopPropagation();
    this.isForgotPassword = false;
  }

  isDisabled() {
    return !this.password;
  }
}
