import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-password-strenght-level',
  templateUrl: './password-strenght-level.component.html',
  styleUrls: ['./password-strenght-level.component.scss'],
})
export class PasswordStrenghtLevelComponent implements OnInit {
  @Input()
  public set level(val: number) {
    this._level = val;
    this.calculateValues();
  }

  @Input('colors')
  public set _colors(val: string[]) {
    this.colors = val;
    this.calculateValues();
  }

  private colors: string[] = [];

  @Input()
  public set labels(val: string[]) {
    this._labels = val;
    this.calculateValues();
  }

  private _labels: string[] = [];

  public color: string = '';
  public label: string = '';
  private _level: number = 0;

  public levelsArray: number[] = [1, 2, 3, 4];

  constructor() {}

  ngOnInit(): void {}

  calculateValues() {
    this.label = this._labels[this._level];
    this.color = this.colors[this._level];
  }

  getBarColor(lvl: number): string {
    if (this._level < lvl) {
      return this.colors[0];
    }
    return this.color;
  }
}
