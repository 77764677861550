<div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="grow" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="40px" layout-id="246926" widget-id="248014 root div_Imported_Imported_Imported_Imported">
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="246952" widget-id="248052 content row div_Imported_Imported_Imported_Imported">
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="33%" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="246985" widget-id="248134 BERATUNGSWUNSCHZETTEL div_Imported_Imported_Imported_Imported">
   <div class="step-header brand-radius brand-shadow brand-gradient" [class.brand-gradient]="model.appState.beratungsWunschzettelDone" (click)="wappnet_step_header_Imported_Imported_Importedheader1click248960('beratungsidealberatungswunschzettel', undefined, true, undefined, [], undefined, undefined)" widget-id="248231 header1 wappnet_step_header_Imported_Imported_Imported"> 
    <div class="step-title">
     {{config.beratungsideal.steps.step1_title}}
    </div> 
    <div class="step-number">
     {{config.beratungsideal.steps.step1_counter}}
    </div> 
   </div>
   <div attachment-point-for="content" [ngClass]="'brand-offset-small-inner brand-radius brand-shadow-inset'" *ngIf="true" widget-id="248230 content panel div_Imported_Imported_Imported_Imported">
    <article widget-id="248357 step 1 text content wappnet_text-with-header_Imported_Imported_Imported"> 
     <header>
      {{config.beratungsideal.steps.header1}}
     </header> 
     <p>{{config.beratungsideal.steps.paragraph1}}</p> 
    </article>
   </div>
  </div>
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="33%" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="246986" widget-id="248135 FORMULAR div_Imported_Imported_Imported_Imported">
   <div class="step-header brand-radius brand-shadow brand-gradient" [class.brand-gradient]="model.appState.dialogWunschDone" (click)="wappnet_step_header_Imported_Imported_Importedheader2click248961('beratungsidealformular', undefined, true, undefined, [], undefined, undefined)" widget-id="248232 header2 wappnet_step_header_Imported_Imported_Imported"> 
    <div class="step-title">
     {{config.beratungsideal.steps.step2_title}}
    </div> 
    <div class="step-number">
     {{config.beratungsideal.steps.step2_counter}}
    </div> 
   </div>
   <div attachment-point-for="content" [ngClass]="'brand-offset-small-inner brand-radius brand-shadow-inset'" *ngIf="true" fxFlex="grow" widget-id="248233 content panel div_Imported_Imported_Imported_Imported">
    <article widget-id="248358 step 2 text content wappnet_text-with-header_Imported_Imported_Imported"> 
     <header>
      {{config.beratungsideal.steps.header2}}
     </header> 
     <p>{{config.beratungsideal.steps.paragraph2}}</p> 
    </article>
   </div>
  </div>
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="33%" widget-id="248136 placeholder div_Imported_Imported_Imported_Imported"></div>
 </div><ng-template cdkConnectedOverlay [cdkConnectedOverlayOpen]="isOpen248053" [cdkConnectedOverlayHasBackdrop]="true" (backdropClick)="isOpen248053 = false" [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'" [cdkConnectedOverlayPositions]="panelPositions" widget-id="248053 navigation row div_Imported_Imported_Imported_Imported"> 
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="246953"> 
   <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="33%" widget-id="248137 placeholder 1 div_Imported_Imported_Imported_Imported"></div> 
   <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="33%" widget-id="248138 placeholder 2 div_Imported_Imported_Imported_Imported"></div> 
   <div class="btn-main" (click)="wappnet_nav_button_Imported_Imported_Importednav_buttonnavigate248919('beratungsidealberatungswunschzettel', undefined, true, undefined, [], undefined, undefined)" fxFlex="33%" widget-id="248139 nav button wappnet_nav_button_Imported_Imported_Imported">
     {{config.beratungsideal.steps.nav_button_text}} 
   </div> 
  </div> 
 </ng-template>
</div>