<div fxFlexFill fxLayout="column">
  <div
    *ngIf="isEnd"
    class="password-reset-success"
    fxLayout="column"
    fxLayoutAlign="start center"
    style="padding: 16px 24px; overflow-y: auto; flex: 1"
  >
    <p class="password-reset-success-message">Password successfully changed.</p>
    <div fxLayout="row" fxLayoutGap="16px">
      <p class="password-reset-success-redirect">
        You will be redirected to the home page shortly.
        <a
          class="password-reset-success-redirect-link"
          (click)="redirect($event)"
          >Redirect now</a
        >
      </p>
    </div>
  </div>

  <div
    *ngIf="!isEnd"
    class="password-reset-content"
    fxLayout="column"
    fxLayoutAlign="start center"
    style="padding: 16px 24px; overflow: auto; flex: 1"
  >
    <div class="error-sign" *ngIf="error">{{ error }}</div>
    <p class="password-reset-content-desc">
      Passwort zurücksetzen. Bitte geben Sie ein neues ein
    </p>

    <form
      #form="ngForm"
      (ngSubmit)="confirm()"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="10px"
      style="flex: 1"
    >
      <div fxLayout="column" fxLayoutGap="4px">
        <label for="pass">Neues Passwort</label>
        <input
          class="system-input"
          type="password"
          id="pass"
          name="password"
          placeholder="Neues Passwort"
          [(ngModel)]="password"
          required
          (keyup)="validatePwd($event)"
        />
      </div>

      <div fxLayout="column" fxLayoutGap="2px">
        <label for="passwordrptd">Passwort wiederholen</label>
        <input
          class="system-input"
          type="password"
          id="passrptd"
          name="passwordrptd"
          placeholder="Passwort wiederholen"
          [(ngModel)]="passwordrptd"
          required
        />
      </div>
      <app-password-strenght-level
        class="password-reset-content-password-strenght"
        [level]="passwordStrength.strengthLevel"
        [colors]="passwordIndicatorColors"
        [labels]="passwordIndicatorLabels"
      ></app-password-strenght-level>
      <input
        class="system-button"
        type="submit"
        value="Reset"
        [disabled]="isDisabled()"
      />
    </form>
  </div>
</div>
