<div fxLayout="column" fxFlexFill class="popup-sign-in">
  <!-- Header -->
  <div
    class="popup-sign-in-header"
    fxLayout="column"
    fxLayoutAlign="start center"
  >
    <div
      class="image-container popup-sign-in-header-app-logo"
      fxLayout="column"
      fxLayoutAlign="center center"
      [style.background-image]="logoUrl"
    ></div>
    <h2 class="popup-sign-in-header-app-name">{{ appName }}</h2>
  </div>

  <!-- Content -->
  <div fxLayout="column" fxLayoutAlign="start center" class="popup-content">
    <div class="error-sign" *ngIf="error">{{ error }}</div>

    <!-- Login/Register -->
    <form
      #loginForm="ngForm"
      (ngSubmit)="confirm()"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      style="flex: 1"
      *ngIf="!isForgotPassword"
    >
      <!-- Password -->
      <div
        class="sign-in-form-element sign-in-password-element"
        fxLayout="column"
      >
        <label for="pass">Passwort</label>
        <input
          class="system-input"
          type="password"
          id="pass"
          name="password"
          placeholder="Passwort"
          [(ngModel)]="password"
          required
        />
      </div>

      <!-- Submit -->
      <input
        type="submit"
        [value]="buttonLabel"
        [disabled]="isDisabled()"
        class="sign-in-form-element system-button"
      />
    </form>

    <!-- Forgot password button -->
    <span
      *ngIf="!isForgotPassword"
      class="sign-in-forgot-password-link"
      (click)="forgotPassword($event)"
    >
      Passwort vergessen
    </span>

    <!-- Forgot password page -->
    <div *ngIf="isForgotPassword">
      <div *ngIf="isResetSent" fxLayout="column" style="flex: 1">
        <div *ngIf="!isResetError" class="sign-in-password-reset-mail-sent">
          E-Mail zum Zurücksetzen des Passworts gesendet.
        </div>
        <div *ngIf="isResetError" class="sign-in-password-reset-mail-error">
          Etwas ist schief gelaufen!
        </div>
      </div>
      <div *ngIf="!isResetSent && !isResetSending" style="flex: 1">
        <p class="sign-in-password-reset-message">
          E-Mail senden, um Passwort zurückzusetzen?
        </p>
        <div
          fxLayout="row"
          fxLayoutAlign="space-around"
          class="sign-in-password-reset-buttons"
        >
          <input
            type="button"
            (click)="sendReset($event)"
            value="Ja"
            class="sign-in-password-reset-button-yes"
          />
          <input
            type="button"
            (click)="cancelReset($event)"
            value="Nein"
            class="sign-in-password-reset-button-no"
          />
        </div>
      </div>
      <p *ngIf="isResetSending" class="sign-in-password-reset-message-sending">
        Versenden von E-Mails...
      </p>
    </div>

    <!-- Impressum -->
    <div fxLayout="row" fxLayout="space-between" class="sign-in-info-panel">
      <a class="sign-in-link-impressum" (click)="openImpressum($event)"
        >Impressum</a
      >
      <a class="sign-in-link-privacy" (click)="openPrivacyPolicy($event)"
        >Datenschutz</a
      >
    </div>
  </div>
</div>
