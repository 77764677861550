import { EndUserCredentialsResourceService } from 'src/app/usersdk';
import { PasswordStrengthInfoDTO } from './../../../usersdk/model/passwordStrengthInfoDTO';
import { switchMap } from 'rxjs/operators';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { DialogRef } from 'src/app/utils/dialog/dialog-ref';
import { DIALOG_DATA } from 'src/app/utils/dialog/dialog.service';
import { of, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthResult, RegisterType } from 'src/app/utils/auth.utils';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  public isFinalize: boolean = false;
  public wantToRegister: boolean = false;
  public appName: string = '';
  public buttonLabel: string = '';
  public consentText: string = '';
  public logoUrl: string = '';
  public registerButtonLabel: string = '';
  public finalizeButtonLabel: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public passwordIndicatorColors: string[] = [];
  public passwordIndicatorLabels: string[] = [];

  password: string;
  passwordrptd: string;
  error: string = undefined;
  consentChecked: boolean = false;
  passwordStrength: PasswordStrengthInfoDTO = { strengthLevel: 0 };
  private requestSubject: Subject<any>;
  private resultObservable: Observable<AuthResult>;
  private mode: RegisterType = RegisterType.SAVE;

  constructor(
    private dialogRef: DialogRef<boolean>,
    private endUserCredentialsService: EndUserCredentialsResourceService,
    private router: Router,
    @Optional() @Inject(DIALOG_DATA) public data?: any
  ) {
    this.requestSubject = data.requestSubject;
    this.resultObservable = data.resultObservable;
    this.mode = data.mode;
    const config = data.config;

    this.appName = '';
    this.finalizeButtonLabel = '';
    this.registerButtonLabel = '';

    this.buttonLabel = this.registerButtonLabel;
    this.consentText = '';
    this.logoUrl = '';
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.passwordIndicatorColors = [];
    this.passwordIndicatorLabels = [];

    if (this.mode === RegisterType.FINALIZE) {
      this.isFinalize = true;
      this.buttonLabel = this.finalizeButtonLabel;
    } else if (this.mode === RegisterType.FINALIZE_WITH_PASSWORD) {
      this.isFinalize = true;
      this.buttonLabel = this.finalizeButtonLabel;
      this.wantToRegister = true;
    } else if (this.mode === RegisterType.SAVE) {
      this.isFinalize = false;
      this.buttonLabel = this.registerButtonLabel;
    }
  }

  ngOnInit(): void {
    this.resultObservable.subscribe((res) => {
      if (res.ok) {
        this.dialogRef.close(true);
      } else {
        this.error = res.error;
      }
    });
  }

  confirm() {
    this.error = undefined;
    // when is Finalize and user doesn't want to register it should check only consent.
    // when is Finalize and user wants to register or it is normal register it should check consent and password
    const isRegister: boolean =
      (this.isFinalize && this.wantToRegister) || !this.isFinalize;
    if (
      (this.isFinalize && !this.wantToRegister && !this.consentChecked) ||
      (isRegister &&
        (!this.password || !this.passwordrptd || !this.consentChecked))
    ) {
      return;
    }
    if (isRegister && this.password !== this.passwordrptd) {
      this.error = 'Das wiederholte Kennwort ist nicht gleich.';
      return;
    }
    if (this.isFinalize && !this.wantToRegister) {
      this.requestSubject.next({
        type: RegisterType.FINALIZE,
        consent: this.consentChecked,
      });
    } else if (this.isFinalize && this.wantToRegister) {
      this.requestSubject.next({
        type: RegisterType.FINALIZE_WITH_PASSWORD,
        password: this.password,
        consent: this.consentChecked,
      });
    } else {
      this.requestSubject.next({
        type: RegisterType.SAVE,
        password: this.password,
        consent: this.consentChecked,
      });
    }
  }

  validatePwd(keyEvent: KeyboardEvent) {
    if (keyEvent.key === 'enter' || keyEvent.key === 'shift') {
      return;
    }
    if (!this.password) {
      this.passwordStrength = {
        entropy: 0,
        isAccepted: false,
        strengthLevel: 0,
      };
      return;
    }
    this.endUserCredentialsService
      .checkPasswordStrengthUsingPOST(this.password)
      .pipe(switchMap((res: PasswordStrengthInfoDTO) => of(res)))
      .subscribe(
        (result) => {
          this.passwordStrength = result;
        },
        (error) => {
          this.passwordStrength = {
            entropy: 0,
            isAccepted: false,
            strengthLevel: 0,
          };
        }
      );
  }

  openPrivacyPolicy(event) {
    event.stopPropagation();
    this.dialogRef.close(false);
    this.router.navigate(''.split('/'));
  }

  isDisabled() {
    if (this.isFinalize && !this.wantToRegister) {
      return !this.consentChecked;
    }
    return (
      !this.passwordStrength.isAccepted ||
      !this.password ||
      !this.passwordrptd ||
      !this.consentChecked
    );
  }

  onWantToRegisterChange(value) {
    if (this.buttonLabel === this.registerButtonLabel) {
      this.buttonLabel = this.finalizeButtonLabel;
    } else {
      this.buttonLabel = this.registerButtonLabel;
    }
  }

  close(event: Event) {
    event.stopPropagation();
    this.dialogRef.close(false);
  }
}
