<div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="grow" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="246929" widget-id="248019 root div_Imported_Imported_Imported_Imported">
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="246960" widget-id="248060 content row div_Imported_Imported_Imported_Imported">
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="33%" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="246989" widget-id="248152 text column div_Imported_Imported_Imported_Imported">
   <div class="step-header brand-radius brand-shadow brand-gradient" [class.brand-gradient]="true" (click)="callActions(undefined, undefined, true, undefined, [], undefined, undefined)" widget-id="248239 step header wappnet_step_header_Imported_Imported_Imported"> 
    <div class="step-title">
     {{config.beratungsideal.steps.step2_title}}
    </div> 
    <div class="step-number">
     {{config.beratungsideal.steps.step2_counter}}
    </div> 
   </div>
   <div attachment-point-for="content" [ngClass]="'brand-offset-small-inner brand-radius brand-shadow-inset'" *ngIf="true" fxFlex="grow" widget-id="248240 text panel div_Imported_Imported_Imported_Imported">
    <article fxFlex="grow" widget-id="248360 wappnet_text-with-header_Imported_Imported_Imported"> 
     <header>
      {{config.beratungsideal.formular.header}}
     </header> 
     <p>{{config.beratungsideal.formular.paragraph}}</p> 
    </article>
   </div>
  </div>
  <div attachment-point-for="content" [ngClass]="'brand-offset-inner brand-radius brand-shadow'" *ngIf="true" fxFlex="66%" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="246990" widget-id="248153 form column div_Imported_Imported_Imported_Imported">
   <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="247015" widget-id="248242 check boxes row div_Imported_Imported_Imported_Imported">
    <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="50%" widget-id="248363 column 1 div_Imported_Imported_Imported_Imported">
     <p widget-id="248430 title wappnet_paragraph_Imported_Imported_Imported">{{config.beratungsideal.formular.info_header}}</p>
     <div class="checkbox-block" widget-id="248431 Telefon wappnet_checkbox_Imported_Imported"> <label class="checkbox"> <input type="checkbox" [(ngModel)]="model.beratungsideal.formular.inf_option_1" name="info" [value]="config.beratungsideal.formular.info_option1"> <span></span> </label> 
      <div>
       {{config.beratungsideal.formular.info_option1}}
      </div> 
     </div>
     <div class="checkbox-block" widget-id="248432 Fax wappnet_checkbox_Imported_Imported"> <label class="checkbox"> <input type="checkbox" [(ngModel)]="model.beratungsideal.formular.inf_option_2" name="info" [value]="config.beratungsideal.formular.info_option2"> <span></span> </label> 
      <div>
       {{config.beratungsideal.formular.info_option2}}
      </div> 
     </div>
     <div class="checkbox-block" widget-id="248433 Post wappnet_checkbox_Imported_Imported"> <label class="checkbox"> <input type="checkbox" [(ngModel)]="model.beratungsideal.formular.inf_option_3" name="info" [value]="config.beratungsideal.formular.info_option3"> <span></span> </label> 
      <div>
       {{config.beratungsideal.formular.info_option3}}
      </div> 
     </div>
     <div class="checkbox-block" widget-id="248434 WhatsApp wappnet_checkbox_Imported_Imported"> <label class="checkbox"> <input type="checkbox" [(ngModel)]="model.beratungsideal.formular.inf_option_4" name="info" [value]="config.beratungsideal.formular.info_option4"> <span></span> </label> 
      <div>
       {{config.beratungsideal.formular.info_option4}}
      </div> 
     </div>
     <div class="checkbox-block" widget-id="248435 Email wappnet_checkbox_Imported_Imported"> <label class="checkbox"> <input type="checkbox" [(ngModel)]="model.beratungsideal.formular.inf_option_5" name="info" [value]="config.beratungsideal.formular.info_option5"> <span></span> </label> 
      <div>
       {{config.beratungsideal.formular.info_option5}}
      </div> 
     </div>
    </div>
    <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="50%" widget-id="248362 column 2 div_Imported_Imported_Imported_Imported">
     <p widget-id="248424 title wappnet_paragraph_Imported_Imported_Imported">{{config.beratungsideal.formular.advice_header}}</p>
     <div class="checkbox-block" widget-id="248425 online call option wappnet_checkbox_Imported_Imported"> <label class="checkbox"> <input type="checkbox" [(ngModel)]="model.beratungsideal.formular.advice_option_1" name="advice" [value]="config.beratungsideal.formular.advice_option1"> <span></span> </label> 
      <div>
       {{config.beratungsideal.formular.advice_option1}}
      </div> 
     </div>
     <div class="checkbox-block" widget-id="248426 bei Ihnen zuhause option wappnet_checkbox_Imported_Imported"> <label class="checkbox"> <input type="checkbox" [(ngModel)]="model.beratungsideal.formular.advice_option_2" name="advice" [value]="config.beratungsideal.formular.advice_option2"> <span></span> </label> 
      <div>
       {{config.beratungsideal.formular.advice_option2}}
      </div> 
     </div>
     <div class="checkbox-block" widget-id="248427 in meinem Büro option wappnet_checkbox_Imported_Imported"> <label class="checkbox"> <input type="checkbox" [(ngModel)]="model.beratungsideal.formular.advice_option_3" name="advice" [value]="config.beratungsideal.formular.advice_option3"> <span></span> </label> 
      <div>
       {{config.beratungsideal.formular.advice_option3}}
      </div> 
     </div>
     <div class="checkbox-block" widget-id="248428 in Ihrem Buro option wappnet_checkbox_Imported_Imported"> <label class="checkbox"> <input type="checkbox" [(ngModel)]="model.beratungsideal.formular.advice_option_4" name="advice" [value]="config.beratungsideal.formular.advice_option4"> <span></span> </label> 
      <div>
       {{config.beratungsideal.formular.advice_option4}}
      </div> 
     </div>
     <div class="checkbox-block" widget-id="248429 e-mail option wappnet_checkbox_Imported_Imported"> <label class="checkbox"> <input type="checkbox" [(ngModel)]="model.beratungsideal.formular.advice_option_5" name="advice" [value]="config.beratungsideal.formular.advice_option5"> <span></span> </label> 
      <div>
       {{config.beratungsideal.formular.advice_option5}}
      </div> 
     </div>
    </div>
   </div>
   <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" widget-id="248241 text field row div_Imported_Imported_Imported_Imported">
    <div attachment-point-for="content" [ngClass]="'brand-offset-inner brand-radius brand-shadow-inset'" *ngIf="true" widget-id="248361 text area frame div_Imported_Imported_Imported_Imported">
     <textarea [placeholder]="config.beratungsideal.formular.placeholder" [(ngModel)]="model.beratungsideal.formular.additional_requirements" widget-id="248423 wishes and requirements text area wappnet_text_area_Imported_Imported_Imported"></textarea>
    </div>
   </div>
  </div>
 </div>
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="20px" layout-id="246959" widget-id="248059 navigation div_Imported_Imported_Imported_Imported">
  <button class="btn-round fab-pointer-cursor" [class.btn-blue]="false" (click)="wappnet_fab_button_Imported_Imported_Importedbacknavigate248927('beratungsidealberatungswunschzettel', undefined, true, undefined, [], undefined, undefined)" widget-id="248150 back wappnet_fab_button_Imported_Imported_Imported"> 
   <div class="btn-round-img" [style.background-image]="config.icons.arrow_left"></div> </button><button class="btn-round temperature-icon fab-pointer-cursor" (click)="callActions('themenfinderrisikotemperatur', undefined, true, undefined, [], undefined, undefined)" widget-id="248149 wappnet_temperature_indicator_Imported_Imported_Imported"> 
   <div class="temperature-icon-peak"> 
    <div class="temperature-icon-peak-background"></div> 
   </div> 
   <div class="btn-round-img" [style.background-image]="model.themenfinder.risikotemperatur == 0 ? config.icons.temperature_low : (model.themenfinder.risikotemperatur == 1 ? config.icons.temperature : config.icons.temperature_high)"></div> </button><button class="btn-round fab-pointer-cursor" [class.btn-blue]="false" (click)="callActions('beratungsidealglueckwunsch', undefined, true, undefined, [], undefined, undefined)" widget-id="248151 forward wappnet_fab_button_Imported_Imported_Imported"> 
   <div class="btn-round-img" [style.background-image]="config.icons.arrow_right"></div> </button>
 </div>
</div>