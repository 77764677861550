/**
 * userAPI API
 * userAPI API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { EmailServerDTO } from '../model/emailServerDTO';
import { StringWrapperDTO } from '../model/stringWrapperDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EmailServerResourceService {

    protected basePath = 'https://localhost:8082';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * createEmailServer
     * 
     * @param emailServerDTO emailServerDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createEmailServerUsingPOST(emailServerDTO: EmailServerDTO, observe?: 'body', reportProgress?: boolean): Observable<EmailServerDTO>;
    public createEmailServerUsingPOST(emailServerDTO: EmailServerDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EmailServerDTO>>;
    public createEmailServerUsingPOST(emailServerDTO: EmailServerDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EmailServerDTO>>;
    public createEmailServerUsingPOST(emailServerDTO: EmailServerDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (emailServerDTO === null || emailServerDTO === undefined) {
            throw new Error('Required parameter emailServerDTO was null or undefined when calling createEmailServerUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<EmailServerDTO>(`${this.basePath}/api/email-servers`,
            emailServerDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteEmailServer
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteEmailServerUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteEmailServerUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteEmailServerUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteEmailServerUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteEmailServerUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/email-servers/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getEmailServerOfCurrentOrganisation
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEmailServerOfCurrentOrganisationUsingGET(observe?: 'body', reportProgress?: boolean): Observable<EmailServerDTO>;
    public getEmailServerOfCurrentOrganisationUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EmailServerDTO>>;
    public getEmailServerOfCurrentOrganisationUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EmailServerDTO>>;
    public getEmailServerOfCurrentOrganisationUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<EmailServerDTO>(`${this.basePath}/api/email-servers/current-organisation`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getEmailServer
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEmailServerUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<EmailServerDTO>;
    public getEmailServerUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EmailServerDTO>>;
    public getEmailServerUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EmailServerDTO>>;
    public getEmailServerUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getEmailServerUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<EmailServerDTO>(`${this.basePath}/api/email-servers/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * sendTestMail
     * 
     * @param receiver receiver
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendTestMailUsingPOST(receiver: StringWrapperDTO, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sendTestMailUsingPOST(receiver: StringWrapperDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sendTestMailUsingPOST(receiver: StringWrapperDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sendTestMailUsingPOST(receiver: StringWrapperDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (receiver === null || receiver === undefined) {
            throw new Error('Required parameter receiver was null or undefined when calling sendTestMailUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/email-servers/test-mail`,
            receiver,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateEmailServer
     * 
     * @param emailServerDTO emailServerDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateEmailServerUsingPUT(emailServerDTO: EmailServerDTO, observe?: 'body', reportProgress?: boolean): Observable<EmailServerDTO>;
    public updateEmailServerUsingPUT(emailServerDTO: EmailServerDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EmailServerDTO>>;
    public updateEmailServerUsingPUT(emailServerDTO: EmailServerDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EmailServerDTO>>;
    public updateEmailServerUsingPUT(emailServerDTO: EmailServerDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (emailServerDTO === null || emailServerDTO === undefined) {
            throw new Error('Required parameter emailServerDTO was null or undefined when calling updateEmailServerUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<EmailServerDTO>(`${this.basePath}/api/email-servers`,
            emailServerDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
