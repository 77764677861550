<app-glueckwunschtemplate attachment-point-for="content" fxFlex="grow" fxLayout="column" fxLayoutAlign="space-between stretch" layout-id="246901" widget-id="248009 wappnet-glueckwunsch-template_Imported_Imported_Imported">
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="" layout-id="246946" widget-id="248044 navigation div_Imported_Imported_Imported_Imported">
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="33%" fxLayout="row" fxLayoutAlign="start start" layout-id="246905" widget-id="248117 left div_Imported_Imported_Imported_Imported">
   <button class="btn-round fab-pointer-cursor" [class.btn-blue]="false" (click)="callActions('themenfinderlebenssituationstep3', undefined, true, undefined, [], undefined, undefined)" widget-id="248214 back wappnet_fab_button_Imported_Imported_Imported"> 
    <div class="btn-round-img" [style.background-image]="config.icons.arrow_left"></div> </button>
  </div>
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="20%" fxLayout="row" fxLayoutAlign="center start" layout-id="246907" widget-id="248119 middle div_Imported_Imported_Imported_Imported">
   <button class="btn-round temperature-icon fab-pointer-cursor" (click)="callActions('themenfinderrisikotemperatur', undefined, true, undefined, [], undefined, undefined)" widget-id="248216 wappnet_temperature_indicator_Imported_Imported_Imported"> 
    <div class="temperature-icon-peak"> 
     <div class="temperature-icon-peak-background"></div> 
    </div> 
    <div class="btn-round-img" [style.background-image]="model.themenfinder.risikotemperatur == 0 ? config.icons.temperature_low : (model.themenfinder.risikotemperatur == 1 ? config.icons.temperature : config.icons.temperature_high)"></div> </button>
  </div>
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="33%" fxLayout="row" fxLayoutAlign="end start" layout-id="246906" widget-id="248118 right div_Imported_Imported_Imported_Imported">
   <div class="btn-main" (click)="wappnet_nav_button_Imported_Imported_Importedforwardnavigate248952('geldgefuhle', undefined, true, undefined, [], undefined, undefined)" widget-id="248215 forward wappnet_nav_button_Imported_Imported_Imported">
     {{config.themenfinder.glueckwunsch.nav_button_text}} 
   </div>
  </div>
 </div>
</app-glueckwunschtemplate>