<div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="30px" layout-id="246917" widget-id="248001 main div_Imported_Imported_Imported_Imported">
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="33%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="30px" layout-id="246936" widget-id="248026 themenfinder tiles div_Imported_Imported_Imported_Imported">
  <div class="tile-rich brand-shadow brand-radius" [class.tile-rich-visited]="model.appState.themenfinderVisited" (click)="wappnet_tile_rich_Imported_Imported_Imported_Importednavigation_tileonClick248902('themenfinder', undefined, true, undefined, [], undefined, undefined)" fxLayout="row" layout-id="246903" widget-id="248076 navigation_tile wappnet_tile_rich_Imported_Imported_Imported_Imported"> 
   <div class="tile-rich-icon" [style.background-image]="model.appState.themenfinderVisited ? config.landingPage.themenfinderMainPictUrl : config.landingPage.themenfinderMainPictDarkUrl "></div> 
   <div class="tile-rich-right"> 
    <div class="tile-rich-header">
     Thema eins
    </div> 
    <div class="tile-rich-text">
     Themen 
    </div> 
    <div class="tile-rich-text">
     Finder
    </div> 
   </div> 
  </div>
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="460px" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="246972" widget-id="248075 informational tiles 1 div_Imported_Imported_Imported_Imported">
   <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="50%" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="247004" widget-id="248187 wrapper div_Imported_Imported_Imported_Imported">
    <div [class.disabled]="!model.appState.themenfinderVisited" (click)="wappnet_tile_Imported_Imported_Imported_ImportedelefantenbeispielonClick248978('themenfinderelefantengleichnis', undefined, true, undefined, [], undefined, undefined)" fxFlex="48" widget-id="248282 elefantenbeispiel wappnet_tile_Imported_Imported_Imported_Imported"> 
     <div style="height:100%" class="tile-category brand-shadow-inset brand-radius" *ngIf="!model.appState.elefantengleichnisDone"> <span class="tile-category-title">+ elefantenbeispiel</span> 
     </div> 
     <div style="height:100%" class="tile-category brand-shadow brand-radius brand-gradient tile-rich-visited" *ngIf="model.appState.elefantengleichnisDone"> 
      <div class="tile-category-img note" [style.background-image]="config.landingPage.elefantenbeispielPictUrl"></div> <span class="tile-category-title">elefantenbeispiel</span> 
     </div> 
    </div>
    <div [class.disabled]="!model.appState.themenfinderVisited" (click)="wappnet_tile_Imported_Imported_Imported_ImportedlebenssituationonClick248977('themenfinderlebenssituationstep1', undefined, true, undefined, [], undefined, undefined)" fxFlex="48" widget-id="248281 lebenssituation wappnet_tile_Imported_Imported_Imported_Imported"> 
     <div style="height:100%" class="tile-category brand-shadow-inset brand-radius" *ngIf="!model.appState.lebenssituationDone"> <span class="tile-category-title">+ lebenssituation</span> 
     </div> 
     <div style="height:100%" class="tile-category brand-shadow brand-radius brand-gradient tile-rich-visited" *ngIf="model.appState.lebenssituationDone"> 
      <div class="tile-category-img note" [style.background-image]="config.landingPage.lebenssituationPictUrl" [ngClass]="'location'"></div> <span class="tile-category-title">lebenssituation</span> 
     </div> 
    </div>
   </div>
   <div [class.disabled]="!model.appState.themenfinderVisited" (click)="wappnet_tile_Imported_Imported_Imported_ImportedrisikotemperaturonClick248944('themenfinderrisikotemperatur', undefined, true, undefined, [], undefined, undefined)" fxFlex="50%" widget-id="248186 risikotemperatur wappnet_tile_Imported_Imported_Imported_Imported"> 
    <div style="height:100%" class="tile-category brand-shadow-inset brand-radius" *ngIf="!model.appState.risikoDone"> <span class="tile-category-title">+ RISIKOTEMPERATUR</span> 
    </div> 
    <div style="height:100%" class="tile-category brand-shadow brand-radius brand-gradient tile-rich-visited" *ngIf="model.appState.risikoDone"> 
     <div class="tile-category-img note" [style.background-image]="model.themenfinder.risikotemperatur == 0 ? config.landingPage.thermometerLowPictUrl : (model.themenfinder.risikotemperatur == 1 ? config.landingPage.thermometerMiddlePictUrl : config.landingPage.thermometerHighPictUrl)"></div> <span class="tile-category-title">RISIKOTEMPERATUR</span> 
    </div> 
   </div>
  </div>
 </div>
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="33%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="30px" layout-id="246937" widget-id="248027 geldgefuehle tiles div_Imported_Imported_Imported_Imported">
  <div class="tile-rich brand-shadow brand-radius" [class.tile-rich-visited]="model.appState.geldgefuehleVisited" (click)="wappnet_tile_rich_Imported_Imported_Imported_Importednavigation_tileonClick248903('geldgefuhle', undefined, true, undefined, [], undefined, undefined)" widget-id="248077 navigation_tile wappnet_tile_rich_Imported_Imported_Imported_Imported"> 
   <div class="tile-rich-icon" [style.background-image]="model.appState.geldgefuehleVisited ? 
config.landingPage.geldgefuehleMainPictUrl : config.landingPage.geldgefuehleMainPictDarkUrl"></div> 
   <div class="tile-rich-right"> 
    <div class="tile-rich-header">
     Thema zwei
    </div> 
    <div class="tile-rich-text">
     geld
    </div> 
    <div class="tile-rich-text">
     gefühle
    </div> 
   </div> 
  </div>
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="460px" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="" layout-id="246973" widget-id="248078 informational tiles 2 div_Imported_Imported_Imported_Imported">
   <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="100%" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="247005" widget-id="248188 wrapper div_Imported_Imported_Imported_Imported">
    <div [class.disabled]="!model.appState.geldgefuehleVisited" (click)="wappnet_tile_Imported_Imported_Imported_ImportedglaubenssaetzeonClick248980('geldgefuhleglaubenssatze', undefined, true, undefined, [], undefined, undefined)" fxFlex="48" widget-id="248284 glaubenssaetze wappnet_tile_Imported_Imported_Imported_Imported"> 
     <div style="height:100%" class="tile-category brand-shadow-inset brand-radius" *ngIf="!model.appState.glaubenssaetzeDone"> <span class="tile-category-title">+ glaubenssätze</span> 
     </div> 
     <div style="height:100%" class="tile-category brand-shadow brand-radius brand-gradient tile-rich-visited" *ngIf="model.appState.glaubenssaetzeDone"> 
      <div class="tile-category-img note" [style.background-image]="config.landingPage.glaubenssaetzePictUrl" [ngClass]="'bird'"></div> <span class="tile-category-title">glaubenssätze</span> 
     </div> 
    </div>
    <div [class.disabled]="!model.appState.geldgefuehleVisited" (click)="wappnet_tile_Imported_Imported_Imported_ImporteddenkfehleronClick248979('geldgefuhledenkfehler', undefined, true, undefined, [], undefined, undefined)" fxFlex="48" widget-id="248283 denkfehler wappnet_tile_Imported_Imported_Imported_Imported"> 
     <div style="height:100%" class="tile-category brand-shadow-inset brand-radius" *ngIf="!model.appState.denkfehlerDone"> <span class="tile-category-title">+ denkfehler</span> 
     </div> 
     <div style="height:100%" class="tile-category brand-shadow brand-radius brand-gradient tile-rich-visited" *ngIf="model.appState.denkfehlerDone"> 
      <div class="tile-category-img note" [style.background-image]="config.landingPage.denkfehlerPictUrl"></div> <span class="tile-category-title">denkfehler</span> 
     </div> 
    </div>
   </div>
  </div>
 </div>
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="33%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="30px" layout-id="246938" widget-id="248028 beratungsideal tiles div_Imported_Imported_Imported_Imported">
  <div class="tile-rich brand-shadow brand-radius" [class.tile-rich-visited]="model.appState.beratungsidealVisited" (click)="wappnet_tile_rich_Imported_Imported_Imported_Importednavigation_tileonClick248904('beratungsideal', undefined, true, undefined, [], undefined, undefined)" widget-id="248080 navigation_tile wappnet_tile_rich_Imported_Imported_Imported_Imported"> 
   <div class="tile-rich-icon" [style.background-image]="model.appState.beratungsidealVisited ? 
config.landingPage.beratungsidealMainPictUrl : config.landingPage.beratungsidealMainPictDarkUrl"></div> 
   <div class="tile-rich-right"> 
    <div class="tile-rich-header">
     thema drei
    </div> 
    <div class="tile-rich-text">
     beratungs
    </div> 
    <div class="tile-rich-text">
     ideal
    </div> 
   </div> 
  </div>
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="461px" fxLayout="row" fxLayoutAlign="space-between stretch" layout-id="246974" fxLayoutGap="20px" widget-id="248079 informational tiles div_Imported_Imported_Imported_Imported">
   <div [class.disabled]="!model.appState.beratungsidealVisited" (click)="wappnet_tile_Imported_Imported_Imported_ImportedberatungswunschzettelonClick248945('beratungsidealberatungswunschzettel', undefined, true, undefined, [], undefined, undefined)" fxFlex="50%" widget-id="248189 beratungswunschzettel wappnet_tile_Imported_Imported_Imported_Imported"> 
    <div style="height:100%" class="tile-category brand-shadow-inset brand-radius" *ngIf="!model.appState.beratungsWunschzettelDone"> <span class="tile-category-title">+ beratungs-wunschzettel</span> 
    </div> 
    <div style="height:100%" class="tile-category brand-shadow brand-radius brand-gradient tile-rich-visited" *ngIf="model.appState.beratungsWunschzettelDone"> 
     <div class="tile-category-img note" [style.background-image]="config.landingPage.beratungsWunschzettelPictUrl"></div> <span class="tile-category-title">beratungs-wunschzettel</span> 
    </div> 
   </div>
   <div [class.disabled]="!model.appState.beratungsidealVisited" (click)="wappnet_tile_Imported_Imported_Imported_ImporteddialogwunschonClick248946('beratungsidealformular', undefined, true, undefined, [], undefined, undefined)" fxFlex="50%" widget-id="248190 dialogwunsch wappnet_tile_Imported_Imported_Imported_Imported"> 
    <div style="height:100%" class="tile-category brand-shadow-inset brand-radius" *ngIf="!model.appState.dialogWunschDone"> <span class="tile-category-title">+ DIALOG-WUNSCH</span> 
    </div> 
    <div style="height:100%" class="tile-category brand-shadow brand-radius brand-gradient tile-rich-visited" *ngIf="model.appState.dialogWunschDone"> 
     <div class="tile-category-img note" [style.background-image]="config.landingPage.dialogWunschPictUrl"></div> <span class="tile-category-title">DIALOG-WUNSCH</span> 
    </div> 
   </div>
  </div>
 </div>
</div>