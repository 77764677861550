<div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="30px" layout-id="246921" widget-id="248005 root div_Imported_Imported_Imported_Imported">
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="grow" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="246944" widget-id="248036 content div_Imported_Imported_Imported_Imported">
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="35%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="40px" layout-id="246979" widget-id="248097 letf column div_Imported_Imported_Imported_Imported">
   <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="grow" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px" layout-id="247010" widget-id="248208 texts divs div_Imported_Imported_Imported_Imported">
    <div class="step-header brand-radius brand-shadow brand-gradient" [class.brand-gradient]="true" (click)="callActions(undefined, undefined, true, undefined, [], undefined, undefined)" widget-id="248314 header wappnet_step_header_Imported_Imported_Imported"> 
     <div class="step-title">
      {{config.geldgefuhle.glaubenssatze.step_header}}
     </div> 
     <div class="step-number">
      {{config.geldgefuhle.glaubenssatze.step_counter}}
     </div> 
    </div>
    <div attachment-point-for="content" [ngClass]="'brand-offset-small-inner brand-radius brand-shadow-inset'" *ngIf="true" widget-id="248315 text div shadowed div_Imported_Imported_Imported_Imported">
     <article widget-id="248417 header + paragraph1 wappnet_text-with-header_Imported_Imported_Imported"> 
      <header>
       {{config.geldgefuhle.glaubenssatze.header}}
      </header> 
      <p>{{config.geldgefuhle.glaubenssatze.paragraph1}}</p> 
     </article>
     <p widget-id="248416 paragraph2 wappnet_paragraph_Imported_Imported_Imported">{{config.geldgefuhle.glaubenssatze.paragraph2}}</p>
     <p widget-id="248415 paragraph3 wappnet_paragraph_Imported_Imported_Imported">{{config.geldgefuhle.glaubenssatze.paragraph3}}</p>
    </div>
   </div>
   <div attachment-point-for="content" [ngClass]="'brand-offset-small-inner brand-radius brand-shadow-inset'" *ngIf="true" fxFlex="grow" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px" layout-id="247011" widget-id="248209 chosen buttons container div_Imported_Imported_Imported_Imported">
    <div class="clickable-panel brand-radius-small" [class.is-active]="true" *ngIf="model.geldgefuhle.glaubenssatze.option2" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option2', false, undefined, [], undefined, undefined)" widget-id="248316 option2 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option2}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="true" *ngIf="model.geldgefuhle.glaubenssatze.option3" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option3', false, undefined, [], undefined, undefined)" widget-id="248317 option3 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option3}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="true" *ngIf="model.geldgefuhle.glaubenssatze.option4" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option4', false, undefined, [], undefined, undefined)" widget-id="248318 option4 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option4}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="true" *ngIf="model.geldgefuhle.glaubenssatze.option5" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option5', false, undefined, [], undefined, undefined)" widget-id="248319 option5 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option5}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="true" *ngIf="model.geldgefuhle.glaubenssatze.option6" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option6', false, undefined, [], undefined, undefined)" widget-id="248335 option6 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option6}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="true" *ngIf="model.geldgefuhle.glaubenssatze.option7" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option7', false, undefined, [], undefined, undefined)" widget-id="248330 option7 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option7}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="true" *ngIf="model.geldgefuhle.glaubenssatze.option8" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option8', false, undefined, [], undefined, undefined)" widget-id="248320 option8 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option8}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="true" *ngIf="model.geldgefuhle.glaubenssatze.option9" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option9', false, undefined, [], undefined, undefined)" widget-id="248321 option9 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option9}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="true" *ngIf="model.geldgefuhle.glaubenssatze.option10" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option10', false, undefined, [], undefined, undefined)" widget-id="248322 option10 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option10}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="true" *ngIf="model.geldgefuhle.glaubenssatze.option11" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option11', false, undefined, [], undefined, undefined)" widget-id="248323 option11 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option11}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="true" *ngIf="model.geldgefuhle.glaubenssatze.option12" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option12', false, undefined, [], undefined, undefined)" widget-id="248337 option12 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      config.geldgefuhle.glaubenssatze.option12
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="true" *ngIf="model.geldgefuhle.glaubenssatze.option13" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option13', false, undefined, [], undefined, undefined)" widget-id="248324 option13 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option13}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="true" *ngIf="model.geldgefuhle.glaubenssatze.option14" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option14', false, undefined, [], undefined, undefined)" widget-id="248325 option14 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option14}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="true" *ngIf="model.geldgefuhle.glaubenssatze.option15" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option15', false, undefined, [], undefined, undefined)" widget-id="248326 option15 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option15}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="true" *ngIf="model.geldgefuhle.glaubenssatze.option1" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option1', false, undefined, [], undefined, undefined)" widget-id="248340 option1 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option1}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="true" *ngIf="model.geldgefuhle.glaubenssatze.option16" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option16', false, undefined, [], undefined, undefined)" widget-id="248327 option16 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option16}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="true" *ngIf="model.geldgefuhle.glaubenssatze.option17" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option17', false, undefined, [], undefined, undefined)" widget-id="248338 option17 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option17}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="true" *ngIf="model.geldgefuhle.glaubenssatze.option18" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option18', false, undefined, [], undefined, undefined)" widget-id="248328 option18 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option18}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="true" *ngIf="model.geldgefuhle.glaubenssatze.option19" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option19', false, undefined, [], undefined, undefined)" widget-id="248329 option19 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option19}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="true" *ngIf="model.geldgefuhle.glaubenssatze.option20" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option20', false, undefined, [], undefined, undefined)" widget-id="248331 option20 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option20}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="true" *ngIf="model.geldgefuhle.glaubenssatze.option21" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option21', false, undefined, [], undefined, undefined)" widget-id="248332 option21 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option21}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="true" *ngIf="model.geldgefuhle.glaubenssatze.option22" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option22', false, undefined, [], undefined, undefined)" widget-id="248333 option22 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option22}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="true" *ngIf="model.geldgefuhle.glaubenssatze.option23" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option23', false, undefined, [], undefined, undefined)" widget-id="248334 option23 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option23}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="true" *ngIf="model.geldgefuhle.glaubenssatze.option24" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option24', false, undefined, [], undefined, undefined)" widget-id="248336 option24 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option24}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="true" *ngIf="model.geldgefuhle.glaubenssatze.option25" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option25', false, undefined, [], undefined, undefined)" widget-id="248339 option25 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option25}}
     </div> 
    </div>
   </div>
  </div>
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="65%" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="246978" widget-id="248096 interaction buttons div_Imported_Imported_Imported_Imported">
   <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="50%" layout-id="247009" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px" widget-id="248207 column 1 div_Imported_Imported_Imported_Imported">
    <div class="clickable-panel brand-radius-small" [class.is-active]="model.geldgefuhle.glaubenssatze.option1" *ngIf="true" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option1', false, undefined, [], undefined, undefined)" widget-id="248303 panel1 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option1}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="model.geldgefuhle.glaubenssatze.option2" *ngIf="true" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option2', false, undefined, [], undefined, undefined)" widget-id="248302 panel2 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option2}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="model.geldgefuhle.glaubenssatze.option3" *ngIf="true" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option3', false, undefined, [], undefined, undefined)" widget-id="248304 panel3 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option3}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="model.geldgefuhle.glaubenssatze.option4" *ngIf="true" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option4', false, undefined, [], undefined, undefined)" widget-id="248305 panel4 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option4}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="model.geldgefuhle.glaubenssatze.option5" *ngIf="true" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option5', false, undefined, [], undefined, undefined)" widget-id="248306 panel5 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option5}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="model.geldgefuhle.glaubenssatze.option6" *ngIf="true" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option6', false, undefined, [], undefined, undefined)" widget-id="248307 panel6 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option6}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="model.geldgefuhle.glaubenssatze.option7" *ngIf="true" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option7', false, undefined, [], undefined, undefined)" widget-id="248308 panel7 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option7}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="model.geldgefuhle.glaubenssatze.option8" *ngIf="true" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option8', false, undefined, [], undefined, undefined)" widget-id="248309 panel8 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option8}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="model.geldgefuhle.glaubenssatze.option10" *ngIf="true" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option10', false, undefined, [], undefined, undefined)" widget-id="248310 panel10 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option10}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="model.geldgefuhle.glaubenssatze.option11" *ngIf="true" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option11', false, undefined, [], undefined, undefined)" widget-id="248311 panel11 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option11}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="model.geldgefuhle.glaubenssatze.option12" *ngIf="true" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option12', false, undefined, [], undefined, undefined)" widget-id="248312 panel12 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option12}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="model.geldgefuhle.glaubenssatze.option13" *ngIf="true" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option13', false, undefined, [], undefined, undefined)" widget-id="248313 panel13 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option13}}
     </div> 
    </div>
   </div>
   <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="50%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px" layout-id="247008" widget-id="248206 column 2 div_Imported_Imported_Imported_Imported">
    <div class="clickable-panel brand-radius-small" [class.is-active]="model.geldgefuhle.glaubenssatze.option14" *ngIf="true" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option14', false, undefined, [], undefined, undefined)" widget-id="248290 panel14 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option14}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="model.geldgefuhle.glaubenssatze.option15" *ngIf="true" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option15', false, undefined, [], undefined, undefined)" widget-id="248291 panel15 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option15}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="model.geldgefuhle.glaubenssatze.option16" *ngIf="true" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option16', false, undefined, [], undefined, undefined)" widget-id="248292 panel16 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option16}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="model.geldgefuhle.glaubenssatze.option17" *ngIf="true" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option17', false, undefined, [], undefined, undefined)" widget-id="248293 panel17 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option17}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="model.geldgefuhle.glaubenssatze.option18" *ngIf="true" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option18', false, undefined, [], undefined, undefined)" widget-id="248294 panel18 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option18}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="model.geldgefuhle.glaubenssatze.option19" *ngIf="true" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option19', false, undefined, [], undefined, undefined)" widget-id="248295 panel19 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option19}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="model.geldgefuhle.glaubenssatze.option20" *ngIf="true" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option20', false, undefined, [], undefined, undefined)" widget-id="248296 panel20 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option20}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="model.geldgefuhle.glaubenssatze.option21" *ngIf="true" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option21', false, undefined, [], undefined, undefined)" widget-id="248297 panel21 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option21}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="model.geldgefuhle.glaubenssatze.option22" *ngIf="true" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option22', false, undefined, [], undefined, undefined)" widget-id="248298 panel22 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option22}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="model.geldgefuhle.glaubenssatze.option23" *ngIf="true" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option23', false, undefined, [], undefined, undefined)" widget-id="248299 panel23 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option23}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="model.geldgefuhle.glaubenssatze.option24" *ngIf="true" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option24', false, undefined, [], undefined, undefined)" widget-id="248300 panel24 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option24}}
     </div> 
    </div>
    <div class="clickable-panel brand-radius-small" [class.is-active]="model.geldgefuhle.glaubenssatze.option25" *ngIf="true" (click)="callActions(undefined, 'model.geldgefuhle.glaubenssatze.option25', false, undefined, [], undefined, undefined)" widget-id="248301 panel25 wappnet_clickable_vanishing_panel_Imported_Imported_Imported"> 
     <div class="clickable-panel-title">
      {{config.geldgefuhle.glaubenssatze.option25}}
     </div> 
    </div>
   </div>
  </div>
 </div>
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-between center" layout-id="246943" widget-id="248035 nav row div_Imported_Imported_Imported_Imported">
  <button class="btn-round fab-pointer-cursor" [class.btn-blue]="false" (click)="callActions('geldgefuhledenkfehler', undefined, true, undefined, [], undefined, undefined)" widget-id="248093 button back wappnet_fab_button_Imported_Imported_Imported"> 
   <div class="btn-round-img" [style.background-image]="config.icons.arrow_left"></div> </button><button class="btn-round temperature-icon fab-pointer-cursor" (click)="callActions('themenfindersteps', undefined, true, undefined, [], undefined, undefined)" widget-id="248095 wappnet_temperature_indicator_Imported_Imported_Imported"> 
   <div class="temperature-icon-peak"> 
    <div class="temperature-icon-peak-background"></div> 
   </div> 
   <div class="btn-round-img" [style.background-image]="model.themenfinder.risikotemperatur == 0 ? config.icons.temperature_low : (model.themenfinder.risikotemperatur == 1 ? config.icons.temperature : config.icons.temperature_high)"></div> </button><button class="btn-round fab-pointer-cursor" [class.btn-blue]="false" (click)="callActions('geldgefuehleglueckwunsch', undefined, true, undefined, [], undefined, undefined)" widget-id="248094 button next wappnet_fab_button_Imported_Imported_Imported"> 
   <div class="btn-round-img" [style.background-image]="config.icons.arrow_right"></div> </button>
 </div>
</div>