<div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="246920" widget-id="248004 root div_Imported_Imported_Imported_Imported">
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="246942" widget-id="248034 content div_Imported_Imported_Imported_Imported">
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="35%" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="246977" widget-id="248092 left_panel div_Imported_Imported_Imported_Imported">
   <div class="step-header brand-radius brand-shadow brand-gradient" [class.brand-gradient]="true" (click)="callActions(undefined, undefined, true, undefined, [], undefined, undefined)" widget-id="248205 step header wappnet_step_header_Imported_Imported_Imported"> 
    <div class="step-title">
     {{config.themenfinder.steps.header2}}
    </div> 
    <div class="step-number">
     {{config.themenfinder.elefantengleichnis.step_counter}}
    </div> 
   </div>
   <div attachment-point-for="content" [ngClass]="'brand-offset-small-inner brand-radius brand-shadow-inset'" *ngIf="true" fxFlex="grow" widget-id="248204 text panel div_Imported_Imported_Imported_Imported">
    <article widget-id="248288 header and first paragraph wappnet_text-with-header_Imported_Imported_Imported"> 
     <header>
      {{config.themenfinder.elefantengleichnis.paragraph_header}}
     </header> 
     <p>{{config.themenfinder.elefantengleichnis.paragraph1}}</p> 
    </article>
    <p widget-id="248289 second paragraph wappnet_paragraph_Imported_Imported_Imported">{{config.themenfinder.elefantengleichnis.paragraph2}}</p>
   </div>
  </div>
  <div attachment-point-for="content" [ngClass]="'brand-offset-inner brand-radius brand-shadow text-column-two'" *ngIf="true" fxFlex="65%" widget-id="248091 right_panel div_Imported_Imported_Imported_Imported">
   <div attachment-point-for="content" [ngClass]="'text-column-elephant'" [style.background-image]="config.themenfinder.elefantengleichnis.imageUrl" *ngIf="true" widget-id="248196 elephant background div_Imported_Imported_Imported_Imported"></div>
   <p widget-id="248202 wappnet_paragraph_Imported_Imported_Imported">{{config.themenfinder.elefantengleichnis.paragraph4}}</p>
   <blockquote widget-id="248197 wappnet_quote_Imported_Imported_Imported">
    {{config.themenfinder.elefantengleichnis.quote1}}
   </blockquote>
   <p widget-id="248198 wappnet_paragraph_Imported_Imported_Imported">{{config.themenfinder.elefantengleichnis.paragraph5}}</p>
   <p widget-id="248199 wappnet_paragraph_Imported_Imported_Imported">{{config.themenfinder.elefantengleichnis.paragraph6}}</p>
   <blockquote widget-id="248200 wappnet_quote_Imported_Imported_Imported">
    {{config.themenfinder.elefantengleichnis.quote2}}
   </blockquote>
   <p widget-id="248203 wappnet_paragraph_Imported_Imported_Imported">{{config.themenfinder.elefantengleichnis.paragraph7}}</p>
   <p widget-id="248201 wappnet_paragraph_Imported_Imported_Imported">{{config.themenfinder.elefantengleichnis.paragraph8}}</p>
  </div>
 </div>
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="grow" fxLayout="row" fxLayoutAlign="space-between end" layout-id="246902" widget-id="248033 navigation div_Imported_Imported_Imported_Imported">
  <button class="btn-round fab-pointer-cursor" [class.btn-blue]="false" (click)="wappnet_fab_button_Imported_Imported_Importedbacknavigate248910('themenfinderrisikotemperatur', undefined, true, undefined, [], undefined, undefined)" widget-id="248088 back wappnet_fab_button_Imported_Imported_Imported"> 
   <div class="btn-round-img" [style.background-image]="config.icons.arrow_left"></div> </button><button class="btn-round temperature-icon fab-pointer-cursor" (click)="callActions('themenfinderrisikotemperatur', undefined, true, undefined, [], undefined, undefined)" widget-id="248090 wappnet_temperature_indicator_Imported_Imported_Imported"> 
   <div class="temperature-icon-peak"> 
    <div class="temperature-icon-peak-background"></div> 
   </div> 
   <div class="btn-round-img" [style.background-image]="model.themenfinder.risikotemperatur == 0 ? config.icons.temperature_low : (model.themenfinder.risikotemperatur == 1 ? config.icons.temperature : config.icons.temperature_high)"></div> </button><button class="btn-round fab-pointer-cursor" [class.btn-blue]="false" (click)="wappnet_fab_button_Imported_Imported_Importedforwardnavigate248911('themenfinderlebenssituationstep1', undefined, true, undefined, [], undefined, undefined)" widget-id="248089 forward wappnet_fab_button_Imported_Imported_Imported"> 
   <div class="btn-round-img" [style.background-image]="config.icons.arrow_right"></div> </button>
 </div>
</div>