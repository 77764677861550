<div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" widget-id="248008 div_Imported_Imported_Imported_Imported">
 <app-lebenssituationdetailspanel attachment-point-for="content" [model]="model.themenfinder.lebenssituation1" showHiddenOnly="false" widget-id="248042 lebenssituation1 wappnet-lebenssituation-details-panel_Imported_Imported_Imported">
  <div widget-id="248115 test Text_Imported_Imported_Imported_Imported">
   {{model.secured1}}
  </div>
 </app-lebenssituationdetailspanel><app-lebenssituationdetailspanel attachment-point-for="content" [model]="model.themenfinder.lebenssituation2" showHiddenOnly="false" widget-id="248043 lebenssituation2 wappnet-lebenssituation-details-panel_Imported_Imported_Imported">
  <div widget-id="248116 Text_Imported_Imported_Imported_Imported">
   {{model.themenfinder.lebenssituation2.secured1}}
  </div>
 </app-lebenssituationdetailspanel>
</div>