<div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="grow" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="30px" layout-id="246924" widget-id="248011 root div_Imported_Imported_Imported_Imported">
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="246949" widget-id="248048 content row div_Imported_Imported_Imported_Imported">
  <div attachment-point-for="content" [ngClass]="''" *ngIf="true" fxFlex="33%" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="246982" widget-id="248125 denkfehler div_Imported_Imported_Imported_Imported">
   <div class="step-header brand-radius brand-shadow brand-gradient" [class.brand-gradient]="model.appState.denkfehlerDone" (click)="wappnet_step_header_Imported_Imported_Importedstep_1click248955('geldgefuhledenkfehler', undefined, true, undefined, [], undefined, undefined)" widget-id="248221 step 1 wappnet_step_header_Imported_Imported_Imported"> 
    <div class="step-title">
     {{config.geldgefuhle.steps.step1_title}}
    </div> 
    <div class="step-number">
     {{config.geldgefuhle.steps.step1_counter}}
    </div> 
   </div>
   <div attachment-point-for="content" [ngClass]="'brand-offset-small-inner brand-radius brand-shadow-inset'" *ngIf="true" fxFlex="grow" widget-id="248220 text 1 div_Imported_Imported_Imported_Imported">
    <article widget-id="248350 wappnet_text-with-header_Imported_Imported_Imported"> 
     <header>
      {{config.geldgefuhle.steps.step1_paragraph_header}}
     </header> 
     <p>{{config.geldgefuhle.steps.step1_paragraph1}}</p> 
    </article>
    <p widget-id="248351 wappnet_paragraph_Imported_Imported_Imported">{{config.geldgefuhle.steps.step1_paragraph2}}</p>
   </div>
  </div>
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="33%" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="246983" widget-id="248127 glaubenssaetze div_Imported_Imported_Imported_Imported">
   <div class="step-header brand-radius brand-shadow brand-gradient" [class.brand-gradient]="model.appState.glaubenssaetzeDone" (click)="wappnet_step_header_Imported_Imported_Importedheader_step_2click248956('geldgefuhleglaubenssatze', undefined, true, undefined, [], undefined, undefined)" widget-id="248223 header step 2 wappnet_step_header_Imported_Imported_Imported"> 
    <div class="step-title">
     {{config.geldgefuhle.steps.step2_title}}
    </div> 
    <div class="step-number">
     {{config.geldgefuhle.steps.step2_counter}}
    </div> 
   </div>
   <div attachment-point-for="content" [ngClass]="'brand-offset-small-inner brand-radius brand-shadow-inset'" *ngIf="true" fxFlex="grow" widget-id="248222 text step 2 div_Imported_Imported_Imported_Imported">
    <article widget-id="248352 wappnet_text-with-header_Imported_Imported_Imported"> 
     <header>
      {{config.geldgefuhle.steps.step2_paragraph_header}}
     </header> 
     <p>{{config.geldgefuhle.steps.step2_paragraph1}}</p> 
    </article>
    <p widget-id="248353 wappnet_paragraph_Imported_Imported_Imported">{{config.geldgefuhle.steps.step2_paragraph2}}</p>
   </div>
  </div>
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="33%" widget-id="248126 placeholder div_Imported_Imported_Imported_Imported"></div>
 </div><ng-template cdkConnectedOverlay [cdkConnectedOverlayOpen]="isOpen248047" [cdkConnectedOverlayHasBackdrop]="true" (backdropClick)="isOpen248047 = false" [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'" [cdkConnectedOverlayPositions]="panelPositions" widget-id="248047 button row div_Imported_Imported_Imported_Imported"> 
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="246948"> 
   <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="33%" widget-id="248123 column 1 div_Imported_Imported_Imported_Imported"></div> 
   <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="33%" widget-id="248122 column 2 div_Imported_Imported_Imported_Imported"></div> 
   <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="33%" widget-id="248124 column 3 div_Imported_Imported_Imported_Imported"> 
    <div class="btn-main" (click)="wappnet_nav_button_Imported_Imported_Importedbuttonnavigate248954('geldgefuhledenkfehler', undefined, true, undefined, [], undefined, undefined)" fxFlex="100%" widget-id="248219 button wappnet_nav_button_Imported_Imported_Imported">
      MIT SCHRITT 1/2 STARTEN 
    </div> 
   </div> 
  </div> 
 </ng-template>
</div>