import { Component, Inject, OnInit, Optional } from '@angular/core';
import { DIALOG_DATA } from 'src/app/utils/dialog/dialog.service';

@Component({
  selector: 'app-blocker-popup',
  templateUrl: './blocker-popup.component.html',
  styleUrls: ['./blocker-popup.component.scss']
})
export class BlockerPopupComponent implements OnInit {

  title: string = "Warnung"
  message: string = "Etwas ist schief gelaufen";

  constructor(@Optional() @Inject(DIALOG_DATA) public data?: any) {
    const map = this.getMappedMessages();
    this.message = map.get(data.message)
  }

  ngOnInit(): void {
  }

  getMappedMessages(): Map<string, string> {
    let map = new Map();
    map.set("unknown_error", "Unbekanntes Problem");
    map.set("dataset_not_found", "Datensatz nicht gefunden");
    map.set("user_not_known", "Benutzer unbekannt");
    map.set("access_not_allowed", "Zugang nicht erlaubt");
    map.set("campaign_not_valid", "Kampagne nicht gültig");
    map.set("consent_not_granted", "Zustimmung nicht erteilt");
    map.set("use-link-warning", "Benutzen Sie bitte den Link aus der eMail.");
    return map;
  }
}
