<div attachment-point-for="content" [ngClass]="'main-div'" *ngIf="config" fxLayout="row" fxLayoutAlign="space-around stretch" layout-id="246935" widget-id="248025 mainLayout div_Imported_Imported_Imported_Imported">
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="7%" widget-id="248071 leftPlaceholder div_Imported_Imported_Imported_Imported"></div>
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="86%" fxLayout="column" fxLayoutAlign="start stretch" layout-id="246971" widget-id="248073 contentWithWarning div_Imported_Imported_Imported_Imported">
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="column" fxLayoutAlign="start center" layout-id="247003" widget-id="248183 warning div_Imported_Imported_Imported_Imported">
   <div widget-id="248278 Text_Imported_Imported_Imported_Imported">
    {{isProd ? '' : 'Entwicklungsversion'}}
   </div>
  </div>
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="30px" layout-id="247002" widget-id="248181 menu content div_Imported_Imported_Imported_Imported">
   <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="10px" layout-id="247019" widget-id="248277 menuList div_Imported_Imported_Imported_Imported">
    <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="20%" widget-id="248409 logo div_Imported_Imported_Imported_Imported">
     <div class="logo" [style.background-image]="config.images.logo_url" (click)="callActions('start-page', undefined, true, undefined, [], undefined, undefined)" widget-id="248457 wappnet_logo_Imported_Imported_Imported_Imported"></div>
    </div>
    <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="config" fxFlex="60%" fxLayout="row" fxLayoutAlign="space-around center" layout-id="247024" widget-id="248411 menu positions tbe div_Imported_Imported_Imported_Imported">
     <div class="header-link" (click)="callActions('start-page', undefined, true, undefined, [], undefined, undefined)" widget-id="248459 Dashboard wappnet_menu_Imported_Imported_Imported_Imported">
      {{config.menu.title1}}
     </div>
     <div class="header-link" (click)="wappnet_menu_Imported_Imported_Imported_ImportedThemenfindershowPage249075('themenfinder', undefined, true, undefined, [], undefined, undefined)" widget-id="248461 Themenfinder wappnet_menu_Imported_Imported_Imported_Imported">
      {{config.menu.title2}}
     </div>
     <div class="header-link" (click)="wappnet_menu_Imported_Imported_Imported_ImportedGeldgef_hleshowPage249076('geldgefuhle', undefined, true, undefined, [], undefined, undefined)" widget-id="248462 Geldgefühle wappnet_menu_Imported_Imported_Imported_Imported">
      {{config.menu.title3}}
     </div>
     <div class="header-link" (click)="wappnet_menu_Imported_Imported_Imported_ImportedBeratungsidealshowPage249077('beratungsideal', undefined, true, undefined, [], undefined, undefined)" widget-id="248463 Beratungsideal wappnet_menu_Imported_Imported_Imported_Imported">
      {{config.menu.title4}}
     </div>
     <div class="header-link" (click)="callActions('kontakt', undefined, true, undefined, [], undefined, undefined)" widget-id="248460 Kontakt wappnet_menu_Imported_Imported_Imported_Imported">
      {{config.menu.title5}}
     </div>
    </div>
    <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="20%" fxLayout="row" fxLayoutAlign="end center" layout-id="247023" widget-id="248410 profileButton div_Imported_Imported_Imported_Imported">
     <div class="header-profile" widget-id="248458 profile wappnet_header_profile_Imported_Imported_Imported"> 
      <div class="header-profile-trigger" #origin248074="cdkOverlayOrigin" cdkOverlayOrigin="" (click)="callActions(undefined, undefined, false, 248074, [{&quot;id&quot;:250102,&quot;originX&quot;:'end',&quot;originY&quot;:'top',&quot;overlayX&quot;:'end',&quot;overlayY&quot;:'top',&quot;offsetX&quot;:26,&quot;offsetY&quot;:-18,&quot;uiWidgetActionInstanceId&quot;:249072}], undefined, undefined)"> 
       <div class="header-profile-btn">
        {{model.profile.username}}
       </div> 
       <div class="header-profile-logo"> 
        <div class="header-profile-img" [style.background-image]="model.profile.picture" [style.background-size]="model.profile.headerPictureSize" [style.width]="model.profile.headerPictureSize" [style.height]="model.profile.headerPictureSize"></div> 
       </div> 
      </div> 
      <div class="header-profile-menu context-menu"> 
       <div class="header-profile-link" (click)="callActions('loginpage', undefined, false, undefined, [], undefined, undefined)"> <span>+</span> Profil hinzufügen 
       </div> 
       <div class="header-profile-options"> 
        <div class="header-profile-option">
         Hilfe
        </div> 
        <div class="header-profile-option">
         Abmelden
        </div> 
       </div> 
      </div> 
     </div>
    </div>
   </div>
  </div>
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="grow" fxLayout="column" layout-id="246913" widget-id="248180 page content div_Imported_Imported_Imported_Imported">
   <router-outlet widget-id="248276 rooting_outlet_Imported_Imported_Imported_Imported"></router-outlet>
  </div>
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="8px" widget-id="248182 space 8px div_Imported_Imported_Imported_Imported"></div>
 </div>
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="7%" widget-id="248072 rightPlaceholder div_Imported_Imported_Imported_Imported"></div><ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="origin248074" [cdkConnectedOverlayOpen]="isOpen248074" [cdkConnectedOverlayHasBackdrop]="true" (backdropClick)="isOpen248074 = false" [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'" [cdkConnectedOverlayPositions]="panelPositions" widget-id="248074 profiles panel div_Imported_Imported_Imported_Imported"> 
  <div attachment-point-for="content" [ngClass]="'dropdown'" *ngIf="true"> 
   <div class="header-profile" fxLayout="row" fxLayoutAlign="end" layout-id="246914" widget-id="248184 profile wappnet_header_profile_Imported_Imported_Imported"> 
    <div class="header-profile-trigger" #origin248074="cdkOverlayOrigin" cdkOverlayOrigin="" (click)="callActions(undefined, undefined, false, 248074, [], undefined, undefined)"> 
     <div class="header-profile-btn">
       {{model.profile.username}} 
     </div> 
     <div class="header-profile-logo"> 
      <div class="header-profile-img" [style.background-image]="model.profile.picture" [style.background-size]="model.profile.headerPictureSize" [style.width]="model.profile.headerPictureSize" [style.height]="model.profile.headerPictureSize"></div> 
     </div> 
    </div> 
    <div class="header-profile-menu context-menu"> 
     <div class="header-profile-link" (click)="callActions('loginpage', undefined, false, undefined, [], undefined, undefined)"> <span>+</span> Profil hinzufügen 
     </div> 
     <div class="header-profile-options"> 
      <div class="header-profile-option">
        Hilfe 
      </div> 
      <div class="header-profile-option">
        Abmelden 
      </div> 
     </div> 
    </div> 
   </div> 
   <div attachment-point-for="content" [ngClass]="'divider-box'" *ngIf="true" widget-id="248185 box with divider div_Imported_Imported_Imported_Imported"> 
    <div class="header-profile-link" #origin248074="cdkOverlayOrigin" cdkOverlayOrigin="" (click)="callActions('loginpage', undefined, false, 248074, [], undefined, undefined)" widget-id="248279 add wappnet_header_profile_add_Imported_Imported_Imported"> <span>+</span> Profil hinzufügen 
    </div> 
    <div attachment-point-for="content" [ngClass]="'header-profile-options'" *ngIf="true" widget-id="248280 profile options div_Imported_Imported_Imported_Imported"> 
     <div class="header-profile-option" widget-id="248412 help wappnet_header_profile_option_Imported_Imported_Imported">
       Hilfe 
     </div> 
     <div class="header-profile-option" widget-id="248413 log out wappnet_header_profile_option_Imported_Imported_Imported">
       Abmelden 
     </div> 
    </div> 
   </div> 
  </div> 
 </ng-template>
</div>