<div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="grow" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="50px" layout-id="246919" widget-id="248003 layout row div_Imported_Imported_Imported_Imported">
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-around stretch" fxLayoutGap="20px" layout-id="246940" widget-id="248031 content_row div_Imported_Imported_Imported_Imported">
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="35%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px" layout-id="246976" widget-id="248084 left column div_Imported_Imported_Imported_Imported">
   <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px" layout-id="247007" widget-id="248195 description div_Imported_Imported_Imported_Imported">
    <div class="step-header brand-radius brand-shadow brand-gradient" [class.brand-gradient]="true" (click)="callActions(undefined, undefined, true, undefined, [], undefined, undefined)" widget-id="248287 header wappnet_step_header_Imported_Imported_Imported"> 
     <div class="step-title">
      {{config.themenfinder.lebenssituation.step_header}}
     </div> 
     <div class="step-number">
      {{config.themenfinder.lebenssituation.step_counter}}
     </div> 
    </div>
    <div attachment-point-for="content" [ngClass]="'brand-offset-small-inner brand-radius brand-shadow-inset'" *ngIf="true" fxFlex="grow" widget-id="248286 text div_Imported_Imported_Imported_Imported">
     <article widget-id="248414 wappnet_text-with-header_Imported_Imported_Imported"> 
      <header>
       {{config.themenfinder.lebenssituation.paragraph_header3}}
      </header> 
      <p>{{config.themenfinder.lebenssituation.paragraph_content3}}</p> 
     </article>
    </div>
   </div>
   <div attachment-point-for="content" [ngClass]="'brand-offset-small-inner brand-radius brand-shadow-inset'" *ngIf="true" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px" layout-id="247006" widget-id="248194 result container div_Imported_Imported_Imported_Imported">
    <ng-template let-i="index" let-count="count" let-isFirst="first" let-isLast="last" let-isEven="even" let-isOdd="odd" let-item [ngForOf]="sortedList" ngFor widget-id="248285 wappnet_result_item_Imported_Imported">
     <p class="highlighted-text brand-radius-small brand-shadow" *ngIf="item.data.active === true">{{item.data.value + ' ' + item.config.label}}</p>
    </ng-template>
   </div>
  </div>
  <div attachment-point-for="content" [ngClass]="'brand-offset-inner brand-radius brand-shadow'" *ngIf="true" fxFlex="65%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="45px" layout-id="246975" widget-id="248083 right column div_Imported_Imported_Imported_Imported">
   <ng-template let-i="index" let-count="count" let-isFirst="first" let-isLast="last" let-isEven="even" let-isOdd="odd" let-item [ngForOf]="list" ngFor widget-id="248193 wappnet_slider_horizontal_Imported_Imported_Imported">
    <div> 
     <p>{{item.config.label}}</p> 
     <div class="horizontal-slider-removable" [class.is-dimmed]="item.data.active === false"> <button class="btn-remove" (click)="wappnet_slider_horizontal_Imported_Imported_Importedclick248947(undefined, 'item.data.active', false, undefined, [], item, undefined)"> 
       <div class="btn-icon-wrapper"> 
        <div class="btn-icon" [style.background-image]="config.icons.close"></div> 
       </div> </button> 
      <div class="horizontal-slider-wrapper"> 
       <div class="horizontal-slider-with-tooltip"> 
        <div class="horizontal-slider horizontal-slider-with-value"> <span class="horizontal-slider-value">{{item.data.value}}</span> 
         <input type="range" [min]="item.minYear" [max]="config.lebenssituation.sliderLabelMax" [(ngModel)]="item.data.value" (click)="wappnet_slider_horizontal_Imported_Imported_ImportedsliderClick248948(undefined, undefined, true, undefined, [], item, undefined)"> 
        </div> 
       </div> 
      </div> 
     </div> 
    </div>
   </ng-template>
  </div>
 </div>
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="10px" layout-id="246941" widget-id="248032 buttons row  div_Imported_Imported_Imported_Imported">
  <button class="btn-round fab-pointer-cursor" [class.btn-blue]="false" (click)="callActions('themenfinderlebenssituationstep2', undefined, true, undefined, [], undefined, undefined)" widget-id="248085 prev_page wappnet_fab_button_Imported_Imported_Imported"> 
   <div class="btn-round-img" [style.background-image]="config.icons.arrow_left"></div> </button><button class="btn-round temperature-icon fab-pointer-cursor" (click)="callActions('themenfinderrisikotemperatur', undefined, true, undefined, [], undefined, undefined)" widget-id="248087 wappnet_temperature_indicator_Imported_Imported_Imported"> 
   <div class="temperature-icon-peak"> 
    <div class="temperature-icon-peak-background"></div> 
   </div> 
   <div class="btn-round-img" [style.background-image]="model.themenfinder.risikotemperatur == 0 ? config.icons.temperature_low : (model.themenfinder.risikotemperatur == 1 ? config.icons.temperature : config.icons.temperature_high)"></div> </button><button class="btn-round fab-pointer-cursor" [class.btn-blue]="false" (click)="callActions('themenfinderglueckwunsch', undefined, true, undefined, [], undefined, undefined)" widget-id="248086 next_page wappnet_fab_button_Imported_Imported_Imported"> 
   <div class="btn-round-img" [style.background-image]="config.icons.arrow_right"></div> </button>
 </div>
</div>