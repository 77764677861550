<div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="grow" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="50px" layout-id="246931" widget-id="248021 root div_Imported_Imported_Imported_Imported">
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-around stretch" fxLayoutGap="20px" layout-id="246963" widget-id="248063 content div_Imported_Imported_Imported_Imported">
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="35%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px" layout-id="246993" widget-id="248159 texts div_Imported_Imported_Imported_Imported">
   <div class="step-header brand-radius brand-shadow brand-gradient" [class.brand-gradient]="true" (click)="callActions(undefined, undefined, true, undefined, [], undefined, undefined)" widget-id="248248 heder wappnet_step_header_Imported_Imported_Imported"> 
    <div class="step-title">
     {{config.themenfinder.lebenssituation.step_header}}
    </div> 
    <div class="step-number">
     {{config.themenfinder.lebenssituation.step_counter}}
    </div> 
   </div>
   <div attachment-point-for="content" [ngClass]="'brand-offset-small-inner brand-radius brand-shadow-inset'" *ngIf="true" fxFlex="grow" widget-id="248249 text div_Imported_Imported_Imported_Imported">
    <article widget-id="248387 wappnet_text-with-header_Imported_Imported_Imported"> 
     <header>
      {{config.themenfinder.lebenssituation.paragraph_header}}
     </header> 
     <p>{{config.themenfinder.lebenssituation.paragraph_content}}</p> 
    </article>
   </div>
  </div>
  <div attachment-point-for="content" [ngClass]="'brand-offset-inner brand-radius brand-shadow'" *ngIf="true" fxFlex="65%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px" layout-id="246994" widget-id="248160 controllers div_Imported_Imported_Imported_Imported">
   <div class="horizontal-slider-thumb" widget-id="248250 situation1 wappnet_slider_thumb_Imported_Imported_Imported">
     {{config.themenfinder.lebenssituation.label1}} 
    <input type="range" [(ngModel)]="model.themenfinder.lebenssituation1.active" min="0" max="1" [class.is-active]="model.themenfinder.lebenssituation1.active==1"> 
   </div>
   <div class="horizontal-slider-thumb" widget-id="248251 situation2 wappnet_slider_thumb_Imported_Imported_Imported">
     {{config.themenfinder.lebenssituation.label2}} 
    <input type="range" [(ngModel)]="model.themenfinder.lebenssituation2.active" min="0" max="1" [class.is-active]="model.themenfinder.lebenssituation2.active==1"> 
   </div>
   <div class="horizontal-slider-thumb" widget-id="248252 situation3 wappnet_slider_thumb_Imported_Imported_Imported">
     {{config.themenfinder.lebenssituation.label3}} 
    <input type="range" [(ngModel)]="model.themenfinder.lebenssituation3.active" min="0" max="1" [class.is-active]="model.themenfinder.lebenssituation3.active==1"> 
   </div>
   <div class="horizontal-slider-thumb" widget-id="248253 situation4 wappnet_slider_thumb_Imported_Imported_Imported">
     {{config.themenfinder.lebenssituation.label4}} 
    <input type="range" [(ngModel)]="model.themenfinder.lebenssituation4.active" min="0" max="1" [class.is-active]="model.themenfinder.lebenssituation4.active==1"> 
   </div>
   <div class="horizontal-slider-thumb" widget-id="248254 situation5 wappnet_slider_thumb_Imported_Imported_Imported">
     {{config.themenfinder.lebenssituation.label5}} 
    <input type="range" [(ngModel)]="model.themenfinder.lebenssituation5.active" min="0" max="1" [class.is-active]="model.themenfinder.lebenssituation5.active==1"> 
   </div>
   <div class="horizontal-slider-thumb" widget-id="248255 situation6 wappnet_slider_thumb_Imported_Imported_Imported">
     {{config.themenfinder.lebenssituation.label6}} 
    <input type="range" [(ngModel)]="model.themenfinder.lebenssituation6.active" min="0" max="1" [class.is-active]="model.themenfinder.lebenssituation6.active==1"> 
   </div>
   <div class="horizontal-slider-thumb" widget-id="248256 situation7 wappnet_slider_thumb_Imported_Imported_Imported">
     {{config.themenfinder.lebenssituation.label7}} 
    <input type="range" [(ngModel)]="model.themenfinder.lebenssituation7.active" min="0" max="1" [class.is-active]="model.themenfinder.lebenssituation7.active==1"> 
   </div>
  </div>
 </div>
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-between center" layout-id="246964" widget-id="248064 footer_buttons div_Imported_Imported_Imported_Imported">
  <button class="btn-round fab-pointer-cursor" [class.btn-blue]="false" (click)="wappnet_fab_button_Imported_Imported_Importedbacknavigate248932('themenfinderelefantengleichnis', undefined, true, undefined, [], undefined, undefined)" widget-id="248161 back wappnet_fab_button_Imported_Imported_Imported"> 
   <div class="btn-round-img" [style.background-image]="config.icons.arrow_left"></div> </button><button class="btn-round temperature-icon fab-pointer-cursor" (click)="callActions('themenfinderrisikotemperatur', undefined, true, undefined, [], undefined, undefined)" widget-id="248163 wappnet_temperature_indicator_Imported_Imported_Imported"> 
   <div class="temperature-icon-peak"> 
    <div class="temperature-icon-peak-background"></div> 
   </div> 
   <div class="btn-round-img" [style.background-image]="model.themenfinder.risikotemperatur == 0 ? config.icons.temperature_low : (model.themenfinder.risikotemperatur == 1 ? config.icons.temperature : config.icons.temperature_high)"></div> </button><button class="btn-round fab-pointer-cursor" [class.btn-blue]="false" (click)="callActions('themenfinderlebenssituationstep2', undefined, true, undefined, [], undefined, undefined)" widget-id="248162 next wappnet_fab_button_Imported_Imported_Imported"> 
   <div class="btn-round-img" [style.background-image]="config.icons.arrow_right"></div> </button>
 </div>
</div>