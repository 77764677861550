import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  public model: any = {};
  public config: any = {};

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.model = this.route.snapshot.data.modelAndConfig[0];
    this.config = this.route.snapshot.data.modelAndConfig[1];
  }
}
