/**
 * userAPI API
 * userAPI API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { PasswordStrengthInfoDTO } from '../model/passwordStrengthInfoDTO';
import { ValueWrapperDTOOfstring } from '../model/valueWrapperDTOOfstring';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EndUserCredentialsResourceService {

    protected basePath = 'https://localhost:8082';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * checkPasswordStrength
     * 
     * @param password password
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkPasswordStrengthUsingPOST(password: string, observe?: 'body', reportProgress?: boolean): Observable<PasswordStrengthInfoDTO>;
    public checkPasswordStrengthUsingPOST(password: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PasswordStrengthInfoDTO>>;
    public checkPasswordStrengthUsingPOST(password: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PasswordStrengthInfoDTO>>;
    public checkPasswordStrengthUsingPOST(password: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling checkPasswordStrengthUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (password !== undefined && password !== null) {
            queryParameters = queryParameters.set('password', <any>password);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<PasswordStrengthInfoDTO>(`${this.basePath}/api/end-user-credentials/password-entropy`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * login
     * 
     * @param password password
     * @param userKey userKey
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginUsingPOST(password: string, userKey: string, observe?: 'body', reportProgress?: boolean): Observable<ValueWrapperDTOOfstring>;
    public loginUsingPOST(password: string, userKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ValueWrapperDTOOfstring>>;
    public loginUsingPOST(password: string, userKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ValueWrapperDTOOfstring>>;
    public loginUsingPOST(password: string, userKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling loginUsingPOST.');
        }

        if (userKey === null || userKey === undefined) {
            throw new Error('Required parameter userKey was null or undefined when calling loginUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (password !== undefined && password !== null) {
            queryParameters = queryParameters.set('password', <any>password);
        }

        let headers = this.defaultHeaders;
        if (userKey !== undefined && userKey !== null) {
            headers = headers.set('userKey', String(userKey));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<ValueWrapperDTOOfstring>(`${this.basePath}/api/end-user-credentials/login`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * passwordResetFinish
     * 
     * @param newPassword newPassword
     * @param resetToken reset-token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public passwordResetFinishUsingPOST(newPassword: string, resetToken: string, observe?: 'body', reportProgress?: boolean): Observable<ValueWrapperDTOOfstring>;
    public passwordResetFinishUsingPOST(newPassword: string, resetToken: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ValueWrapperDTOOfstring>>;
    public passwordResetFinishUsingPOST(newPassword: string, resetToken: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ValueWrapperDTOOfstring>>;
    public passwordResetFinishUsingPOST(newPassword: string, resetToken: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (newPassword === null || newPassword === undefined) {
            throw new Error('Required parameter newPassword was null or undefined when calling passwordResetFinishUsingPOST.');
        }

        if (resetToken === null || resetToken === undefined) {
            throw new Error('Required parameter resetToken was null or undefined when calling passwordResetFinishUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (newPassword !== undefined && newPassword !== null) {
            queryParameters = queryParameters.set('newPassword', <any>newPassword);
        }

        let headers = this.defaultHeaders;
        if (resetToken !== undefined && resetToken !== null) {
            headers = headers.set('reset-token', String(resetToken));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<ValueWrapperDTOOfstring>(`${this.basePath}/api/end-user-credentials/password-reset-finish`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * passwordResetStart
     * 
     * @param userKey userKey
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public passwordResetStartUsingPOST(userKey: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public passwordResetStartUsingPOST(userKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public passwordResetStartUsingPOST(userKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public passwordResetStartUsingPOST(userKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userKey === null || userKey === undefined) {
            throw new Error('Required parameter userKey was null or undefined when calling passwordResetStartUsingPOST.');
        }

        let headers = this.defaultHeaders;
        if (userKey !== undefined && userKey !== null) {
            headers = headers.set('userKey', String(userKey));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/end-user-credentials/password-reset-start`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * setCredentials
     * 
     * @param password password
     * @param userKey userKey
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setCredentialsUsingPOST(password: string, userKey: string, observe?: 'body', reportProgress?: boolean): Observable<ValueWrapperDTOOfstring>;
    public setCredentialsUsingPOST(password: string, userKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ValueWrapperDTOOfstring>>;
    public setCredentialsUsingPOST(password: string, userKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ValueWrapperDTOOfstring>>;
    public setCredentialsUsingPOST(password: string, userKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling setCredentialsUsingPOST.');
        }

        if (userKey === null || userKey === undefined) {
            throw new Error('Required parameter userKey was null or undefined when calling setCredentialsUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (password !== undefined && password !== null) {
            queryParameters = queryParameters.set('password', <any>password);
        }

        let headers = this.defaultHeaders;
        if (userKey !== undefined && userKey !== null) {
            headers = headers.set('userKey', String(userKey));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<ValueWrapperDTOOfstring>(`${this.basePath}/api/end-user-credentials`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
