import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { ActivatedApplicationResourceService } from './api/activatedApplicationResource.service';
import { AppConfigurationResourceService } from './api/appConfigurationResource.service';
import { ApplicationResourceService } from './api/applicationResource.service';
import { CampaignResourceService } from './api/campaignResource.service';
import { ComplexDataTypeResourceService } from './api/complexDataTypeResource.service';
import { ElasticsearchResourceService } from './api/elasticsearchResource.service';
import { EmailServerPasswordResourceService } from './api/emailServerPasswordResource.service';
import { EmailServerResourceService } from './api/emailServerResource.service';
import { EndUserCredentialsResourceService } from './api/endUserCredentialsResource.service';
import { EndUserResourceService } from './api/endUserResource.service';
import { OrganisationResourceService } from './api/organisationResource.service';
import { UserResourceService } from './api/userResource.service';

@NgModule({
    imports: [],
    declarations: [],
    exports: [],
    providers: [
        ActivatedApplicationResourceService,
        AppConfigurationResourceService,
        ApplicationResourceService,
        CampaignResourceService,
        ComplexDataTypeResourceService,
        ElasticsearchResourceService,
        EmailServerPasswordResourceService,
        EmailServerResourceService,
        EndUserCredentialsResourceService,
        EndUserResourceService,
        OrganisationResourceService,
        UserResourceService]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<any> {
        return {
            ngModule: ApiModule,
            providers: [{ provide: Configuration, useFactory: configurationFactory }]
        };
    }

    constructor(@Optional() @SkipSelf() parentModule: ApiModule,
        @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
                'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
