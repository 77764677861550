<div fxLayout="column">
  <div fxLayout="row" class="password-level-row">
    <div
      [style.width]="'25%'"
      *ngFor="let lvl of levelsArray"
      class="password-level-bar"
      [style.background-color]="getBarColor(lvl)"
    ></div>
  </div>
  <span class="password-strenght-label" [style.color]="color">{{ label }}</span>
</div>
