<div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" widget-id="248007 main div_Imported_Imported_Imported_Imported">
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" widget-id="248040 header div_Imported_Imported_Imported_Imported">
  <ng-content widget-id="248103 content-wrapper_Imported_Imported_Imported"></ng-content>
 </div>
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" widget-id="248041 panel div_Imported_Imported_Imported_Imported">
  <ng-template let-i="index" let-count="count" let-isFirst="first" let-isLast="last" let-isEven="even" let-isOdd="odd" let-item [ngForOf]="itemsList" ngFor widget-id="248113 list wappnet_checkbox_withtitle_Imported_Imported_Imported">
   <div class="switcher-wrapper" *ngIf="!showHiddenOnly || !item.modelItem.secured"> 
    <div class="switcher-label"> 
     <div>
      {{item.configItem.header1}}
     </div> 
     <div>
      {{item.configItem.header2}}
     </div> 
    </div> 
    <div class="switcher"> <label> <input type="checkbox" [(ngModel)]="item.modelItem.secured"> <span class="switcher-body"> <span class="switcher-icon"></span> </span> </label> 
    </div> 
   </div>
  </ng-template>
 </div>
</div>