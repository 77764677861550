/**
 * userAPI API
 * userAPI API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ActivatedApplicationDTO } from '../model/activatedApplicationDTO';
import { AppConfigurationDTO } from '../model/appConfigurationDTO';
import { CampaignDTO } from '../model/campaignDTO';
import { EndUserDTO } from '../model/endUserDTO';
import { IssuerInfoDTO } from '../model/issuerInfoDTO';
import { OrganisationDTO } from '../model/organisationDTO';
import { StringWrapperDTO } from '../model/stringWrapperDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OrganisationResourceService {

    protected basePath = 'https://localhost:8082';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * createOrganisation
     * 
     * @param organisationDTO organisationDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrganisationUsingPOST(organisationDTO: OrganisationDTO, observe?: 'body', reportProgress?: boolean): Observable<OrganisationDTO>;
    public createOrganisationUsingPOST(organisationDTO: OrganisationDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganisationDTO>>;
    public createOrganisationUsingPOST(organisationDTO: OrganisationDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganisationDTO>>;
    public createOrganisationUsingPOST(organisationDTO: OrganisationDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (organisationDTO === null || organisationDTO === undefined) {
            throw new Error('Required parameter organisationDTO was null or undefined when calling createOrganisationUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrganisationDTO>(`${this.basePath}/api/organisations`,
            organisationDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteOrganisation
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteOrganisationUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteOrganisationUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteOrganisationUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteOrganisationUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteOrganisationUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/organisations/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllOrganisations
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllOrganisationsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<OrganisationDTO>>;
    public getAllOrganisationsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OrganisationDTO>>>;
    public getAllOrganisationsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OrganisationDTO>>>;
    public getAllOrganisationsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<OrganisationDTO>>(`${this.basePath}/api/organisations`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getEmailTemplate
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEmailTemplateUsingGET(observe?: 'body', reportProgress?: boolean): Observable<StringWrapperDTO>;
    public getEmailTemplateUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StringWrapperDTO>>;
    public getEmailTemplateUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StringWrapperDTO>>;
    public getEmailTemplateUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<StringWrapperDTO>(`${this.basePath}/api/organisations/current/emailTemplate`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getOrganisationAppConfigurations
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationAppConfigurationsUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<AppConfigurationDTO>>;
    public getOrganisationAppConfigurationsUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AppConfigurationDTO>>>;
    public getOrganisationAppConfigurationsUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AppConfigurationDTO>>>;
    public getOrganisationAppConfigurationsUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getOrganisationAppConfigurationsUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<AppConfigurationDTO>>(`${this.basePath}/api/organisations/${encodeURIComponent(String(id))}/app-configurations`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getOrganisationApplications
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationApplicationsUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ActivatedApplicationDTO>>;
    public getOrganisationApplicationsUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ActivatedApplicationDTO>>>;
    public getOrganisationApplicationsUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ActivatedApplicationDTO>>>;
    public getOrganisationApplicationsUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getOrganisationApplicationsUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ActivatedApplicationDTO>>(`${this.basePath}/api/organisations/${encodeURIComponent(String(id))}/applications`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getOrganisationCampaigns
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationCampaignsUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<CampaignDTO>>;
    public getOrganisationCampaignsUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CampaignDTO>>>;
    public getOrganisationCampaignsUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CampaignDTO>>>;
    public getOrganisationCampaignsUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getOrganisationCampaignsUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CampaignDTO>>(`${this.basePath}/api/organisations/${encodeURIComponent(String(id))}/campaigns`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getOrganisationEndUsers
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationEndUsersUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<EndUserDTO>>;
    public getOrganisationEndUsersUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EndUserDTO>>>;
    public getOrganisationEndUsersUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EndUserDTO>>>;
    public getOrganisationEndUsersUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getOrganisationEndUsersUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<EndUserDTO>>(`${this.basePath}/api/organisations/${encodeURIComponent(String(id))}/end-users`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getOrganisation
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<OrganisationDTO>;
    public getOrganisationUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganisationDTO>>;
    public getOrganisationUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganisationDTO>>;
    public getOrganisationUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getOrganisationUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OrganisationDTO>(`${this.basePath}/api/organisations/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getTokenIssuerUrl
     * 
     * @param uuid uuid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTokenIssuerUrlUsingGET(uuid: string, observe?: 'body', reportProgress?: boolean): Observable<IssuerInfoDTO>;
    public getTokenIssuerUrlUsingGET(uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IssuerInfoDTO>>;
    public getTokenIssuerUrlUsingGET(uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IssuerInfoDTO>>;
    public getTokenIssuerUrlUsingGET(uuid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling getTokenIssuerUrlUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<IssuerInfoDTO>(`${this.basePath}/api/organisations/${encodeURIComponent(String(uuid))}/tokenIssuerUrl`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateEmailTemplate
     * 
     * @param emailTemplate emailTemplate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateEmailTemplateUsingPUT(emailTemplate: string, observe?: 'body', reportProgress?: boolean): Observable<StringWrapperDTO>;
    public updateEmailTemplateUsingPUT(emailTemplate: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StringWrapperDTO>>;
    public updateEmailTemplateUsingPUT(emailTemplate: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StringWrapperDTO>>;
    public updateEmailTemplateUsingPUT(emailTemplate: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (emailTemplate === null || emailTemplate === undefined) {
            throw new Error('Required parameter emailTemplate was null or undefined when calling updateEmailTemplateUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<StringWrapperDTO>(`${this.basePath}/api/organisations/current/emailTemplate`,
            emailTemplate,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateOrganisation
     * 
     * @param organisationDTO organisationDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOrganisationUsingPUT(organisationDTO: OrganisationDTO, observe?: 'body', reportProgress?: boolean): Observable<OrganisationDTO>;
    public updateOrganisationUsingPUT(organisationDTO: OrganisationDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganisationDTO>>;
    public updateOrganisationUsingPUT(organisationDTO: OrganisationDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganisationDTO>>;
    public updateOrganisationUsingPUT(organisationDTO: OrganisationDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (organisationDTO === null || organisationDTO === undefined) {
            throw new Error('Required parameter organisationDTO was null or undefined when calling updateOrganisationUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<OrganisationDTO>(`${this.basePath}/api/organisations`,
            organisationDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
