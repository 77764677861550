/**
 * userAPI API
 * userAPI API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DataDTO } from '../model/dataDTO';
import { DocumentPrintDTO } from '../model/documentPrintDTO';
import { EndUserDTO } from '../model/endUserDTO';
import { ResponseEntity } from '../model/responseEntity';
import { UserStatusDTO } from '../model/userStatusDTO';
import { UsersImportDTO } from '../model/usersImportDTO';
import { ValueWrapperDTOOfstring } from '../model/valueWrapperDTOOfstring';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EndUserResourceService {

    protected basePath = 'https://localhost:8082';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * confirmConsent
     * 
     * @param sessionToken sessionToken
     * @param userKey userKey
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confirmConsentUsingPOST(sessionToken?: string, userKey?: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public confirmConsentUsingPOST(sessionToken?: string, userKey?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public confirmConsentUsingPOST(sessionToken?: string, userKey?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public confirmConsentUsingPOST(sessionToken?: string, userKey?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (sessionToken !== undefined && sessionToken !== null) {
            headers = headers.set('sessionToken', String(sessionToken));
        }
        if (userKey !== undefined && userKey !== null) {
            headers = headers.set('userKey', String(userKey));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<ResponseEntity>(`${this.basePath}/api/end-users/consent`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createEndUser
     * 
     * @param endUserDTO endUserDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createEndUserUsingPOST(endUserDTO: EndUserDTO, observe?: 'body', reportProgress?: boolean): Observable<EndUserDTO>;
    public createEndUserUsingPOST(endUserDTO: EndUserDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EndUserDTO>>;
    public createEndUserUsingPOST(endUserDTO: EndUserDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EndUserDTO>>;
    public createEndUserUsingPOST(endUserDTO: EndUserDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (endUserDTO === null || endUserDTO === undefined) {
            throw new Error('Required parameter endUserDTO was null or undefined when calling createEndUserUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<EndUserDTO>(`${this.basePath}/api/end-users`,
            endUserDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createUserData
     * 
     * @param initialDocument initialDocument
     * @param sessionToken sessionToken
     * @param userKey userKey
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createUserDataUsingPOST(initialDocument: any, sessionToken?: string, userKey?: string, observe?: 'body', reportProgress?: boolean): Observable<DataDTO>;
    public createUserDataUsingPOST(initialDocument: any, sessionToken?: string, userKey?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DataDTO>>;
    public createUserDataUsingPOST(initialDocument: any, sessionToken?: string, userKey?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DataDTO>>;
    public createUserDataUsingPOST(initialDocument: any, sessionToken?: string, userKey?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (initialDocument === null || initialDocument === undefined) {
            throw new Error('Required parameter initialDocument was null or undefined when calling createUserDataUsingPOST.');
        }



        let headers = this.defaultHeaders;
        if (sessionToken !== undefined && sessionToken !== null) {
            headers = headers.set('sessionToken', String(sessionToken));
        }
        if (userKey !== undefined && userKey !== null) {
            headers = headers.set('userKey', String(userKey));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<DataDTO>(`${this.basePath}/api/end-users/data`,
            initialDocument,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteEndUser
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteEndUserUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteEndUserUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteEndUserUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteEndUserUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteEndUserUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/end-users/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * finalizeWithoutPassword
     * 
     * @param documentToSave documentToSave
     * @param userKey userKey
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public finalizeWithoutPasswordUsingPUT(documentToSave: any, userKey?: string, observe?: 'body', reportProgress?: boolean): Observable<DataDTO>;
    public finalizeWithoutPasswordUsingPUT(documentToSave: any, userKey?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DataDTO>>;
    public finalizeWithoutPasswordUsingPUT(documentToSave: any, userKey?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DataDTO>>;
    public finalizeWithoutPasswordUsingPUT(documentToSave: any, userKey?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentToSave === null || documentToSave === undefined) {
            throw new Error('Required parameter documentToSave was null or undefined when calling finalizeWithoutPasswordUsingPUT.');
        }


        let headers = this.defaultHeaders;
        if (userKey !== undefined && userKey !== null) {
            headers = headers.set('userKey', String(userKey));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<DataDTO>(`${this.basePath}/api/end-users/data/finalize-without-password`,
            documentToSave,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * finalizeWork
     * 
     * @param sessionToken sessionToken
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public finalizeWorkUsingPOST(sessionToken?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public finalizeWorkUsingPOST(sessionToken?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public finalizeWorkUsingPOST(sessionToken?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public finalizeWorkUsingPOST(sessionToken?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (sessionToken !== undefined && sessionToken !== null) {
            headers = headers.set('sessionToken', String(sessionToken));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/end-users/data/finalize`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getDocumentPrint
     * 
     * @param advisorKey advisorKey
     * @param sessionToken sessionToken
     * @param userKey userKey
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocumentPrintUsingGET(advisorKey?: string, sessionToken?: string, userKey?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentPrintDTO>;
    public getDocumentPrintUsingGET(advisorKey?: string, sessionToken?: string, userKey?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentPrintDTO>>;
    public getDocumentPrintUsingGET(advisorKey?: string, sessionToken?: string, userKey?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentPrintDTO>>;
    public getDocumentPrintUsingGET(advisorKey?: string, sessionToken?: string, userKey?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let headers = this.defaultHeaders;
        if (advisorKey !== undefined && advisorKey !== null) {
            headers = headers.set('advisorKey', String(advisorKey));
        }
        if (sessionToken !== undefined && sessionToken !== null) {
            headers = headers.set('sessionToken', String(sessionToken));
        }
        if (userKey !== undefined && userKey !== null) {
            headers = headers.set('userKey', String(userKey));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DocumentPrintDTO>(`${this.basePath}/api/end-users/printout`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getEndUser
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEndUserUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<EndUserDTO>;
    public getEndUserUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EndUserDTO>>;
    public getEndUserUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EndUserDTO>>;
    public getEndUserUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getEndUserUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<EndUserDTO>(`${this.basePath}/api/end-users/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getStatus
     * 
     * @param sessionToken sessionToken
     * @param userKey userKey
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStatusUsingGET(sessionToken?: string, userKey?: string, observe?: 'body', reportProgress?: boolean): Observable<UserStatusDTO>;
    public getStatusUsingGET(sessionToken?: string, userKey?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserStatusDTO>>;
    public getStatusUsingGET(sessionToken?: string, userKey?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserStatusDTO>>;
    public getStatusUsingGET(sessionToken?: string, userKey?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (sessionToken !== undefined && sessionToken !== null) {
            headers = headers.set('sessionToken', String(sessionToken));
        }
        if (userKey !== undefined && userKey !== null) {
            headers = headers.set('userKey', String(userKey));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<UserStatusDTO>(`${this.basePath}/api/end-users/status`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getUserData
     * 
     * @param advisorKey advisorKey
     * @param sessionToken sessionToken
     * @param userKey userKey
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserDataUsingGET(advisorKey?: string, sessionToken?: string, userKey?: string, observe?: 'body', reportProgress?: boolean): Observable<DataDTO>;
    public getUserDataUsingGET(advisorKey?: string, sessionToken?: string, userKey?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DataDTO>>;
    public getUserDataUsingGET(advisorKey?: string, sessionToken?: string, userKey?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DataDTO>>;
    public getUserDataUsingGET(advisorKey?: string, sessionToken?: string, userKey?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let headers = this.defaultHeaders;
        if (advisorKey !== undefined && advisorKey !== null) {
            headers = headers.set('advisorKey', String(advisorKey));
        }
        if (sessionToken !== undefined && sessionToken !== null) {
            headers = headers.set('sessionToken', String(sessionToken));
        }
        if (userKey !== undefined && userKey !== null) {
            headers = headers.set('userKey', String(userKey));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DataDTO>(`${this.basePath}/api/end-users/data`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * importEndUsers
     * 
     * @param usersImportDTO usersImportDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importEndUsersUsingPOST(usersImportDTO: UsersImportDTO, observe?: 'body', reportProgress?: boolean): Observable<Array<EndUserDTO>>;
    public importEndUsersUsingPOST(usersImportDTO: UsersImportDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EndUserDTO>>>;
    public importEndUsersUsingPOST(usersImportDTO: UsersImportDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EndUserDTO>>>;
    public importEndUsersUsingPOST(usersImportDTO: UsersImportDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (usersImportDTO === null || usersImportDTO === undefined) {
            throw new Error('Required parameter usersImportDTO was null or undefined when calling importEndUsersUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<EndUserDTO>>(`${this.basePath}/api/end-users/import`,
            usersImportDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * saveCurrentIntermediateStatus
     * 
     * @param consent consent
     * @param documentToSave documentToSave
     * @param finalize finalize
     * @param password password
     * @param userKey userKey
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveCurrentIntermediateStatusUsingPOST(consent: boolean, documentToSave: any, finalize: boolean, password: string, userKey: string, observe?: 'body', reportProgress?: boolean): Observable<ValueWrapperDTOOfstring>;
    public saveCurrentIntermediateStatusUsingPOST(consent: boolean, documentToSave: any, finalize: boolean, password: string, userKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ValueWrapperDTOOfstring>>;
    public saveCurrentIntermediateStatusUsingPOST(consent: boolean, documentToSave: any, finalize: boolean, password: string, userKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ValueWrapperDTOOfstring>>;
    public saveCurrentIntermediateStatusUsingPOST(consent: boolean, documentToSave: any, finalize: boolean, password: string, userKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (consent === null || consent === undefined) {
            throw new Error('Required parameter consent was null or undefined when calling saveCurrentIntermediateStatusUsingPOST.');
        }

        if (documentToSave === null || documentToSave === undefined) {
            throw new Error('Required parameter documentToSave was null or undefined when calling saveCurrentIntermediateStatusUsingPOST.');
        }

        if (finalize === null || finalize === undefined) {
            throw new Error('Required parameter finalize was null or undefined when calling saveCurrentIntermediateStatusUsingPOST.');
        }

        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling saveCurrentIntermediateStatusUsingPOST.');
        }

        if (userKey === null || userKey === undefined) {
            throw new Error('Required parameter userKey was null or undefined when calling saveCurrentIntermediateStatusUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (consent !== undefined && consent !== null) {
            queryParameters = queryParameters.set('consent', <any>consent);
        }
        if (finalize !== undefined && finalize !== null) {
            queryParameters = queryParameters.set('finalize', <any>finalize);
        }
        if (password !== undefined && password !== null) {
            queryParameters = queryParameters.set('password', <any>password);
        }

        let headers = this.defaultHeaders;
        if (userKey !== undefined && userKey !== null) {
            headers = headers.set('userKey', String(userKey));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ValueWrapperDTOOfstring>(`${this.basePath}/api/end-users/data/save`,
            documentToSave,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateEndUser
     * 
     * @param endUserDTO endUserDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateEndUserUsingPUT(endUserDTO: EndUserDTO, observe?: 'body', reportProgress?: boolean): Observable<EndUserDTO>;
    public updateEndUserUsingPUT(endUserDTO: EndUserDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EndUserDTO>>;
    public updateEndUserUsingPUT(endUserDTO: EndUserDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EndUserDTO>>;
    public updateEndUserUsingPUT(endUserDTO: EndUserDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (endUserDTO === null || endUserDTO === undefined) {
            throw new Error('Required parameter endUserDTO was null or undefined when calling updateEndUserUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<EndUserDTO>(`${this.basePath}/api/end-users`,
            endUserDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateUserData
     * 
     * @param documentToSave documentToSave
     * @param sessionToken sessionToken
     * @param userKey userKey
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUserDataUsingPUT(documentToSave: any, sessionToken?: string, userKey?: string, observe?: 'body', reportProgress?: boolean): Observable<DataDTO>;
    public updateUserDataUsingPUT(documentToSave: any, sessionToken?: string, userKey?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DataDTO>>;
    public updateUserDataUsingPUT(documentToSave: any, sessionToken?: string, userKey?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DataDTO>>;
    public updateUserDataUsingPUT(documentToSave: any, sessionToken?: string, userKey?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentToSave === null || documentToSave === undefined) {
            throw new Error('Required parameter documentToSave was null or undefined when calling updateUserDataUsingPUT.');
        }



        let headers = this.defaultHeaders;
        if (sessionToken !== undefined && sessionToken !== null) {
            headers = headers.set('sessionToken', String(sessionToken));
        }
        if (userKey !== undefined && userKey !== null) {
            headers = headers.set('userKey', String(userKey));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<DataDTO>(`${this.basePath}/api/end-users/data`,
            documentToSave,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
