import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-cookie-consent-banner',
  templateUrl: './cookie-consent-banner.component.html',
  styleUrls: ['./cookie-consent-banner.component.scss'],
})
export class CookieConsentBannerComponent implements OnInit {
  @Input() public config: any = {};

  @Input() public cookieBannerTemplate: TemplateRef<any>;

  constructor() {}

  ngOnInit(): void {}

  public shouldBeDisplayed(): boolean {
    return sessionStorage.getItem('cookieConsent') == null;
  }

  public close(): void {
    sessionStorage.setItem('cookieConsent', 'true');
  }
}
