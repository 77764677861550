/**
 * userAPI API
 * userAPI API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface EndUsersRequestDTO { 
    pageIndex?: number;
    pageSize?: number;
    searchPhrase?: string;
    sortDirection?: EndUsersRequestDTO.SortDirectionEnum;
    sortField?: string;
}
export namespace EndUsersRequestDTO {
    export type SortDirectionEnum = 'DESC' | 'ASC';
    export const SortDirectionEnum = {
        DESC: 'DESC' as SortDirectionEnum,
        ASC: 'ASC' as SortDirectionEnum
    };
}
