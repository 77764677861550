import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of, Subscriber } from 'rxjs';
import { DataAccessService } from './data-access-service';

@Injectable({
  providedIn: 'root',
})
export class ConfigAccessService implements Resolve<any> {
  private config: any;
  private dataLoaded: boolean = false;
  private subscribers: Subscriber<any>[] = [];
  private loadingData: boolean = false;

  private get isProd(): boolean {
    return true;
  }

  constructor(private dataAccessService: DataAccessService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): any | Observable<any> | Promise<any> {
    if (this.dataLoaded) {
      return of(this.config);
    }

    var result = new Observable<any>((sub) => {
      this.subscribers.push(sub);
      this.startLoadingData(route);
    });
    return result;
  }

  private startLoadingData(route: ActivatedRouteSnapshot) {
    if (route && this.loadingData == false) {
      this.loadingData = true;
      this.dataAccessService.saveAccessKeysInSessionStorage(route);
      this.dataAccessService.appInitObservable().subscribe((data) => {
        this.dataLoaded = true;
        this.loadingData = false;
        this.config = data;
        for (let i = 0; i < this.subscribers.length; i++) {
          this.subscribers[i].next(this.config);
          this.subscribers[i].complete();
        }
      });
    }
  }
}
