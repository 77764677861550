/**
 * userAPI API
 * userAPI API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ActivatedApplicationDTO } from '../model/activatedApplicationDTO';
import { AppConfigurationDTO } from '../model/appConfigurationDTO';
import { AppSettingsDTO } from '../model/appSettingsDTO';
import { CampaignDTO } from '../model/campaignDTO';
import { DataDTO } from '../model/dataDTO';
import { StringWrapperDTO } from '../model/stringWrapperDTO';
import { ValueWrapperDTOOfstring } from '../model/valueWrapperDTOOfstring';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ActivatedApplicationResourceService {

    protected basePath = 'https://localhost:8082';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * activateApplication
     * 
     * @param activatedApplicationDTO activatedApplicationDTO
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activateApplicationUsingPOST(activatedApplicationDTO: ActivatedApplicationDTO, id: number, observe?: 'body', reportProgress?: boolean): Observable<ActivatedApplicationDTO>;
    public activateApplicationUsingPOST(activatedApplicationDTO: ActivatedApplicationDTO, id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ActivatedApplicationDTO>>;
    public activateApplicationUsingPOST(activatedApplicationDTO: ActivatedApplicationDTO, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ActivatedApplicationDTO>>;
    public activateApplicationUsingPOST(activatedApplicationDTO: ActivatedApplicationDTO, id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activatedApplicationDTO === null || activatedApplicationDTO === undefined) {
            throw new Error('Required parameter activatedApplicationDTO was null or undefined when calling activateApplicationUsingPOST.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling activateApplicationUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ActivatedApplicationDTO>(`${this.basePath}/api/activated-applications/admin-api/${encodeURIComponent(String(id))}`,
            activatedApplicationDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteActivatedApplication
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteActivatedApplicationUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteActivatedApplicationUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteActivatedApplicationUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteActivatedApplicationUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteActivatedApplicationUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/activated-applications/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getActivatedApplication
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActivatedApplicationUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<ActivatedApplicationDTO>;
    public getActivatedApplicationUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ActivatedApplicationDTO>>;
    public getActivatedApplicationUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ActivatedApplicationDTO>>;
    public getActivatedApplicationUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getActivatedApplicationUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ActivatedApplicationDTO>(`${this.basePath}/api/activated-applications/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllActivatedApplicationsByApplicationId
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllActivatedApplicationsByApplicationIdUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ActivatedApplicationDTO>>;
    public getAllActivatedApplicationsByApplicationIdUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ActivatedApplicationDTO>>>;
    public getAllActivatedApplicationsByApplicationIdUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ActivatedApplicationDTO>>>;
    public getAllActivatedApplicationsByApplicationIdUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getAllActivatedApplicationsByApplicationIdUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ActivatedApplicationDTO>>(`${this.basePath}/api/activated-applications/admin-api/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllActivatedApplications
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllActivatedApplicationsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<ActivatedApplicationDTO>>;
    public getAllActivatedApplicationsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ActivatedApplicationDTO>>>;
    public getAllActivatedApplicationsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ActivatedApplicationDTO>>>;
    public getAllActivatedApplicationsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ActivatedApplicationDTO>>(`${this.basePath}/api/activated-applications`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getCampaigns
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCampaignsUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<CampaignDTO>>;
    public getCampaignsUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CampaignDTO>>>;
    public getCampaignsUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CampaignDTO>>>;
    public getCampaignsUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getCampaignsUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CampaignDTO>>(`${this.basePath}/api/activated-applications/${encodeURIComponent(String(id))}/campaigns`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getCanCreateTestConfigurations
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCanCreateTestConfigurationsUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public getCanCreateTestConfigurationsUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public getCanCreateTestConfigurationsUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public getCanCreateTestConfigurationsUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getCanCreateTestConfigurationsUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<boolean>(`${this.basePath}/api/activated-applications/${encodeURIComponent(String(id))}/test-configurations`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getConfigData
     * 
     * @param advisorKey advisorKey
     * @param sessionToken sessionToken
     * @param userKey userKey
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfigDataUsingGET(advisorKey?: string, sessionToken?: string, userKey?: string, observe?: 'body', reportProgress?: boolean): Observable<DataDTO>;
    public getConfigDataUsingGET(advisorKey?: string, sessionToken?: string, userKey?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DataDTO>>;
    public getConfigDataUsingGET(advisorKey?: string, sessionToken?: string, userKey?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DataDTO>>;
    public getConfigDataUsingGET(advisorKey?: string, sessionToken?: string, userKey?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let headers = this.defaultHeaders;
        if (advisorKey !== undefined && advisorKey !== null) {
            headers = headers.set('advisorKey', String(advisorKey));
        }
        if (sessionToken !== undefined && sessionToken !== null) {
            headers = headers.set('sessionToken', String(sessionToken));
        }
        if (userKey !== undefined && userKey !== null) {
            headers = headers.set('userKey', String(userKey));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DataDTO>(`${this.basePath}/api/activated-applications/config`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getConfigurations
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfigurationsUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<AppConfigurationDTO>>;
    public getConfigurationsUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AppConfigurationDTO>>>;
    public getConfigurationsUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AppConfigurationDTO>>>;
    public getConfigurationsUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getConfigurationsUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<AppConfigurationDTO>>(`${this.basePath}/api/activated-applications/${encodeURIComponent(String(id))}/configurations`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getCss
     * 
     * @param advisorKey advisorKey
     * @param sessionToken sessionToken
     * @param userKey userKey
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCssUsingGET(advisorKey?: string, sessionToken?: string, userKey?: string, observe?: 'body', reportProgress?: boolean): Observable<ValueWrapperDTOOfstring>;
    public getCssUsingGET(advisorKey?: string, sessionToken?: string, userKey?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ValueWrapperDTOOfstring>>;
    public getCssUsingGET(advisorKey?: string, sessionToken?: string, userKey?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ValueWrapperDTOOfstring>>;
    public getCssUsingGET(advisorKey?: string, sessionToken?: string, userKey?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let headers = this.defaultHeaders;
        if (advisorKey !== undefined && advisorKey !== null) {
            headers = headers.set('advisorKey', String(advisorKey));
        }
        if (sessionToken !== undefined && sessionToken !== null) {
            headers = headers.set('sessionToken', String(sessionToken));
        }
        if (userKey !== undefined && userKey !== null) {
            headers = headers.set('userKey', String(userKey));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ValueWrapperDTOOfstring>(`${this.basePath}/api/activated-applications/css`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getSettings
     * 
     * @param advisorKey advisorKey
     * @param sessionToken sessionToken
     * @param userKey userKey
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSettingsUsingGET(advisorKey?: string, sessionToken?: string, userKey?: string, observe?: 'body', reportProgress?: boolean): Observable<AppSettingsDTO>;
    public getSettingsUsingGET(advisorKey?: string, sessionToken?: string, userKey?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AppSettingsDTO>>;
    public getSettingsUsingGET(advisorKey?: string, sessionToken?: string, userKey?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AppSettingsDTO>>;
    public getSettingsUsingGET(advisorKey?: string, sessionToken?: string, userKey?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let headers = this.defaultHeaders;
        if (advisorKey !== undefined && advisorKey !== null) {
            headers = headers.set('advisorKey', String(advisorKey));
        }
        if (sessionToken !== undefined && sessionToken !== null) {
            headers = headers.set('sessionToken', String(sessionToken));
        }
        if (userKey !== undefined && userKey !== null) {
            headers = headers.set('userKey', String(userKey));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<AppSettingsDTO>(`${this.basePath}/api/activated-applications/settings`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * logBusinessEvent
     * 
     * @param eventName eventName
     * @param sessionToken sessionToken
     * @param userKey userKey
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public logBusinessEventUsingPOST(eventName: StringWrapperDTO, sessionToken?: string, userKey?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public logBusinessEventUsingPOST(eventName: StringWrapperDTO, sessionToken?: string, userKey?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public logBusinessEventUsingPOST(eventName: StringWrapperDTO, sessionToken?: string, userKey?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public logBusinessEventUsingPOST(eventName: StringWrapperDTO, sessionToken?: string, userKey?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (eventName === null || eventName === undefined) {
            throw new Error('Required parameter eventName was null or undefined when calling logBusinessEventUsingPOST.');
        }



        let headers = this.defaultHeaders;
        if (sessionToken !== undefined && sessionToken !== null) {
            headers = headers.set('sessionToken', String(sessionToken));
        }
        if (userKey !== undefined && userKey !== null) {
            headers = headers.set('userKey', String(userKey));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/activated-applications/log-event`,
            eventName,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
