<div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="grow" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="50px" layout-id="246927" widget-id="248015 root div_Imported_Imported_Imported_Imported">
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-around stretch" fxLayoutGap="20px" layout-id="246954" widget-id="248054 content div_Imported_Imported_Imported_Imported">
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="35%" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="246987" widget-id="248140 texts div_Imported_Imported_Imported_Imported">
   <div class="step-header brand-radius brand-shadow brand-gradient" [class.brand-gradient]="true" (click)="callActions(undefined, undefined, true, undefined, [], undefined, undefined)" widget-id="248234 header wappnet_step_header_Imported_Imported_Imported"> 
    <div class="step-title">
     {{config.themenfinder.risikotemperatur.header_text}}
    </div> 
    <div class="step-number">
     {{config.themenfinder.risikotemperatur.header_counter}}
    </div> 
   </div>
   <div attachment-point-for="content" [ngClass]="'brand-offset-small-inner brand-radius brand-shadow-inset'" *ngIf="true" fxFlex="grow" widget-id="248235 content div_Imported_Imported_Imported_Imported">
    <article fxFlex="100%" widget-id="248359 paragraph wappnet_text-with-header_Imported_Imported_Imported"> 
     <header>
      {{config.themenfinder.risikotemperatur.paragraph_header}}
     </header> 
     <p>{{config.themenfinder.risikotemperatur.paragraph_text}}</p> 
    </article>
   </div>
  </div>
  <div attachment-point-for="content" [ngClass]="'brand-offset-inner brand-radius brand-shadow'" *ngIf="true" fxFlex="65%" fxLayout="row" fxLayoutAlign="space-between stretch" layout-id="246988" widget-id="248141 temperature div_Imported_Imported_Imported_Imported">
   <div fxLayout="row" fxLayoutAlign="start stretch" widget-id="248236 temperatur wappnet_risikotemperatur_Imported_Imported_Imported"> 
    <div class="vertical-temperature-slider"> 
     <div class="vertical-temperature-range"> 
      <input type="range" [(ngModel)]="model.themenfinder.risikotemperatur" max="2" min="0"> 
     </div> 
     <div class="vertical-temperature-img" [style.background-image]="model.themenfinder.risikotemperatur == 0 ? config.landingPage.thermometerLowPictUrl : (model.themenfinder.risikotemperatur == 1 ? config.landingPage.thermometerMiddlePictUrl : config.landingPage.thermometerHighPictUrl)"></div> 
    </div> 
    <div fxLayout="column" fxLayoutAlign="start stretch" class="temperature-dropdown-container" fxLayoutGap="10px"> 
     <div class="temperature-dropdown orange-one" fxFlex="28%" [class.is-dimmed]="model.themenfinder.risikotemperatur!=2"> 
      <div class="temperature-dropdown-trigger" [class.is-active]="model.appState.risikotemperaturExpanded.first_clicked" (click)="callActions(undefined, 'model.appState.risikotemperaturExpanded.first_clicked', false, undefined, [], undefined, undefined)"> 
       <div class="temperature-dropdown-arrow"> 
        <div class="shape-first"></div> 
       </div> 
       <div class="temperature-dropdown-title"> 
        <div>
         {{config.themenfinder.risikotemperatur.dropdown_title_1}}
        </div> 
       </div> 
      </div> 
      <div class="temperature-dropdown-text">
       {{config.themenfinder.risikotemperatur.dropdown_text_1}}
      </div> 
     </div> 
     <div class="temperature-dropdown orange-two" fxFlex="28%" [class.is-dimmed]="model.themenfinder.risikotemperatur!=1"> 
      <div class="temperature-dropdown-trigger" [class.is-active]="model.appState.risikotemperaturExpanded.second_clicked" (click)="callActions(undefined, 'model.appState.risikotemperaturExpanded.second_clicked', false, undefined, [], undefined, undefined)"> 
       <div class="temperature-dropdown-arrow"> 
        <div class="shape-first"></div> 
       </div> 
       <div class="temperature-dropdown-title"> 
        <div>
         {{config.themenfinder.risikotemperatur.dropdown_title_2}}
        </div> 
       </div> 
      </div> 
      <div class="temperature-dropdown-text">
       {{config.themenfinder.risikotemperatur.dropdown_text_2}}
      </div> 
     </div> 
     <div class="temperature-dropdown orange-three" fxFlex="28%" [class.is-dimmed]="model.themenfinder.risikotemperatur!=0"> 
      <div class="temperature-dropdown-trigger" [class.is-active]="model.appState.risikotemperaturExpanded.third_clicked" (click)="callActions(undefined, 'model.appState.risikotemperaturExpanded.third_clicked', false, undefined, [], undefined, undefined)"> 
       <div class="temperature-dropdown-arrow"> 
        <div class="shape-first"></div> 
       </div> 
       <div class="temperature-dropdown-title"> 
        <div>
         {{config.themenfinder.risikotemperatur.dropdown_title_3}}
        </div> 
       </div> 
      </div> 
      <div class="temperature-dropdown-text">
       {{config.themenfinder.risikotemperatur.dropdown_text_3}}
      </div> 
     </div> 
     <div fxFlex="16%"></div> 
    </div> 
   </div>
  </div>
 </div>
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" layout-id="246955" widget-id="248055 footer_buttons div_Imported_Imported_Imported_Imported">
  <button class="btn-round fab-pointer-cursor" [class.btn-blue]="false" (click)="callActions('themenfindersteps', undefined, true, undefined, [], undefined, undefined)" widget-id="248142 button_back wappnet_fab_button_Imported_Imported_Imported"> 
   <div class="btn-round-img" [style.background-image]="config.icons.arrow_left"></div> </button><button class="btn-round fab-pointer-cursor" [class.btn-blue]="false" (click)="wappnet_fab_button_Imported_Imported_Importedbutton_nextnavigate248921('themenfinderelefantengleichnis', undefined, true, undefined, [], undefined, undefined)" widget-id="248143 button_next wappnet_fab_button_Imported_Imported_Imported"> 
   <div class="btn-round-img" [style.background-image]="config.icons.arrow_right"></div> </button>
 </div>
</div>