import { DataAccessService } from '../../utils/data-access-service';
import { ListsMergeService } from '../../utils/lists-merge.service';
import { UserStatusDTO } from 'src/app/usersdk';
import { Subscription } from 'rxjs';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectionPositionPair } from '@angular/cdk/overlay';


@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent implements OnInit, OnDestroy {

  @Input()
  public config: any = {};

  @Input()
  public model: any = {};

  @Input()
  public status: UserStatusDTO = {};

  private statusSubscription: Subscription;

   @Input()
 public showHiddenOnly: boolean = false;

 public get isProd() {
   return true;
 }


  

  constructor(private route: ActivatedRoute, private router: Router, private dataAccessService : DataAccessService,
              private listsMerge: ListsMergeService) { }

  ngOnInit(): void {
    if (this.route.snapshot.data.modelAndConfig) {
        this.model = this.route.snapshot.data.modelAndConfig[0];
        this.config = this.route.snapshot.data.modelAndConfig[1];
        this.status = this.route.snapshot.data.modelAndConfig[2];
    }
    this.dataAccessService.sendEvent('Anwendung gestartet');
    this.statusSubscription = this.dataAccessService.getStatusObservable().subscribe(status => this.status = status);
  }

  ngOnDestroy(): void {
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
  }

   public panelPositions: ConnectionPositionPair[] = [];

 private defaultPanelPositions: ConnectionPositionPair[] = [{ "originX": "start", "originY": "bottom", "overlayX": "start", "overlayY": "top" },
  { "originX": "start", "originY": "top", "overlayX": "start", "overlayY": "bottom" },
  { "originX": "end", "originY": "top", "overlayX": "end", "overlayY": "bottom" },
  { "originX": "end", "originY": "bottom", "overlayX": "end", "overlayY": "top" }];

 private openPanel(widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[]) {
    if (panelPositions && panelPositions.length > 0) {
      this.panelPositions = panelPositions.concat(this.defaultPanelPositions);
    } else {
      this.panelPositions = this.defaultPanelPositions;
    }
    this['isOpen' + widgetToOpenAsPanelId] = !this['isOpen' + widgetToOpenAsPanelId];
  }


   callActions(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any,
              eventToSend : string) {
        this.tweakModelField(pathToBooleanFieldToTweak, item);
    if (saveModel) {
      this.dataAccessService.saveDocument();
    }
    if (eventToSend) {
      this.dataAccessService.sendEvent(eventToSend);
    }

    if (pathToNavigate) {
      this.router.navigate(pathToNavigate.split('/'));
    }
    if(widgetToOpenAsPanelId) {
      this.openPanel(widgetToOpenAsPanelId, panelPositions);
    }
  }


    private tweakModelField(pathToBooleanFieldToTweak: string, item: any) {
      if (pathToBooleanFieldToTweak) {
        var pathParts = pathToBooleanFieldToTweak.split('.');
        var pathLength = pathParts.length;
        if (pathLength < 2) {
          console.warn("Path to tweak to short: " + pathToBooleanFieldToTweak);
          return;
        }
        var modelToSet = {};
        if (pathParts[0] == 'item') {
          modelToSet = item;
        } else if (pathParts[0] == 'model') {
          modelToSet = this.model;
        }
        for (var i = 1; i < pathLength - 1; i++) {
          modelToSet = modelToSet[pathParts[i]];
        }
        var currentValue = modelToSet[pathParts[pathLength - 1]];
        if (currentValue) {
          modelToSet[pathParts[pathLength - 1]] = false;
        } else {
          modelToSet[pathParts[pathLength - 1]] = true;
        }
      }
   }


  public wappnet_tile_Imported_Imported_Imported_ImportedrisikotemperaturonClick248944(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.appState.themenfinderVisited = true;
this.model.appState.risikoDone = true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}




public wappnet_tile_Imported_Imported_Imported_ImportedlebenssituationonClick248977(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.appState.lebenssituationDone = true;
this.model.appState.themenfinderVisited = true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}


public wappnet_tile_Imported_Imported_Imported_ImportedelefantenbeispielonClick248978(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.appState.elefantengleichnisDone = true;
this.model.appState.themenfinderVisited = true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}


public wappnet_tile_rich_Imported_Imported_Imported_Importednavigation_tileonClick248902(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  console.log("Themenfinder visited 3: " + this.model.appState.themenfinderVisited);
this.model.appState.themenfinderVisited=true;
console.log("Themenfinder visited 4: " + this.model.appState.themenfinderVisited);
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}




public wappnet_tile_rich_Imported_Imported_Imported_Importednavigation_tileonClick248903(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.appState.geldgefuehleVisited=true
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}






public wappnet_tile_Imported_Imported_Imported_ImporteddenkfehleronClick248979(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.appState.denkfehlerDone = true;
this.model.appState.geldgefuehleVisited = true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}


public wappnet_tile_Imported_Imported_Imported_ImportedglaubenssaetzeonClick248980(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.appState.glaubenssaetzeDone = true;
this.model.appState.geldgefuehleVisited = true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}






public wappnet_tile_Imported_Imported_Imported_ImportedberatungswunschzettelonClick248945(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.appState.beratungsWunschzettelDone = true;
this.model.appState.beratungsidealVisited = true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}


public wappnet_tile_Imported_Imported_Imported_ImporteddialogwunschonClick248946(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.appState.dialogWunschDone=true;
this.model.appState.beratungsidealVisited = true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}


public wappnet_tile_rich_Imported_Imported_Imported_Importednavigation_tileonClick248904(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.appState.beratungsidealVisited=true
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}


}
