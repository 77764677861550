/**
 * userAPI API
 * userAPI API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface CampaignDTO { 
    activatedApplicationId?: number;
    anonymity?: CampaignDTO.AnonymityEnum;
    appConfigurationId?: number;
    emailFrom?: string;
    emailReplyTo?: string;
    emailSubject?: string;
    emailTemplate?: string;
    id?: number;
    launched?: boolean;
    name?: string;
    organisationId?: number;
    userPasswordRequired?: boolean;
    validTo?: Date;
}
export namespace CampaignDTO {
    export type AnonymityEnum = 'ANONYMOUS' | 'NONANONYMOUS';
    export const AnonymityEnum = {
        ANONYMOUS: 'ANONYMOUS' as AnonymityEnum,
        NONANONYMOUS: 'NONANONYMOUS' as AnonymityEnum
    };
}
