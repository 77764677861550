<div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px" layout-id="246928" widget-id="248017 div_Imported_Imported_Imported_Imported">
 <div class="article-img" [style.background-image]="config.themenfinder.landingpage.imageUrl" fxFlex="60%" widget-id="248058 image wappnet_image_container_Imported_Imported_Imported"> 
 </div>
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="40%" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="10px" layout-id="246958" widget-id="248057 right panel div_Imported_Imported_Imported_Imported">
  <div attachment-point-for="content" [ngClass]="'brand-offset-inner brand-radius brand-shadow'" *ngIf="true" layout-id="246911" widget-id="248147 texts div_Imported_Imported_Imported_Imported">
   <article widget-id="248238 text1 wappnet_text-with-header_Imported_Imported_Imported"> 
    <header>
     {{config.themenfinder.landingpage.header1}}
    </header> 
    <p>{{config.themenfinder.landingpage.content1}}</p> 
   </article>
   <article widget-id="248237 text2 wappnet_text-with-header_Imported_Imported_Imported"> 
    <header>
     {{config.themenfinder.landingpage.header2}}
    </header> 
    <p>{{config.themenfinder.landingpage.content2}}</p> 
   </article>
  </div>
  <div class="btn-main" (click)="callActions('themenfindersteps', undefined, false, undefined, [], undefined, undefined)" fxFlex="10%" widget-id="248148 button wappnet_nav_button_Imported_Imported_Imported">
    {{config.themenfinder.landingpage.navButton}} 
  </div>
 </div>
</div>