<div attachment-point-for="content" [ngClass]="'debug1'" *ngIf="true" fxFlex="grow" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="246922" widget-id="248006 root div_Imported_Imported_Imported_Imported">
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" widget-id="248037 row with content div_Imported_Imported_Imported_Imported">
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="15%" widget-id="248099 left placeholder div_Imported_Imported_Imported_Imported"></div>
  <div attachment-point-for="content" [ngClass]="'brand-offset-small-inner brand-radius brand-shadow-inset'" *ngIf="true" fxFlex="70%" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="246980" widget-id="248100 main frame div_Imported_Imported_Imported_Imported">
   <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="50%" fxLayout="column" fxLayoutAlign="start stretch" layout-id="247012" widget-id="248210 left side div_Imported_Imported_Imported_Imported">
    <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="40px" widget-id="248341 placeholder div_Imported_Imported_Imported_Imported"></div>
    <article widget-id="248342 header wappnet_text-with-header_Imported_Imported_Imported"> 
     <header [ngClass]="'success-title'">
      {{config.themenfinder.glueckwunsch.header}}
     </header> 
     <p></p> 
    </article>
    <p widget-id="248343 paragraph1 wappnet_paragraph_Imported_Imported_Imported">{{config.themenfinder.glueckwunsch.paragraph1}}</p>
    <p widget-id="248344 paragraph2 wappnet_paragraph_Imported_Imported_Imported">{{config.themenfinder.glueckwunsch.paragraph2}}</p>
    <p widget-id="248345 paragraph3 wappnet_paragraph_Imported_Imported_Imported">{{config.themenfinder.glueckwunsch.paragraph3}}</p>
    <p [ngClass]="'success-signature'" widget-id="248346 signature wappnet_paragraph_Imported_Imported_Imported">{{config.themenfinder.glueckwunsch.signature}}</p>
   </div>
   <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="50%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="40px" layout-id="247013" widget-id="248211 right side div_Imported_Imported_Imported_Imported">
    <div attachment-point-for="content" [ngClass]="'bgImgCenter'" [style.background-image]="config.themenfinder.glueckwunsch.imageUrl" *ngIf="true" fxFlex="145px" widget-id="248347 party popper image div_Imported_Imported_Imported_Imported"></div>
    <div class="btn-main" (click)="callActions(undefined, undefined, true, undefined, [], undefined, undefined)" widget-id="248348 generate code wappnet_nav_button_Imported_Imported_Imported">
      {{config.themenfinder.glueckwunsch.generate_button_text}} 
    </div>
    <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="" layout-id="247020" widget-id="248349 pdf and share buttons div_Imported_Imported_Imported_Imported">
     <button class="btn-round fab-pointer-cursor" [class.btn-blue]="false" #origin248039="cdkOverlayOrigin" cdkOverlayOrigin="" (click)="callActions(undefined, undefined, true, 248039, [{&quot;id&quot;:250101,&quot;originX&quot;:'center',&quot;originY&quot;:'top',&quot;overlayX&quot;:'center',&quot;overlayY&quot;:'top',&quot;offsetX&quot;:0,&quot;offsetY&quot;:-22,&quot;uiWidgetActionInstanceId&quot;:249053}], undefined, undefined)" widget-id="248418 share wappnet_fab_button_Imported_Imported_Imported"> 
      <div class="btn-round-img" [style.background-image]="config.icons.share"></div> </button><button class="btn-round fab-pointer-cursor" [class.btn-blue]="false" (click)="wappnet_fab_button_Imported_Imported_Importedto_pdfnavigate249054(undefined, undefined, true, undefined, [], undefined, undefined)" widget-id="248419 to pdf wappnet_fab_button_Imported_Imported_Imported"> 
      <div class="btn-round-img" [style.background-image]="config.icons.pdf"></div> </button>
    </div>
   </div>
  </div>
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="15%" widget-id="248098 right placeholder div_Imported_Imported_Imported_Imported"></div>
 </div><ng-content widget-id="248038 rowWithNavigation content-wrapper_Imported_Imported_Imported"></ng-content><ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="origin248039" [cdkConnectedOverlayOpen]="isOpen248039" [cdkConnectedOverlayHasBackdrop]="true" (backdropClick)="isOpen248039 = false" [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'" [cdkConnectedOverlayPositions]="panelPositions" widget-id="248039 sharing panel div_Imported_Imported_Imported_Imported"> 
  <div attachment-point-for="content" [ngClass]="'dropdown'" *ngIf="true" fxLayout="column" fxLayoutAlign="space-between center" layout-id="246945"> <button class="btn-round fab-pointer-cursor" [class.btn-blue]="false" #origin248039="cdkOverlayOrigin" cdkOverlayOrigin="" (click)="callActions(undefined, undefined, true, 248039, [], undefined, undefined)" widget-id="248102 share wappnet_fab_button_Imported_Imported_Imported"> 
    <div class="btn-round-img" [style.background-image]="config.icons.share"></div> </button> 
   <div attachment-point-for="content" [ngClass]="'divider-box'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px" layout-id="246981" widget-id="248101 sharing buttons div_Imported_Imported_Imported_Imported"> <button class="btn-round fab-pointer-cursor" [class.btn-blue]="&quot;true&quot;" #origin248039="cdkOverlayOrigin" cdkOverlayOrigin="" (click)="callActions(undefined, undefined, true, 248039, [], undefined, undefined)" widget-id="248213 whatsapp wappnet_fab_button_Imported_Imported_Imported"> 
     <div class="btn-round-img" [style.background-image]="config.icons.share_whatsapp"></div> </button><button class="btn-round fab-pointer-cursor" [class.btn-blue]="&quot;true&quot;" #origin248039="cdkOverlayOrigin" cdkOverlayOrigin="" (click)="callActions(undefined, undefined, true, 248039, [], undefined, undefined)" widget-id="248212 mail wappnet_fab_button_Imported_Imported_Imported"> 
     <div class="btn-round-img" [style.background-image]="config.icons.share_mail"></div> </button> 
   </div> 
  </div> 
 </ng-template>
</div>