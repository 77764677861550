<div fxLayout="column" fxFlexFill class="popup-sign-up">
  <!-- Title header -->
  <div fxLayout="row" class="popup-sign-up-header">
    <span class="sign-up-title">Hinweis zur Datenspeicherung</span>
    <div (click)="close($event)" class="sign-up-close"></div>
  </div>

  <!-- Content -->
  <div fxLayout="column" fxLayoutAlign="start stretch" class="popup-content">
    <!-- Privacy policy -->
    <div class="sign-up-privacy-policy-panel" fxLayout="row" fxLayoutGap="3px">
      <span class="sign-up-privacy-policy-text"
        >Zum Speichern der Daten benötigen wir deine Einverständniserklärung zum
        Datenschutz… Alle notwendigen Informationen erhältst du hier…
        <span
          class="sign-up-privacy-policy-link"
          (click)="openPrivacyPolicy($event)"
          >Link.</span
        ></span
      >
    </div>

    <div class="error-sign" *ngIf="error">{{ error }}</div>

    <!-- Login/Register -->
    <form
      #loginForm="ngForm"
      (ngSubmit)="confirm()"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      style="flex: 1"
    >
      <!-- Consent -->
      <div class="sign-up-form-element sign-up-consent-panel" fxLayout="row">
        <span class="sign-up-consent-text">{{ consentText }}</span>
        <input
          type="checkbox"
          id="consent"
          name="consent"
          class="system-checkbox"
          [(ngModel)]="consentChecked"
          required
        />
      </div>

      <!-- Finalize text and confirmation -->
      <div
        *ngIf="isFinalize"
        fxLayout="column"
        fxLayoutGap="4px"
        class="sign-up-form-element"
      >
        <p class="finalize-message">
          Wenn du nach dem Klick auf „{{ this.buttonLabel }}“ noch einmal auf
          deine Daten zugreifen möchtest, gib hier ein Passwort zum Schutz
          deiner Daten ein:
        </p>
        <div fxLayout="row" class="sign-up-finalize-toggle-panel">
          <span class="sign-up-finalize-text">Erneuter Login gewünscht</span>
          <app-toggle
            class="sign-up-finalize-toggle"
            [(checked)]="wantToRegister"
            (checkedChange)="onWantToRegisterChange($event)"
          ></app-toggle>
        </div>
      </div>

      <!-- Password -->
      <div
        class="sign-up-form-element sign-up-password-element"
        fxLayout="column"
        *ngIf="wantToRegister || !isFinalize"
      >
        <label for="pass">Passwort</label>
        <input
          class="system-input"
          type="password"
          id="pass"
          name="password"
          [(ngModel)]="password"
          required
          placeholder="Passwort"
          (keyup)="validatePwd($event)"
        />
        <app-password-strenght-level
          class="sign-up-password-strenght-indicator"
          [colors]="passwordIndicatorColors"
          [labels]="passwordIndicatorLabels"
          [level]="passwordStrength.strengthLevel"
        ></app-password-strenght-level>
      </div>

      <!-- Confirm password -->
      <div
        class="sign-up-form-element sign-up-confirm-password-element"
        fxLayout="column"
        *ngIf="wantToRegister || !isFinalize"
      >
        <label for="passwordrptd">Passwort wiederholen</label>
        <input
          class="system-input"
          type="password"
          id="passrptd"
          name="passwordrptd"
          placeholder="Passwort wiederholen"
          [(ngModel)]="passwordrptd"
          required
        />
      </div>

      <!-- Submit -->
      <input
        type="submit"
        [value]="buttonLabel"
        [disabled]="isDisabled()"
        class="sign-up-form-element system-button"
      />
    </form>
  </div>
</div>
