export interface AuthResult {
  ok?: {};
  error?: string;
}

export interface AuthRequest {
  type: RegisterType;
  password: string;
  consent: boolean;
}

export enum RegisterType {
  SAVE,
  FINALIZE,
  FINALIZE_WITH_PASSWORD,
}
