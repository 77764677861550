/**
 * userAPI API
 * userAPI API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DataDTO } from '../model/dataDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ElasticsearchResourceService {

    protected basePath = 'https://localhost:8082';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * getDocument
     * 
     * @param documentId documentId
     * @param indexName indexName
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocumentUsingGET1(documentId: string, indexName: string, observe?: 'body', reportProgress?: boolean): Observable<DataDTO>;
    public getDocumentUsingGET1(documentId: string, indexName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DataDTO>>;
    public getDocumentUsingGET1(documentId: string, indexName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DataDTO>>;
    public getDocumentUsingGET1(documentId: string, indexName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling getDocumentUsingGET1.');
        }

        if (indexName === null || indexName === undefined) {
            throw new Error('Required parameter indexName was null or undefined when calling getDocumentUsingGET1.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (documentId !== undefined && documentId !== null) {
            queryParameters = queryParameters.set('documentId', <any>documentId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DataDTO>(`${this.basePath}/api/elasticsearch/${encodeURIComponent(String(indexName))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * startDocument
     * 
     * @param indexName indexName
     * @param initialDocument initialDocument
     * @param documentId documentId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public startDocumentUsingPOST(indexName: string, initialDocument: any, documentId?: string, observe?: 'body', reportProgress?: boolean): Observable<DataDTO>;
    public startDocumentUsingPOST(indexName: string, initialDocument: any, documentId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DataDTO>>;
    public startDocumentUsingPOST(indexName: string, initialDocument: any, documentId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DataDTO>>;
    public startDocumentUsingPOST(indexName: string, initialDocument: any, documentId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (indexName === null || indexName === undefined) {
            throw new Error('Required parameter indexName was null or undefined when calling startDocumentUsingPOST.');
        }

        if (initialDocument === null || initialDocument === undefined) {
            throw new Error('Required parameter initialDocument was null or undefined when calling startDocumentUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (documentId !== undefined && documentId !== null) {
            queryParameters = queryParameters.set('documentId', <any>documentId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<DataDTO>(`${this.basePath}/api/elasticsearch/${encodeURIComponent(String(indexName))}/create`,
            initialDocument,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateDocument
     * 
     * @param document document
     * @param documentId documentId
     * @param indexName indexName
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDocumentUsingPUT(document: any, documentId: string, indexName: string, observe?: 'body', reportProgress?: boolean): Observable<DataDTO>;
    public updateDocumentUsingPUT(document: any, documentId: string, indexName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DataDTO>>;
    public updateDocumentUsingPUT(document: any, documentId: string, indexName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DataDTO>>;
    public updateDocumentUsingPUT(document: any, documentId: string, indexName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (document === null || document === undefined) {
            throw new Error('Required parameter document was null or undefined when calling updateDocumentUsingPUT.');
        }

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling updateDocumentUsingPUT.');
        }

        if (indexName === null || indexName === undefined) {
            throw new Error('Required parameter indexName was null or undefined when calling updateDocumentUsingPUT.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (documentId !== undefined && documentId !== null) {
            queryParameters = queryParameters.set('documentId', <any>documentId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<DataDTO>(`${this.basePath}/api/elasticsearch/${encodeURIComponent(String(indexName))}/update`,
            document,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
