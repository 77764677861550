/**
 * userAPI API
 * userAPI API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface OrganisationDTO { 
    active: boolean;
    canInviteToTestApp?: boolean;
    id?: number;
    name: string;
    passwordResetEmailTemplate?: string;
    tokenIssuerUrl: string;
    uuid: string;
}
