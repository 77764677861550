<div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="grow" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="50px" layout-id="246932" widget-id="248022 layout_row div_Imported_Imported_Imported_Imported">
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="none start" fxLayoutGap="20px" layout-id="246966" widget-id="248066 contentrow div_Imported_Imported_Imported_Imported">
  <div attachment-point-for="content" [ngClass]="''" *ngIf="true" fxFlex="35%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px" layout-id="246995" widget-id="248167 leftpanel div_Imported_Imported_Imported_Imported">
   <div class="step-header brand-radius brand-shadow brand-gradient" [class.brand-gradient]="true" (click)="callActions(undefined, undefined, true, undefined, [], undefined, undefined)" widget-id="248257 step wappnet_step_header_Imported_Imported_Imported"> 
    <div class="step-title">
     {{config.themenfinder.lebenssituation.step_header}}
    </div> 
    <div class="step-number">
     {{config.themenfinder.lebenssituation.step_counter}}
    </div> 
   </div>
   <div attachment-point-for="content" [ngClass]="'brand-offset-small-inner brand-radius brand-shadow-inset'" *ngIf="true" widget-id="248258 content div_Imported_Imported_Imported_Imported">
    <article fxFlex="100%" widget-id="248388 wappnet_text-with-header_Imported_Imported_Imported"> 
     <header>
      {{config.themenfinder.lebenssituation.paragraph_header2}}
     </header> 
     <p>{{config.themenfinder.lebenssituation.paragraph_content2}}</p> 
    </article>
   </div>
  </div>
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="65%" fxLayout="row wrap" fxLayoutAlign="space-between start" fxLayoutGap="" layout-id="246996" widget-id="248168 rightpanel div_Imported_Imported_Imported_Imported">
   <div class="extendable-panel brand-shadow" *ngIf="model.themenfinder.lebenssituation1.active==1" [class.is-highlighted]="false" fxFlex="48%" widget-id="248265 situation1 wappnet_extendable_panel_Imported_Imported_Imported"> 
    <div class="extendable-panel-header" [class.brand-gradient]="model.themenfinder.lebenssituation1.chosen" (click)="wappnet_extendable_panel_Imported_Imported_Importedsituation1headerClick248970(undefined, 'model.themenfinder.lebenssituation1.expanded', false, undefined, [], undefined, undefined)"> 
     <div class="extendable-panel-title">
      Paar / Familie
     </div> 
     <div class="extendable-panel-trigger"> 
      <div class="extendable-panel-icon" [style.background-image]="config.icons.burger" *ngIf="!model.themenfinder.lebenssituation1.expanded"></div> 
      <div class="extendable-panel-icon" *ngIf="model.themenfinder.lebenssituation1.expanded" [style.background-image]="config.icons.close"></div> 
     </div> 
    </div> 
    <div class="extendable-panel-body" attachment-point-for="panelContent" *ngIf="model.themenfinder.lebenssituation1.expanded">
     <app-lebenssituationdetailspanel attachment-point-for="content" [model]="model.themenfinder.lebenssituation1" [showHiddenOnly]="false" [config]="config" widget-id="248401 wappnet-lebenssituation-details-panel_Imported_Imported_Imported"></app-lebenssituationdetailspanel>
    </div> 
   </div>
   <div class="extendable-panel brand-shadow" *ngIf="model.themenfinder.lebenssituation2.active==1" [class.is-highlighted]="false" fxFlex="48%" widget-id="248261 situation2 wappnet_extendable_panel_Imported_Imported_Imported"> 
    <div class="extendable-panel-header" [class.brand-gradient]="model.themenfinder.lebenssituation2.chosen" (click)="wappnet_extendable_panel_Imported_Imported_Importedsituation2headerClick248966(undefined, 'model.themenfinder.lebenssituation2.expanded', false, undefined, [], undefined, undefined)"> 
     <div class="extendable-panel-title">
      Angestellte*r
     </div> 
     <div class="extendable-panel-trigger"> 
      <div class="extendable-panel-icon" [style.background-image]="config.icons.burger" *ngIf="!model.themenfinder.lebenssituation2.expanded"></div> 
      <div class="extendable-panel-icon" *ngIf="model.themenfinder.lebenssituation2.expanded" [style.background-image]="config.icons.close"></div> 
     </div> 
    </div> 
    <div class="extendable-panel-body" attachment-point-for="panelContent" *ngIf="model.themenfinder.lebenssituation2.expanded">
     <app-lebenssituationdetailspanel attachment-point-for="content" [model]="model.themenfinder.lebenssituation2" [showHiddenOnly]="false" [config]="config" widget-id="248397 wappnet-lebenssituation-details-panel_Imported_Imported_Imported"></app-lebenssituationdetailspanel>
    </div> 
   </div>
   <div class="extendable-panel brand-shadow" *ngIf="model.themenfinder.lebenssituation3.active==1" [class.is-highlighted]="false" fxFlex="48%" widget-id="248264 situation3 wappnet_extendable_panel_Imported_Imported_Imported"> 
    <div class="extendable-panel-header" [class.brand-gradient]="model.themenfinder.lebenssituation3.chosen" (click)="wappnet_extendable_panel_Imported_Imported_Importedsituation3headerClick248969(undefined, 'model.themenfinder.lebenssituation3.expanded', false, undefined, [], undefined, undefined)"> 
     <div class="extendable-panel-title">
      Schüler*In, Azubi, Student*In
     </div> 
     <div class="extendable-panel-trigger"> 
      <div class="extendable-panel-icon" [style.background-image]="config.icons.burger" *ngIf="!model.themenfinder.lebenssituation3.expanded"></div> 
      <div class="extendable-panel-icon" *ngIf="model.themenfinder.lebenssituation3.expanded" [style.background-image]="config.icons.close"></div> 
     </div> 
    </div> 
    <div class="extendable-panel-body" attachment-point-for="panelContent" *ngIf="model.themenfinder.lebenssituation3.expanded">
     <app-lebenssituationdetailspanel attachment-point-for="content" [model]="model.themenfinder.lebenssituation3" [showHiddenOnly]="false" [config]="config" widget-id="248400 wappnet-lebenssituation-details-panel_Imported_Imported_Imported"></app-lebenssituationdetailspanel>
    </div> 
   </div>
   <div class="extendable-panel brand-shadow" *ngIf="model.themenfinder.lebenssituation4.active==1" [class.is-highlighted]="false" fxFlex="48%" widget-id="248260 situation4 wappnet_extendable_panel_Imported_Imported_Imported"> 
    <div class="extendable-panel-header" [class.brand-gradient]="model.themenfinder.lebenssituation4.chosen" (click)="wappnet_extendable_panel_Imported_Imported_Importedsituation4headerClick248965(undefined, 'model.themenfinder.lebenssituation4.expanded', false, undefined, [], undefined, undefined)"> 
     <div class="extendable-panel-title">
      Single
     </div> 
     <div class="extendable-panel-trigger"> 
      <div class="extendable-panel-icon" [style.background-image]="config.icons.burger" *ngIf="!model.themenfinder.lebenssituation4.expanded"></div> 
      <div class="extendable-panel-icon" *ngIf="model.themenfinder.lebenssituation4.expanded" [style.background-image]="config.icons.close"></div> 
     </div> 
    </div> 
    <div class="extendable-panel-body" attachment-point-for="panelContent" *ngIf="model.themenfinder.lebenssituation4.expanded">
     <app-lebenssituationdetailspanel attachment-point-for="content" [model]="model.themenfinder.lebenssituation4" [showHiddenOnly]="false" [config]="config" widget-id="248396 wappnet-lebenssituation-details-panel_Imported_Imported_Imported"></app-lebenssituationdetailspanel>
    </div> 
   </div>
   <div class="extendable-panel brand-shadow" *ngIf="model.themenfinder.lebenssituation5.active==1" [class.is-highlighted]="false" fxFlex="48%" widget-id="248262 situation5 wappnet_extendable_panel_Imported_Imported_Imported"> 
    <div class="extendable-panel-header" [class.brand-gradient]="model.themenfinder.lebenssituation5.chosen" (click)="wappnet_extendable_panel_Imported_Imported_Importedsituation5headerClick248967(undefined, 'model.themenfinder.lebenssituation5.expanded', false, undefined, [], undefined, undefined)"> 
     <div class="extendable-panel-title">
      Unternehmer*In
     </div> 
     <div class="extendable-panel-trigger"> 
      <div class="extendable-panel-icon" [style.background-image]="config.icons.burger" *ngIf="!model.themenfinder.lebenssituation5.expanded"></div> 
      <div class="extendable-panel-icon" *ngIf="model.themenfinder.lebenssituation5.expanded" [style.background-image]="config.icons.close"></div> 
     </div> 
    </div> 
    <div class="extendable-panel-body" attachment-point-for="panelContent" *ngIf="model.themenfinder.lebenssituation5.expanded">
     <app-lebenssituationdetailspanel attachment-point-for="content" [model]="model.themenfinder.lebenssituation5" [showHiddenOnly]="false" [config]="config" widget-id="248398 wappnet-lebenssituation-details-panel_Imported_Imported_Imported"></app-lebenssituationdetailspanel>
    </div> 
   </div>
   <div class="extendable-panel brand-shadow" *ngIf="model.themenfinder.lebenssituation6.active==1" [class.is-highlighted]="false" fxFlex="48%" widget-id="248266 situation6 wappnet_extendable_panel_Imported_Imported_Imported"> 
    <div class="extendable-panel-header" [class.brand-gradient]="model.themenfinder.lebenssituation6.chosen" (click)="wappnet_extendable_panel_Imported_Imported_Importedsituation6headerClick248971(undefined, 'model.themenfinder.lebenssituation6.expanded', false, undefined, [], undefined, undefined)"> 
     <div class="extendable-panel-title">
      Rentner*In, Pensionär*In
     </div> 
     <div class="extendable-panel-trigger"> 
      <div class="extendable-panel-icon" [style.background-image]="config.icons.burger" *ngIf="!model.themenfinder.lebenssituation6.expanded"></div> 
      <div class="extendable-panel-icon" *ngIf="model.themenfinder.lebenssituation6.expanded" [style.background-image]="config.icons.close"></div> 
     </div> 
    </div> 
    <div class="extendable-panel-body" attachment-point-for="panelContent" *ngIf="model.themenfinder.lebenssituation6.expanded">
     <app-lebenssituationdetailspanel attachment-point-for="content" [model]="model.themenfinder.lebenssituation6" [showHiddenOnly]="false" [config]="config" widget-id="248402 wappnet-lebenssituation-details-panel_Imported_Imported_Imported"></app-lebenssituationdetailspanel>
    </div> 
   </div>
   <div class="extendable-panel brand-shadow" *ngIf="model.themenfinder.lebenssituation7.active==1" [class.is-highlighted]="false" fxFlex="48%" widget-id="248263 situation7 wappnet_extendable_panel_Imported_Imported_Imported"> 
    <div class="extendable-panel-header" [class.brand-gradient]="model.themenfinder.lebenssituation7.chosen" (click)="wappnet_extendable_panel_Imported_Imported_Importedsituation7headerClick248968(undefined, 'model.themenfinder.lebenssituation7.expanded', false, undefined, [], undefined, undefined)"> 
     <div class="extendable-panel-title">
      Arbeitssuchend
     </div> 
     <div class="extendable-panel-trigger"> 
      <div class="extendable-panel-icon" [style.background-image]="config.icons.burger" *ngIf="!model.themenfinder.lebenssituation7.expanded"></div> 
      <div class="extendable-panel-icon" *ngIf="model.themenfinder.lebenssituation7.expanded" [style.background-image]="config.icons.close"></div> 
     </div> 
    </div> 
    <div class="extendable-panel-body" attachment-point-for="panelContent" *ngIf="model.themenfinder.lebenssituation7.expanded">
     <app-lebenssituationdetailspanel attachment-point-for="content" [model]="model.themenfinder.lebenssituation7" [showHiddenOnly]="false" [config]="config" widget-id="248399 wappnet-lebenssituation-details-panel_Imported_Imported_Imported"></app-lebenssituationdetailspanel>
    </div> 
   </div>
  </div>
 </div>
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="10px" layout-id="246965" widget-id="248065 buttons_row div_Imported_Imported_Imported_Imported">
  <button class="btn-round fab-pointer-cursor" [class.btn-blue]="false" (click)="callActions('themenfinderlebenssituationstep1', undefined, true, undefined, [], undefined, undefined)" widget-id="248164 prev_page wappnet_fab_button_Imported_Imported_Imported"> 
   <div class="btn-round-img" [style.background-image]="config.icons.arrow_left"></div> </button><button class="btn-round temperature-icon fab-pointer-cursor" (click)="callActions('themenfinderrisikotemperatur', undefined, true, undefined, [], undefined, undefined)" widget-id="248166 wappnet_temperature_indicator_Imported_Imported_Imported"> 
   <div class="temperature-icon-peak"> 
    <div class="temperature-icon-peak-background"></div> 
   </div> 
   <div class="btn-round-img" [style.background-image]="model.themenfinder.risikotemperatur == 0 ? config.icons.temperature_low : (model.themenfinder.risikotemperatur == 1 ? config.icons.temperature : config.icons.temperature_high)"></div> </button><button class="btn-round fab-pointer-cursor" [class.btn-blue]="false" (click)="callActions('themenfinderlebenssituationstep3', undefined, true, undefined, [], undefined, undefined)" widget-id="248165 next_page wappnet_fab_button_Imported_Imported_Imported"> 
   <div class="btn-round-img" [style.background-image]="config.icons.arrow_right"></div> </button>
 </div>
</div>