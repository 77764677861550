import { Event, NavigationEnd, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, mergeMap } from 'rxjs/operators'
import { Observable } from 'rxjs';
import { DataAccessService } from './utils/data-access-service';
import { ConfigAccessService } from './utils/config-access-service';
import { ListsMergeService } from './utils/lists-merge.service';
import { UserStatusDTO } from './usersdk';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectionPositionPair } from '@angular/cdk/overlay';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = "w3";

  public config: any;
  public model: any;
  public status: UserStatusDTO;

   @Input()
 public showHiddenOnly: boolean = false;

 public get isProd() {
   return true;
 }


  constructor(private route: ActivatedRoute, private router: Router, private titleService: Title,
    private listsMerge: ListsMergeService, private dataAccessService: DataAccessService,
    private configAccessService : ConfigAccessService) { };

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) route = route.firstChild
        return route
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe(data => {
      let pageTitle = (typeof data['pageName'] !== "undefined") ? (' | ' + data['pageName']) : ''
      this.titleService.setTitle(this.title + pageTitle)
    })

    var dataObservable: Observable<[any, any, UserStatusDTO]> = this.dataAccessService.resolve(null, null) as Observable<[any, any, UserStatusDTO]>;
    var configObservable: Observable<any> = this.configAccessService.resolve(null, null) as Observable<any>;

    this.dataAccessService.getStatusObservable().subscribe(status => {
      this.status = status;
    })

    dataObservable.subscribe(result => {
      this.model = result[0];
      this.config = result[1];
      this.status = result[2];
    });

    configObservable.subscribe(result => {
      this.config = result;
    });
  }

   public panelPositions: ConnectionPositionPair[] = [];

 private defaultPanelPositions: ConnectionPositionPair[] = [{ "originX": "start", "originY": "bottom", "overlayX": "start", "overlayY": "top" },
  { "originX": "start", "originY": "top", "overlayX": "start", "overlayY": "bottom" },
  { "originX": "end", "originY": "top", "overlayX": "end", "overlayY": "bottom" },
  { "originX": "end", "originY": "bottom", "overlayX": "end", "overlayY": "top" }];

 private openPanel(widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[]) {
    if (panelPositions && panelPositions.length > 0) {
      this.panelPositions = panelPositions.concat(this.defaultPanelPositions);
    } else {
      this.panelPositions = this.defaultPanelPositions;
    }
    this['isOpen' + widgetToOpenAsPanelId] = !this['isOpen' + widgetToOpenAsPanelId];
  }


   callActions(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any,
              eventToSend : string) {
        this.tweakModelField(pathToBooleanFieldToTweak, item);
    if (saveModel) {
      this.dataAccessService.saveDocument();
    }
    if (eventToSend) {
      this.dataAccessService.sendEvent(eventToSend);
    }

    if (pathToNavigate) {
      this.router.navigate(pathToNavigate.split('/'));
    }
    if(widgetToOpenAsPanelId) {
      this.openPanel(widgetToOpenAsPanelId, panelPositions);
    }
  }


    private tweakModelField(pathToBooleanFieldToTweak: string, item: any) {
      if (pathToBooleanFieldToTweak) {
        var pathParts = pathToBooleanFieldToTweak.split('.');
        var pathLength = pathParts.length;
        if (pathLength < 2) {
          console.warn("Path to tweak to short: " + pathToBooleanFieldToTweak);
          return;
        }
        var modelToSet = {};
        if (pathParts[0] == 'item') {
          modelToSet = item;
        } else if (pathParts[0] == 'model') {
          modelToSet = this.model;
        }
        for (var i = 1; i < pathLength - 1; i++) {
          modelToSet = modelToSet[pathParts[i]];
        }
        var currentValue = modelToSet[pathParts[pathLength - 1]];
        if (currentValue) {
          modelToSet[pathParts[pathLength - 1]] = false;
        } else {
          modelToSet[pathParts[pathLength - 1]] = true;
        }
      }
   }


  public div_Imported_Imported_Imported_Importedmenu_positions_tbeonClick249050(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.appState.themenfinderVisited=true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}


public div_Imported_Imported_Imported_Importedmenu_positions_tbeonClick249051(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.appState.themenfinderVisited=true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}






public wappnet_menu_Imported_Imported_Imported_ImportedThemenfindershowPage249075(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.appState.themenfinderVisited=true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}


public wappnet_menu_Imported_Imported_Imported_ImportedGeldgef_hleshowPage249076(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.appState.geldgefuehleVisited=true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}


public wappnet_menu_Imported_Imported_Imported_ImportedBeratungsidealshowPage249077(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.appState.beratungsidealVisited=true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}






















}
