import { AuthService } from './auth.service';
import { OnboardingComponent } from '../components/common/onboarding/onboarding.component';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { forkJoin, Observable, of, Subscriber } from 'rxjs';
import { map, concatAll, concatMap, tap, mergeMap } from 'rxjs/operators';
import {
  ActivatedApplicationResourceService,
  DataDTO,
  EndUserResourceService,
  StringWrapperDTO,
  UserStatusDTO,
  ValueWrapperDTOOfstring,
} from '../usersdk';
import { DataCreationService } from './data-creation-service';
import { ObjectsMergeService } from './objects-merge.service';
import { DialogService } from './dialog/dialog.service';
import { BlockerPopupComponent } from '../components/common/blocker-popup/blocker-popup.component';

@Injectable({
  providedIn: 'root',
})
export class DataAccessService implements Resolve<[any, any, UserStatusDTO]> {
  private data: any;
  private config: any;
  private userStatus: UserStatusDTO;
  private isAdvisor: boolean = false;
  private dataLoaded: boolean = false;
  private subscribers: Subscriber<any>[] = [];
  private statusSubscribers: Subscriber<UserStatusDTO>[] = [];
  private loadingData: boolean = false;

  private readonly SESSION_TOKEN: string = 'session_token';
  private readonly USER_KEY: string = 'user_key';
  private readonly ADVISOR_KEY: string = 'advisor_key';

  constructor(
    private activatedApplicationService: ActivatedApplicationResourceService,
    private dataCreator: DataCreationService,
    private dataMerger: ObjectsMergeService,
    private dialogService: DialogService,
    private endUserService: EndUserResourceService,
    private authService: AuthService
  ) {
    this.authService.userStatusObservable.subscribe(
      (res) => (this.userStatus = res)
    );
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | [any, any, UserStatusDTO]
    | Observable<[any, any, UserStatusDTO]>
    | Promise<[any, any, UserStatusDTO]> {
    if (this.dataLoaded) {
      return of([this.data, this.config, this.userStatus]);
    }
    var result = new Observable<[any, any, UserStatusDTO]>((sub) => {
      this.subscribers.push(sub);
      this.startLoadingData(route);
    });
    return result;
  }

  private startLoadingData(route: ActivatedRouteSnapshot) {
    if (route && this.loadingData == false) {
      this.loadingData = true;
      this.saveAccessKeysInSessionStorage(route);
      this.appInitObservable()
        .pipe(
          concatMap(() => {
            return this.loginObservable();
          }),
          concatMap((loginDone) => {
            if (loginDone) {
              return this.getDataObservable();
            }
            return of(undefined);
          }),
          concatMap((userData) => {
            return this.onboardingObservable(userData);
          })
        )
        .subscribe((userData) => {
          this.loadingData = false;
          if (userData) {
            this.dataLoaded = true;
            this.data = userData;
            for (let i = 0; i < this.subscribers.length; i++) {
              this.subscribers[i].next([
                this.data,
                this.config,
                this.userStatus,
              ]);
              this.subscribers[i].complete();
            }
          }
        });
    }
  }

  public saveDocument(): void {
    if (!this.data) {
      return;
    }
    if (this.isAdvisor) {
      return;
    }
    if (!this.userStatus.consentGranted) {
      return;
    }
    var sessionToken = this.getSessionToken();
    var userKey = this.getUserKey();
    this.endUserService
      .updateUserDataUsingPUT(this.data, sessionToken, userKey)
      .subscribe();
  }

  public print() {
    var sessionToken = this.getSessionToken();
    var userKey = this.getUserKey();
    var advisorKey = this.getAdvisorKey();
    this.endUserService
      .getDocumentPrintUsingGET(advisorKey, sessionToken, userKey)
      .subscribe((data) => {
        let byteArr: string = data.content;
        let url = this.getFileURL(byteArr, 'application/pdf;base64');
        this.download(url, data.name);
      });
  }

  public register() {
    return this.authService
      .register(this.config, this.data, this.userStatus)
      .pipe(
        tap((res) => {
          this.refreshStatus();
        })
      );
  }

  private download(url: string, name: string) {
    var link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.click();
  }

  private getFileURL(byteArr: string, mimeType: string): string {
    const byteCharacters = atob(byteArr);
    const byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], { type: mimeType });
    return URL.createObjectURL(file);
  }

  public sendEvent(eventName: string) {
    if (this.isAdvisor) {
      return;
    }
    var sessionToken = this.getSessionToken();
    var userKey = this.getUserKey();
    var event: StringWrapperDTO = { content: eventName };
    this.activatedApplicationService
      .logBusinessEventUsingPOST(event, sessionToken, userKey)
      .subscribe();
  }

  getStatusObservable(): Observable<UserStatusDTO> {
    return new Observable<UserStatusDTO>((sub) => {
      this.statusSubscribers.push(sub);
    });
  }

  refreshStatus() {
    const userKey = this.getUserKey();
    const sessionToken = this.getSessionToken();
    this.endUserService
      .getStatusUsingGET(sessionToken, userKey)
      .subscribe((status) => {
        this.userStatus = status;
        this.statusSubscribers = this.statusSubscribers.filter(
          (sub) => !sub.closed
        );
        for (let i = 0; i < this.statusSubscribers.length; i++) {
          this.statusSubscribers[i].next(this.userStatus);
        }
      });
  }

  getConfigObservable(token: string): Observable<any> {
    return this.activatedApplicationService
      .getConfigDataUsingGET(token)
      .pipe(map((result) => JSON.parse(result.content)));
  }

  getDataObservable(): Observable<any> {
    var sessionToken = this.getSessionToken();
    var userKey = this.getUserKey();
    var emptyModel = this.dataCreator.getNewObject();
    return this.endUserService
      .getUserDataUsingGET(this.getAdvisorKey(), sessionToken, userKey)
      .pipe(
        map((result) => {
          if (result && result.content) {
            var userData = JSON.parse(result.content);
            var fullData = emptyModel;
            var mergedData = this.dataMerger.merge(fullData, userData);
            return of(mergedData);
          } else {
            if (
              (sessionToken || userKey) &&
              result.messageKey === 'dataset_not_found'
            ) {
              return this.endUserService
                .createUserDataUsingPOST(
                  emptyModel,
                  this.getSessionToken(),
                  this.getUserKey()
                )
                .pipe(
                  map((result) => {
                    if (result.content) {
                      return JSON.parse(result.content);
                    } else {
                      return this.showBlockerPopup(result.messageKey);
                    }
                  })
                );
            } else {
              // Can't write (in this case initialise) user data when:
              // you are advisor or data is already initialized
              return this.showBlockerPopup(result.messageKey);
            }
          }
        }),
        concatAll()
      );
  }

  public appInitObservable(): Observable<DataDTO> {
    const userKey = sessionStorage.getItem(this.USER_KEY);
    const advisorKey = sessionStorage.getItem(this.ADVISOR_KEY);
    const sessionToken = this.getSessionToken();

    let getConfig: Observable<any>;
    let getCss: Observable<ValueWrapperDTOOfstring>;
    let getStatus: Observable<UserStatusDTO>;

    if (!userKey && !advisorKey && !sessionToken) {
      this.getCssObservable(undefined, undefined, undefined).
        subscribe(() => this.showBlockerPopup("use-link-warning"));
      return of();
    }

    if (this.config) {
      getConfig = of(this.config);
      getCss = of(undefined);
      getStatus = of(this.userStatus);
    } else {
      getConfig = this.activatedApplicationService.getConfigDataUsingGET(advisorKey, sessionToken, userKey).pipe(
        concatMap((data) => { return of(JSON.parse(data.content)) })
      ).pipe(tap((data) => {
        this.config = data;
      }));
      var defaultStatus: UserStatusDTO = { campaignValid: true, consentGranted: true, credentialsSet: false };
      getCss = this.getCssObservable(advisorKey, sessionToken, userKey);
      getStatus = of(defaultStatus);
      if (userKey || sessionToken) {
        getStatus = this.endUserService.getStatusUsingGET(
          sessionToken,
          userKey
        );
      }
      getStatus = getStatus.pipe(tap((status) => (this.userStatus = status)));
    }

    if (advisorKey) {
      this.isAdvisor = true;
    }
    return forkJoin([getConfig, getCss, getStatus]).pipe(
      concatMap((result) => {
        return of(result[0]);
      })
    );
  }

  private getCssObservable(advisorKey: any, sessionToken: any, userKey: any): Observable<ValueWrapperDTOOfstring> {
    return this.activatedApplicationService.getCssUsingGET(advisorKey, sessionToken, userKey).pipe(tap((result) => {
      var sheet = document.createElement('style');
      sheet.innerHTML = result.value;
      document.getElementsByTagName('head')[0].appendChild(sheet);
    }));
  }

  private loginObservable(): Observable<boolean> {
    var sessionToken = this.getSessionToken();
    if (sessionToken) {
      return of(true);
    }
    if (!this.config) {
      return of(true);
    }
    if (!this.userStatus.credentialsSet) {
      return of(true);
    }
    const userKey = this.getUserKey();
    if (userKey) {
      return this.authService.login(userKey, this.config, this.userStatus);
    } else {
      //at the moment doing nothing because setting up a password
      //doesn't take place anymore at the beginning of the process
      //it can be an advisor as well, who doesn't require login
      return of(true);
    }
  }

  private onboardingObservable(userData: any): Observable<any> {
    if (
      !userData ||
      this.isAdvisor ||
      !this.userStatus ||
      (this.userStatus && !this.userStatus.campaignValid)
    ) {
      return of(userData); //return anything and let the process roll
    }

    var showOnboarding = true && !this.userStatus.consentGranted;
    var showWelcomeBack = true && this.userStatus.consentGranted;

    if (!showOnboarding && !showWelcomeBack) {
      return of(userData);
    }

    this.dialogService
      .open(OnboardingComponent, {
        disableClose: false,
        data: {
          config: this.config ?? {},
          showOnboarding: showOnboarding,
          showWelcomeBack: showWelcomeBack,
        },
        panelClass: 'onboarding-panel',
        backdropClass: 'onboarding-backdrop',
      })
      .afterClosed()
      .subscribe();
    return of(userData);
  }

  private showBlockerPopup(message: string): Observable<string> {
    return this.dialogService
      .open(BlockerPopupComponent, {
        disableClose: true,
        data: { message: message },
      })
      .afterClosed();
  }

  //TODO: try to remove
  public setSessionToken(sessionToken: string) {
    if (sessionToken) {
      sessionStorage.removeItem(this.USER_KEY);
      sessionStorage.setItem(this.SESSION_TOKEN, sessionToken);
    } else {
      sessionStorage.removeItem(this.SESSION_TOKEN);
    }
  }

  private setSessionStorageItem(key: string, value: string) {
    if (value) {
      sessionStorage.setItem(key, value);
    } else {
      sessionStorage.removeItem(key);
    }
  }

  private getSessionToken() {
    return sessionStorage.getItem(this.SESSION_TOKEN);
  }

  private getUserKey() {
    return sessionStorage.getItem(this.USER_KEY);
  }

  private getAdvisorKey() {
    return sessionStorage.getItem(this.ADVISOR_KEY);
  }

  public saveAccessKeysInSessionStorage(route: ActivatedRouteSnapshot) {
    const userKey = route.queryParams.key;
    const advisorKey = route.queryParams.advisor_key;
    if (userKey) {
      this.setSessionStorageItem(this.USER_KEY, userKey);
    }
    if (advisorKey) {
      this.setSessionStorageItem(this.ADVISOR_KEY, advisorKey);
    }
  }
}
