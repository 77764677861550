/**
 * userAPI API
 * userAPI API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface EndUserDTO { 
    advisorKey?: string;
    applicationUrl?: string;
    campaignId?: number;
    completedDataset?: boolean;
    declaredConsent?: boolean;
    email?: string;
    endUserCredentialsId?: number;
    firstName?: string;
    id?: number;
    lastName?: string;
    mailSentAt?: Date;
    organisationId?: number;
    status?: EndUserDTO.StatusEnum;
    userDataId?: string;
}
export namespace EndUserDTO {
    export type StatusEnum = 'OPEN' | 'SENT' | 'COMPLETED';
    export const StatusEnum = {
        OPEN: 'OPEN' as StatusEnum,
        SENT: 'SENT' as StatusEnum,
        COMPLETED: 'COMPLETED' as StatusEnum
    };
}
