<div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="40px" layout-id="246923" widget-id="248010 root div_Imported_Imported_Imported_Imported">
 <div class="article-img" [style.background-image]="config.beratungsideal.landingPage.imageUrl" fxFlex="60%" widget-id="248046 image wappnet_image_container_Imported_Imported_Imported"> 
 </div>
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="40%" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="10px" layout-id="246947" widget-id="248045 content column div_Imported_Imported_Imported_Imported">
  <div attachment-point-for="content" [ngClass]="'brand-offset-inner brand-radius brand-shadow'" *ngIf="true" fxFlex="grow" widget-id="248120 text frame div_Imported_Imported_Imported_Imported">
   <article widget-id="248217 wappnet_text-with-header_Imported_Imported_Imported"> 
    <header>
     {{config.beratungsideal.landingPage.header}}
    </header> 
    <p>{{config.beratungsideal.landingPage.paragraph1}}</p> 
   </article>
   <p widget-id="248218 wappnet_paragraph_Imported_Imported_Imported">{{config.beratungsideal.landingPage.paragraph2}}</p>
  </div>
  <div class="btn-main" (click)="callActions('beratungsidealsteps', undefined, true, undefined, [], undefined, undefined)" widget-id="248121 navigation to steps wappnet_nav_button_Imported_Imported_Imported">
    {{config.beratungsideal.landingPage.nav_button_text}} 
  </div>
 </div>
</div>