<div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="grow" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="246934" widget-id="248024 root div_Imported_Imported_Imported_Imported">
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="grow" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="246970" widget-id="248070 content space div_Imported_Imported_Imported_Imported">
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="33%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px" layout-id="247000" widget-id="248178 first column div_Imported_Imported_Imported_Imported">
   <div class="step-header brand-radius brand-shadow brand-gradient" [class.brand-gradient]="true" (click)="callActions(undefined, undefined, true, undefined, [], undefined, undefined)" widget-id="248273 step header wappnet_step_header_Imported_Imported_Imported"> 
    <div class="step-title">
     {{config.beratungsideal.steps.step1_title}}
    </div> 
    <div class="step-number">
     {{config.beratungsideal.steps.step1_counter}}
    </div> 
   </div>
   <div attachment-point-for="content" [ngClass]="'brand-offset-small-inner brand-radius brand-shadow-inset'" *ngIf="true" widget-id="248274 text panel div_Imported_Imported_Imported_Imported">
    <article fxFlex="100%" widget-id="248406 text content wappnet_text-with-header_Imported_Imported_Imported"> 
     <header>
      {{config.beratungsideal.beratungswunschzettel.header}}
     </header> 
     <p>{{config.beratungsideal.beratungswunschzettel.paragraph}}</p> 
    </article>
   </div>
  </div>
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="66%" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="45px" layout-id="247001" widget-id="248179 second column div_Imported_Imported_Imported_Imported">
   <ng-template let-i="index" let-count="count" let-isFirst="first" let-isLast="last" let-isEven="even" let-isOdd="odd" let-item [ngForOf]="list" ngFor widget-id="248275 slider wrapper div_Imported_Imported_Imported_Imported">
    <div attachment-point-for="content" [ngClass]="''" *ngIf="true" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="10px" layout-id="247018">
     <div attachment-point-for="content" [ngClass]="'debug slider-options-wrapper'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-between center" layout-id="247022" widget-id="248407 texts div_Imported_Imported_Imported_Imported">
      <p [class.is-selected]="item.data.value === 0" widget-id="248455 text1 bottom wappnet_paragraph_Imported_Imported_Imported">{{item.config.text_bottom}}</p>
      <p [class.is-selected]="item.data.value === 1" widget-id="248456 text_middle wappnet_paragraph_Imported_Imported_Imported">beides</p>
      <p [class.is-selected]="item.data.value === 2" widget-id="248454 text1 upper wappnet_paragraph_Imported_Imported_Imported">{{item.config.text_upper}}</p>
     </div>
     <div class="horizontal-slider-wrapper" widget-id="248408 slider wappnet_slider_vertical_Imported_Imported_Imported"> 
      <div class="horizontal-slider-with-tooltip"> 
       <div class="horizontal-slider mirror-gradient"> 
        <input type="range" min="0" max="2" [(ngModel)]="item.data.value"> 
       </div> 
      </div> 
      <div class="horizontal-tooltip-labels" *ngIf="false"> 
       <div class="tooltip-background orange-one" [class.is-active]="item.data.value === 0"> <span class="tooltip brand-shadow brand-radius-small"> <span class="tooltip-arrow position-top"> <span class="tooltip-arrow-shape"></span> </span> VOLL &amp; GANZ </span> 
       </div> 
       <div class="tooltip-background orange-three" [class.is-active]="item.data.value === 1"> <span class="tooltip brand-shadow brand-radius-small"> <span class="tooltip-arrow position-top"> <span class="tooltip-arrow-shape"></span> </span> SOWOHL ALS AUCH </span> 
       </div> 
       <div class="tooltip-background orange-one" [class.is-active]="item.data.value === 2"> <span class="tooltip brand-shadow brand-radius-small"> <span class="tooltip-arrow position-top"> <span class="tooltip-arrow-shape"></span> </span> VOLL &amp; GANZ </span> 
       </div> 
      </div> 
     </div>
    </div>
   </ng-template>
  </div>
 </div>
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="20px" layout-id="246969" widget-id="248069 navigation div_Imported_Imported_Imported_Imported">
  <button class="btn-round fab-pointer-cursor" [class.btn-blue]="false" (click)="callActions('beratungsidealsteps', undefined, true, undefined, [], undefined, undefined)" widget-id="248175 back wappnet_fab_button_Imported_Imported_Imported"> 
   <div class="btn-round-img" [style.background-image]="config.icons.arrow_left"></div> </button><button class="btn-round temperature-icon fab-pointer-cursor" (click)="callActions('themenfinderrisikotemperatur', undefined, true, undefined, [], undefined, undefined)" widget-id="248177 wappnet_temperature_indicator_Imported_Imported_Imported"> 
   <div class="temperature-icon-peak"> 
    <div class="temperature-icon-peak-background"></div> 
   </div> 
   <div class="btn-round-img" [style.background-image]="model.themenfinder.risikotemperatur == 0 ? config.icons.temperature_low : (model.themenfinder.risikotemperatur == 1 ? config.icons.temperature : config.icons.temperature_high)"></div> </button><button class="btn-round fab-pointer-cursor" [class.btn-blue]="false" (click)="wappnet_fab_button_Imported_Imported_Importedforwardnavigate248940('beratungsidealformular', undefined, true, undefined, [], undefined, undefined)" widget-id="248176 forward wappnet_fab_button_Imported_Imported_Imported"> 
   <div class="btn-round-img" [style.background-image]="config.icons.arrow_right"></div> </button>
 </div>
</div>