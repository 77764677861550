<app-glueckwunschtemplate attachment-point-for="content" fxFlex="grow" widget-id="248013  wappnet-glueckwunsch-template_Imported_Imported_Imported">
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="" layout-id="246951" widget-id="248051 navigation div_Imported_Imported_Imported_Imported">
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="33%" fxLayout="row" fxLayoutAlign="start center" layout-id="246908" widget-id="248131 left div_Imported_Imported_Imported_Imported">
   <button class="btn-round fab-pointer-cursor" [class.btn-blue]="false" (click)="callActions('geldgefuhleglaubenssatze', undefined, true, undefined, [], undefined, undefined)" widget-id="248227 back wappnet_fab_button_Imported_Imported_Imported"> 
    <div class="btn-round-img" [style.background-image]="config.icons.arrow_left"></div> </button>
  </div>
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="20%" fxLayout="row" fxLayoutAlign="center center" layout-id="246910" widget-id="248133 middle div_Imported_Imported_Imported_Imported">
   <button class="btn-round temperature-icon fab-pointer-cursor" (click)="callActions('themenfinderrisikotemperatur', undefined, true, undefined, [], undefined, undefined)" widget-id="248229 wappnet_temperature_indicator_Imported_Imported_Imported"> 
    <div class="temperature-icon-peak"> 
     <div class="temperature-icon-peak-background"></div> 
    </div> 
    <div class="btn-round-img" [style.background-image]="model.themenfinder.risikotemperatur == 0 ? config.icons.temperature_low : (model.themenfinder.risikotemperatur == 1 ? config.icons.temperature : config.icons.temperature_high)"></div> </button>
  </div>
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="33%" fxLayout="row" fxLayoutAlign="end center" layout-id="246909" widget-id="248132 right div_Imported_Imported_Imported_Imported">
   <div class="btn-main" (click)="wappnet_nav_button_Imported_Imported_Importedforwardnavigate248958('beratungsideal', undefined, true, undefined, [], undefined, undefined)" widget-id="248228 forward wappnet_nav_button_Imported_Imported_Imported">
     {{config.geldgefuhle.glueckwunsch.nav_button_text}} 
   </div>
  </div>
 </div>
</app-glueckwunschtemplate>