/**
 * userAPI API
 * userAPI API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ComplexDataTypeFieldDTO { 
    complexDataTypeId?: number;
    complexDataTypeName?: string;
    defaultValue?: string;
    displayName?: string;
    id?: number;
    name?: string;
    nullable?: boolean;
    ownerId?: number;
    ownerName?: string;
    repeatable?: boolean;
    showInList?: boolean;
    simpleDataType?: ComplexDataTypeFieldDTO.SimpleDataTypeEnum;
    visibleInGui?: boolean;
}
export namespace ComplexDataTypeFieldDTO {
    export type SimpleDataTypeEnum = 'STRING' | 'INTEGER' | 'BOOLEAN' | 'FLOAT' | 'DATE';
    export const SimpleDataTypeEnum = {
        STRING: 'STRING' as SimpleDataTypeEnum,
        INTEGER: 'INTEGER' as SimpleDataTypeEnum,
        BOOLEAN: 'BOOLEAN' as SimpleDataTypeEnum,
        FLOAT: 'FLOAT' as SimpleDataTypeEnum,
        DATE: 'DATE' as SimpleDataTypeEnum
    };
}
