export * from './activatedApplicationResource.service';
import { ActivatedApplicationResourceService } from './activatedApplicationResource.service';
export * from './appConfigurationResource.service';
import { AppConfigurationResourceService } from './appConfigurationResource.service';
export * from './applicationResource.service';
import { ApplicationResourceService } from './applicationResource.service';
export * from './campaignResource.service';
import { CampaignResourceService } from './campaignResource.service';
export * from './complexDataTypeResource.service';
import { ComplexDataTypeResourceService } from './complexDataTypeResource.service';
export * from './elasticsearchResource.service';
import { ElasticsearchResourceService } from './elasticsearchResource.service';
export * from './emailServerPasswordResource.service';
import { EmailServerPasswordResourceService } from './emailServerPasswordResource.service';
export * from './emailServerResource.service';
import { EmailServerResourceService } from './emailServerResource.service';
export * from './endUserCredentialsResource.service';
import { EndUserCredentialsResourceService } from './endUserCredentialsResource.service';
export * from './endUserResource.service';
import { EndUserResourceService } from './endUserResource.service';
export * from './organisationResource.service';
import { OrganisationResourceService } from './organisationResource.service';
export * from './userResource.service';
import { UserResourceService } from './userResource.service';
export const APIS = [ActivatedApplicationResourceService, AppConfigurationResourceService, ApplicationResourceService, CampaignResourceService, ComplexDataTypeResourceService, ElasticsearchResourceService, EmailServerPasswordResourceService, EmailServerResourceService, EndUserCredentialsResourceService, EndUserResourceService, OrganisationResourceService, UserResourceService];
