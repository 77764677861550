import { DataAccessService } from './../../../utils/data-access-service';
import { switchMap } from 'rxjs/operators';
import { EndUserCredentialsResourceService } from './../../../usersdk/api/endUserCredentialsResource.service';
import { PasswordStrengthInfoDTO } from './../../../usersdk/model/passwordStrengthInfoDTO';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit, OnDestroy {
  public token: string = '';

  public logoUrl: string = '';
  public appName: string = '';

  public password: string;
  public passwordrptd: string;
  public error: string = undefined;
  public passwordStrength: PasswordStrengthInfoDTO = {
    strengthLevel: 0,
    entropy: 0,
    isAccepted: false,
  };

  public isEnd: boolean = false;
  private timeout: any;

  public passwordIndicatorColors: string[] = [];
  public passwordIndicatorLabels: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private endUserCredentialsService: EndUserCredentialsResourceService,
    private router: Router,
    private dataAccessService: DataAccessService
  ) {
    const config = this.route.snapshot.data.config;
    this.token = this.route.snapshot.queryParamMap.get('passwordResetKey');

    this.appName = '';
    this.logoUrl = '';
    this.passwordIndicatorColors = [];
    this.passwordIndicatorLabels = [];
  }

  ngOnInit(): void {}

  validatePwd(keyEvent: KeyboardEvent) {
    if (keyEvent.key === 'enter' || keyEvent.key === 'shift') {
      return;
    }
    if (!this.password) {
      this.passwordStrength = {
        entropy: 0,
        isAccepted: false,
        strengthLevel: 0,
      };
      return;
    }
    this.endUserCredentialsService
      .checkPasswordStrengthUsingPOST(this.password)
      .pipe(switchMap((res: PasswordStrengthInfoDTO) => of(res)))
      .subscribe(
        (result) => {
          this.passwordStrength = result;
        },
        (error) => {
          this.passwordStrength = {
            entropy: 0,
            isAccepted: false,
            strengthLevel: 0,
          };
        }
      );
  }

  isDisabled(): boolean {
    return (
      !this.passwordStrength.isAccepted || !this.password || !this.passwordrptd
    );
  }

  confirm() {
    this.error = undefined;
    if (!this.password || !this.passwordrptd) {
      return;
    }
    if (this.password !== this.passwordrptd) {
      this.error = 'Das wiederholte Kennwort ist nicht gleich.';
      return;
    }
    this.endUserCredentialsService
      .passwordResetFinishUsingPOST(this.password, this.token)
      .subscribe(
        (result) => {
          this.timeout = setTimeout(() => {
            this.redirect(null);
          }, 5000);
          this.isEnd = true;
          this.dataAccessService.setSessionToken(result.value);
        },
        (error) => (this.error = error.message)
      );
  }

  redirect(event: Event) {
    if (event) {
      event.stopPropagation();
    }
    if (!this.isEnd) {
      return;
    }
    this.router.navigate(['/']);
  }

  ngOnDestroy() {
    clearTimeout(this.timeout);
  }
}
