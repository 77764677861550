<div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" layout-id="246933" widget-id="248023 root div_Imported_Imported_Imported_Imported">
 <ng-template cdkConnectedOverlay [cdkConnectedOverlayOpen]="isOpen248068" [cdkConnectedOverlayHasBackdrop]="true" (backdropClick)="isOpen248068 = false" [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'" [cdkConnectedOverlayPositions]="panelPositions" widget-id="248068 button_row div_Imported_Imported_Imported_Imported"> 
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="none center" fxLayoutGap="20px" layout-id="246968"> 
   <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="33" widget-id="248173 placeholder1 div_Imported_Imported_Imported_Imported"></div> 
   <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="33" widget-id="248174 placeholder2 div_Imported_Imported_Imported_Imported"></div> 
   <div class="btn-main" (click)="wappnet_nav_button_Imported_Imported_Importedbutton_step_1navigate248938('themenfinderrisikotemperatur', undefined, true, undefined, [], undefined, undefined)" fxFlex="33" widget-id="248172 button_step_1 wappnet_nav_button_Imported_Imported_Imported">
     {{config.themenfinder.steps.nav_button_text}} 
   </div> 
  </div> 
 </ng-template>
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="none stretch" fxLayoutGap="20px" layout-id="246967" widget-id="248067 steps_list div_Imported_Imported_Imported_Imported">
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="33" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px" layout-id="246999" widget-id="248171 risikotemperatur div_Imported_Imported_Imported_Imported">
   <div class="step-header brand-radius brand-shadow brand-gradient" [class.brand-gradient]="model.appState.risikoDone" (click)="wappnet_step_header_Imported_Imported_Importedstep_one_headerclick248975('themenfinderrisikotemperatur', undefined, true, undefined, [], undefined, undefined)" widget-id="248272 step_one_header wappnet_step_header_Imported_Imported_Imported"> 
    <div class="step-title">
     {{config.themenfinder.steps.header1}}
    </div> 
    <div class="step-number">
     1/3
    </div> 
   </div>
   <div attachment-point-for="content" [ngClass]="'brand-offset-small-inner brand-radius brand-shadow-inset'" *ngIf="true" fxFlex="grow" widget-id="248271 step_one_content div_Imported_Imported_Imported_Imported">
    <article widget-id="248405 wappnet_text-with-header_Imported_Imported_Imported"> 
     <header [ngClass]="'config.colors.contentHeader'">
      {{config.themenfinder.steps.paragraph_header1}}
     </header> 
     <p>{{config.themenfinder.steps.paragraph1}}</p> 
    </article>
   </div>
  </div>
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="33" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px" layout-id="246997" widget-id="248169 elefantengleichnis div_Imported_Imported_Imported_Imported">
   <div class="step-header brand-radius brand-shadow brand-gradient" [class.brand-gradient]="model.appState.elefantengleichnisDone" (click)="wappnet_step_header_Imported_Imported_Importedstep_two_headerclick248972('themenfinderelefantengleichnis', undefined, true, undefined, [], undefined, undefined)" widget-id="248268 step_two_header wappnet_step_header_Imported_Imported_Imported"> 
    <div class="step-title">
     {{config.themenfinder.steps.header2}}
    </div> 
    <div class="step-number">
     2/3
    </div> 
   </div>
   <div attachment-point-for="content" [ngClass]="'brand-offset-small-inner brand-radius brand-shadow-inset'" *ngIf="true" fxFlex="grow" widget-id="248267 step_two_content div_Imported_Imported_Imported_Imported">
    <article widget-id="248403 wappnet_text-with-header_Imported_Imported_Imported"> 
     <header>
      {{config.themenfinder.steps.paragraph_header2}}
     </header> 
     <p>{{config.themenfinder.steps.paragraph2}}</p> 
    </article>
   </div>
  </div>
  <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="33" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px" layout-id="246998" widget-id="248170 lebenssituation div_Imported_Imported_Imported_Imported">
   <div class="step-header brand-radius brand-shadow brand-gradient" [class.brand-gradient]="model.appState.lebenssituationDone" (click)="wappnet_step_header_Imported_Imported_Importedstep_three_headerclick248973('themenfinderlebenssituationstep1', undefined, true, undefined, [], undefined, undefined)" widget-id="248269 step_three_header wappnet_step_header_Imported_Imported_Imported"> 
    <div class="step-title">
     {{config.themenfinder.steps.header3}}
    </div> 
    <div class="step-number">
     3/3
    </div> 
   </div>
   <div attachment-point-for="content" [ngClass]="'brand-offset-small-inner brand-radius brand-shadow-inset'" *ngIf="true" fxFlex="grow" widget-id="248270 step_three_content div_Imported_Imported_Imported_Imported">
    <article widget-id="248404 wappnet_text-with-header_Imported_Imported_Imported"> 
     <header>
      {{config.themenfinder.steps.paragraph_header3}}
     </header> 
     <p>{{config.themenfinder.steps.paragraph3}}</p> 
    </article>
   </div>
  </div>
 </div>
</div>