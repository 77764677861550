import {
  FlexibleConnectedPositionStrategy,
  OverlayRef,
} from '@angular/cdk/overlay';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { DialogConfig } from './dialog-config';

/**
 * Reference to a dialog opened via the Dialog service.
 */
export class DialogRef<T = any> {
  private afterClosedSubject = new ReplaySubject<T>(1);

  constructor(private overlayRef: OverlayRef, private config: DialogConfig) {
    if (!config.disableClose) {
      this.overlayRef.backdropClick().subscribe(() => {
        this.close();
      });

      this.overlayRef
        .keydownEvents()
        .pipe(filter((event) => event.key === 'Escape'))
        .subscribe(() => {
          this.close();
        });
    }

    if (this.config.panelClass) {
      this.overlayRef.addPanelClass(this.config.panelClass);
    }
    this.overlayRef.updateSize({
      height: this.config.height,
      width: this.config.width,
    });

    /**
     * Set the position of the overlay element to 'relative' - helps when we want to set the position of the dialog to 'absolute'
     * Set the display property from 'flex' to 'block'. 'flex' does not render 'border-radius' properly
     */
    this.overlayRef.overlayElement.classList.add('cdk-overlay-pane-block');
  }

  close(dialogResult?: T): void {
    this.afterClosedSubject.next(dialogResult);
    this.afterClosedSubject.complete();
    this.overlayRef.dispose();
  }

  afterClosed(): Observable<T> {
    return this.afterClosedSubject.asObservable();
  }
}
