<div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px" layout-id="246918" widget-id="248002 div_Imported_Imported_Imported_Imported">
 <div class="article-img" [style.background-image]="config.geldgefuhle.landingPage.imageUrl" fxFlex="60%" widget-id="248029 image wappnet_image_container_Imported_Imported_Imported"> 
 </div>
 <div attachment-point-for="content" [ngClass]="'debug'" *ngIf="true" fxFlex="40%" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="10px" layout-id="246939" widget-id="248030 right_panel div_Imported_Imported_Imported_Imported">
  <div attachment-point-for="content" [ngClass]="'brand-offset-inner brand-radius brand-shadow'" *ngIf="true" widget-id="248081 texts div_Imported_Imported_Imported_Imported">
   <article widget-id="248191 article 1 wappnet_text-with-header_Imported_Imported_Imported"> 
    <header>
     {{config.geldgefuhle.landingPage.header1}}
    </header> 
    <p>{{config.geldgefuhle.landingPage.paragraph1}}</p> 
   </article>
   <article widget-id="248192 article 2 wappnet_text-with-header_Imported_Imported_Imported"> 
    <header>
     {{config.geldgefuhle.landingPage.header2}}
    </header> 
    <p>{{config.geldgefuhle.landingPage.paragraph2}}</p> 
   </article>
  </div>
  <div class="btn-main" (click)="callActions('geldgefuhlesteps', undefined, true, undefined, [], undefined, undefined)" widget-id="248082 button wappnet_nav_button_Imported_Imported_Imported">
    BREMSE LÖSEN 
  </div>
 </div>
</div>