import { DataAccessService } from '../../utils/data-access-service';
import { ListsMergeService } from '../../utils/lists-merge.service';
import { UserStatusDTO } from 'src/app/usersdk';
import { Subscription } from 'rxjs';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectionPositionPair } from '@angular/cdk/overlay';


@Component({
  selector: 'app-geldgefuhledenkfehler',
  templateUrl: './geldgefuhledenkfehler.component.html',
  styleUrls: ['./geldgefuhledenkfehler.component.scss']
})
export class GeldgefuhledenkfehlerComponent implements OnInit, OnDestroy {

  @Input()
  public config: any = {};

  @Input()
  public model: any = {};

  @Input()
  public status: UserStatusDTO = {};

  private statusSubscription: Subscription;

   @Input()
 public showHiddenOnly: boolean = false;

 public get isProd() {
   return true;
 }


    public denkfehlerList: any = {};



  constructor(private route: ActivatedRoute, private router: Router, private dataAccessService : DataAccessService,
              private listsMerge: ListsMergeService) { }

  ngOnInit(): void {
    if (this.route.snapshot.data.modelAndConfig) {
        this.model = this.route.snapshot.data.modelAndConfig[0];
        this.config = this.route.snapshot.data.modelAndConfig[1];
        this.status = this.route.snapshot.data.modelAndConfig[2];
    }
    var prototype0='{"chosen":false,"expanded":false,"id":0,"visited":false}';
this.denkfehlerList=this.listsMerge.merge(this.config.geldgefuhle.denkfehler.fehlerList, this.model.geldgefuhle.denkfehlerList, 'id', 'id', 'config', 'model', prototype0);

    this.statusSubscription = this.dataAccessService.getStatusObservable().subscribe(status => this.status = status);
  }

  ngOnDestroy(): void {
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
  }

   public panelPositions: ConnectionPositionPair[] = [];

 private defaultPanelPositions: ConnectionPositionPair[] = [{ "originX": "start", "originY": "bottom", "overlayX": "start", "overlayY": "top" },
  { "originX": "start", "originY": "top", "overlayX": "start", "overlayY": "bottom" },
  { "originX": "end", "originY": "top", "overlayX": "end", "overlayY": "bottom" },
  { "originX": "end", "originY": "bottom", "overlayX": "end", "overlayY": "top" }];

 private openPanel(widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[]) {
    if (panelPositions && panelPositions.length > 0) {
      this.panelPositions = panelPositions.concat(this.defaultPanelPositions);
    } else {
      this.panelPositions = this.defaultPanelPositions;
    }
    this['isOpen' + widgetToOpenAsPanelId] = !this['isOpen' + widgetToOpenAsPanelId];
  }


   callActions(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any,
              eventToSend : string) {
        this.tweakModelField(pathToBooleanFieldToTweak, item);
    if (saveModel) {
      this.dataAccessService.saveDocument();
    }
    if (eventToSend) {
      this.dataAccessService.sendEvent(eventToSend);
    }

    if (pathToNavigate) {
      this.router.navigate(pathToNavigate.split('/'));
    }
    if(widgetToOpenAsPanelId) {
      this.openPanel(widgetToOpenAsPanelId, panelPositions);
    }
  }


    private tweakModelField(pathToBooleanFieldToTweak: string, item: any) {
      if (pathToBooleanFieldToTweak) {
        var pathParts = pathToBooleanFieldToTweak.split('.');
        var pathLength = pathParts.length;
        if (pathLength < 2) {
          console.warn("Path to tweak to short: " + pathToBooleanFieldToTweak);
          return;
        }
        var modelToSet = {};
        if (pathParts[0] == 'item') {
          modelToSet = item;
        } else if (pathParts[0] == 'model') {
          modelToSet = this.model;
        }
        for (var i = 1; i < pathLength - 1; i++) {
          modelToSet = modelToSet[pathParts[i]];
        }
        var currentValue = modelToSet[pathParts[pathLength - 1]];
        if (currentValue) {
          modelToSet[pathParts[pathLength - 1]] = false;
        } else {
          modelToSet[pathParts[pathLength - 1]] = true;
        }
      }
   }


  public wappnet_question_panel_Imported_Imported_Importedq7click249056(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.geldgefuhle.denkfehler.fehler7.visited = true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}


public wappnet_question_panel_Imported_Imported_Importedq7click249057(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.geldgefuhle.denkfehler.fehler7.visited = true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}




public wappnet_question_panel_Imported_Imported_Importedq8click249058(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.geldgefuhle.denkfehler.fehler8.visited = true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}




public wappnet_question_panel_Imported_Imported_Importedq9click249059(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.geldgefuhle.denkfehler.fehler9.visited = true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}




public wappnet_question_panel_Imported_Imported_Importedq10click249060(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.geldgefuhle.denkfehler.fehler10.visited = true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}


public wappnet_question_panel_Imported_Imported_Importedq10click249061(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.geldgefuhle.denkfehler.fehler10.visited = true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}




public wappnet_question_panel_Imported_Imported_Importedq11click249062(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.geldgefuhle.denkfehler.fehler11.visited = true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}


public wappnet_question_panel_Imported_Imported_Importedq11click249063(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.geldgefuhle.denkfehler.fehler11.visited = true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}






public wappnet_question_panel_Imported_Imported_Importedq1click249064(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.geldgefuhle.denkfehler.fehler1.visited = true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}




public wappnet_question_panel_Imported_Imported_Importedq2click249065(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.geldgefuhle.denkfehler.fehler2.visited = true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}




public wappnet_question_panel_Imported_Imported_Importedq3click249066(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.geldgefuhle.denkfehler.fehler3.visited = true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}




public wappnet_question_panel_Imported_Imported_Importedq4click249067(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.geldgefuhle.denkfehler.fehler4.visited = true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}




public wappnet_question_panel_Imported_Imported_Importedq5click249068(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.geldgefuhle.denkfehler.fehler5.visited = true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}




public wappnet_question_panel_Imported_Imported_Importedq6click249069(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.geldgefuhle.denkfehler.fehler6.visited = true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}




































public wappnet_fab_button_Imported_Imported_Importedbacknavigate248929(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.appState.denkfehlerDone = true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}


public wappnet_fab_button_Imported_Imported_Importedforwardnavigate248930(pathToNavigate: string, pathToBooleanFieldToTweak: string, saveModel: boolean,
              widgetToOpenAsPanelId: number, panelPositions: ConnectionPositionPair[], item: any, eventToSend: string) {
  this.model.appState.glaubenssaetzeDone = true;
  this.callActions(pathToNavigate, pathToBooleanFieldToTweak, saveModel, widgetToOpenAsPanelId, panelPositions, item, eventToSend);
}




}
