import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent implements OnInit {
  public isChecked: boolean = false;

  @Input('checked')
  public _isChecked(val: boolean) {
    this.isChecked = val;
  }

  @Output()
  public checkedChange: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onChange(newValue: boolean) {
    this.checkedChange.emit(newValue);
  }
}
